import React, { useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/style.css";
import "./css/custom.css";
import getRoutes from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import {
  getLatestNotiTypeFromDB,
  getLatestOOFListFromDB,
  getLatestItineraryDataFromDB,
} from "./helpers/offlineAPIs";
import { getAllEntriesFromDB } from "./helpers/db";
import {
  useOnlineStatus,
  useFetchAndSyncData,
  useKeyboardDetection,
} from "./hooks";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js`;

const App = () => {
  useKeyboardDetection();
  const dispatch = useDispatch();
  const localSUser = JSON.parse(localStorage.getItem("user"));
  const { user } = useSelector((state) => state.userReducer);
  const { isOnline } = useOnlineStatus();
  const { uploadAndFetchData } = useFetchAndSyncData(false);

  useEffect(() => {
    if (isOnline && user?.user_id) {
      const intervalId = setInterval(
        handleUploadDBData,
        process.env.REACT_APP_API_CALL_TIME
      );
      return () => clearInterval(intervalId);
    }
  }, [isOnline, user]);
  const handleUploadDBData = useCallback(async () => {
    try {
      await uploadAndFetchData("");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    if (isOnline && user?.user_id) {
      const intervalId = setInterval(
        handleUpdateDBData,
        process.env.REACT_APP_API_CALL_TIME
      );
      return () => clearInterval(intervalId);
    }
  }, [isOnline, user]);
  const handleUpdateDBData = useCallback(async () => {
    const [oofMissedCalls, addedItinerary] = await Promise.all([
      getAllEntriesFromDB("oofMissedCalls"),
      getAllEntriesFromDB("addedItinerary"),
    ]);
    if (oofMissedCalls?.length === 0) {
      getLatestOOFListFromDB(dispatch, false).catch(() => { });
    }
    if (addedItinerary?.length === 0) {
      getLatestItineraryDataFromDB(dispatch, false).catch(() => { });
    }
    getLatestNotiTypeFromDB(dispatch, false).catch(() => { });
  });

  // useEffect(() => {
  //   if (
  //     isOnline &&
  //     user &&
  //     (location.pathname === routes.dashboard ||
  //       location.pathname === routes.mdList)
  //   ) {
  //     handleAutoRefresh();
  //   }
  // }, [isOnline, location.pathname]);
  // const handleAutoRefresh = useCallback(async () => {
  //   await uploadAndFetchData("autoRefresh");
  // });

  useEffect(() => {
    let lastTouchEnd = 0;
    const handleTouchEnd = (e) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        e.preventDefault();
      }
      lastTouchEnd = now;
    };
    document.addEventListener("touchend", handleTouchEnd);
    return () => {
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  useEffect(() => {
    const handleGestureStart = (e) => {
      e.preventDefault();
    };
    document.addEventListener("gesturestart", handleGestureStart);
    return () => {
      document.removeEventListener("gesturestart", handleGestureStart);
    };
  }, []);

  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.scale !== 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  const routing = useRoutes(getRoutes(localSUser ? user : null));
  return <>{routing}</>;
};

export default App;
