import { Navigate } from "react-router-dom";
import { routes } from "./constants";
import LoginPage from "../pages/Login/LoginPage";
import Dashboard from "../pages/Dashboard/Dashboard";
import MDList from "../pages/MdList/MDList";
import AToZMDList from "../pages/MdList/AToZMDList";
import EditCallMDList from "../pages/MdList/EditCallMDList";
import MissedCallMDList from "../pages/MdList/MissedCallMDList";
import MdBirthDayList from "../pages/MdList/MdBirthDayList";
import MDInfo from "../pages/MdList/MDInfo";
import CallActivities from "../pages/CallRecording/CallActivities";
import ViewCallSummary from "../pages/CallRecording/ViewCallSummary";
import QuickSign from "../pages/QuickSign/QuickSign";
import PromoMaterials from "../pages/PromoMaterials/PromoMaterials";
import ReceivePromoMats from "../pages/PromoMaterials/ReceivePromoMats";
import OutOfField from "../pages/OutOfField/OutOfField";
import TimeInOut from "../pages/TimeInOut/TimeInOut";
import Tactical from "../pages/Tactical/Tactical";
import Reports from "../pages/Report/Reports";
import Notifications from "../pages/Notifications/Notifications";
import Sync from "../pages/Sync/Sync";
import Itinerary from "../pages/Itinerary/Itinerary";
import UpdateItinerary from "../pages/Itinerary/UpdateItinerary";
import ItineraryPerDay from "../pages/Itinerary/ItineraryPerDay";
import MonthlyItinerary from "../pages/Itinerary/MonthlyItinerary";
import BrowserCompatibility from "../pages/BrowserCompatibility/BrowserCompatibility";
import UpdateRequestHistory from "../pages/Itinerary/UpdateRequestHistory";
import RequestHistory from "../pages/OutOfField/RequestHistory";

export const compatibilityRoutes = [
  {
    path: routes.homepage,
    element: <Navigate to={routes.browserCompatibility} />,
  },
  { path: routes.browserCompatibility, element: <BrowserCompatibility /> },
];

export const authRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.login} /> },
  { path: routes.login, element: <LoginPage /> },
];

export const mainRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.dashboard} /> },
  { path: routes.dashboard, element: <Dashboard /> },
  { path: routes.mdList, element: <MDList /> },
  { path: routes.aTozMDList, element: <AToZMDList /> },
  { path: routes.editCallMdList, element: <EditCallMDList /> },
  { path: routes.missedCallMdList, element: <MissedCallMDList /> },
  { path: routes.itinerary, element: <Itinerary /> },
  { path: routes.updateItinerary, element: <UpdateItinerary /> },
  { path: routes.updateItineraryHistory, element: <UpdateRequestHistory /> },
  { path: routes.itineraryPerDay, element: <ItineraryPerDay /> },
  { path: routes.monthlyItinerary, element: <MonthlyItinerary /> },
  { path: routes.callActivities, element: <CallActivities /> },
  { path: routes.quickSign, element: <QuickSign /> },
  { path: routes.viewCallSummary, element: <ViewCallSummary /> },
  { path: routes.promoMaterials, element: <PromoMaterials /> },
  { path: routes.recivePromoMats, element: <ReceivePromoMats /> },
  { path: routes.outOfField, element: <OutOfField /> },
  { path: routes.timeInOut, element: <TimeInOut /> },
  { path: routes.tactical, element: <Tactical /> },
  { path: routes.reports, element: <Reports /> },
  { path: routes.notifications, element: <Notifications /> },
  { path: routes.mdBirthdayList, element: <MdBirthDayList /> },
  { path: routes.mdInfo, element: <MDInfo /> },
  { path: routes.requestHistory, element: <RequestHistory /> },
  { path: routes.sync, element: <Sync /> },
];

export const timeoutRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.sync} /> },
  { path: routes.sync, element: <Sync /> },
];

export const limitedRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.timeInOut} /> },
  { path: routes.timeInOut, element: <TimeInOut /> },
];

export const invalidRoutes = [
  { path: "*", element: <LoginPage /> },
  { path: routes.login, element: <LoginPage /> },
];
