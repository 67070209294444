import React, { useRef, useState } from "react";
import Select from "react-select";
import { Button, Table } from "react-bootstrap";
import { CustomImageCapture, DataNotFound, ImageCard } from "../../components";
import { AddNewMDModal, ImageCaptureModal } from "../../components/Modals";
import {
  backBtnIcon,
  cameraIcon2,
  ExcelIcon,
  fileUploadIcon,
  OutOfFieldIcon,
} from "../../icon/icon";
import {
  mediaToBase64,
  checkSystemWebcam,
  manageRequestStatus,
  checkRequestIsEnable,
} from "../../helpers/commonFunction";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { isDesktop } from "react-device-detect";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

const UpdateMDForm = (props) => {
  const {
    show,
    values,
    errors,
    validate,
    monthInfo,
    isLoading,
    setErrors,
    setValues,
    selectedDate,
    mdInfoMethod,
    mdRequestList,
    handleBackBtn,
    clinicOptions,
    specialtyOptions,
    frequencyOptions,
    universeMDOptions,
    handleInputChange,
    handleSavePrompt,
    onDateChangehandler,
  } = props;
  const inputRef = useRef();
  const [showWebcam, setShowWebcam] = useState(false);
  const handleShowWebcam = () => {
    setShowWebcam(!showWebcam);
  };
  const [showAddClinic, setShowAddClinic] = useState(false);
  const handleAddClinicModal = () => {
    if (!showAddClinic) {
      setErrors({
        ...errors,
        clinic: "",
      });
    }
    setShowAddClinic(!showAddClinic);
  };

  const handleAddNewClinic = (item) => {
    if (item) {
      const reqObj = { label: item, value: item };
      clinicOptions[clinicOptions?.length + 1] = reqObj;
      setValues({
        ...values,
        clinic: reqObj,
      });
      handleAddClinicModal();
    } else {
      validate();
    }
  };
  const handleSystemWebcam = () => {
    checkSystemWebcam()
      .then(() => {
        if (isDesktop) {
          handleShowWebcam();
        } else {
          inputRef.current.click();
        }
      })
      .catch(() => {});
  };
  const handleCaptureImage = async (event) => {
    if (isDesktop) {
      const blobData = dataURLtoBlob(event);
      setValues({
        ...values,
        attachment: blobData,
      });
      handleShowWebcam();
    } else {
      const file =
        event?.target?.files &&
        event?.target?.files?.length > 0 &&
        event?.target?.files[0];
      if (file) {
        const base64 = await mediaToBase64(file);
        const blobData = dataURLtoBlob(base64);
        setValues({
          ...values,
          attachment: blobData,
        });
        setShowWebcam(false);
      }
    }
  };

  const getRequestListCount = (list) => {
    const notiCount = list ? list.length.toString().padStart(2, "0") : 0;
    return notiCount;
  };

  const renderImageList = (request) => {
    return (
      <>
        {request?.file ? (
          (request?.file?.size && request?.file?.type?.includes("xls")) ||
          (!request?.file?.size && request?.file?.includes("xls")) ||
          request?.file?.name?.includes("xls") ? (
            <span>{ExcelIcon}</span>
          ) : (
            <ImageCard
              src={
                request?.file?.size
                  ? URL.createObjectURL(request?.file)
                  : request?.base64?.includes("base64")
                  ? request?.base64
                  : process.env.REACT_APP_IMAGE_URL + request?.file
              }
              alt="Attachment"
              imagePreview={true}
            />
          )
        ) : (
          "-"
        )}
      </>
    );
  };

  return (
    <div className={`tc-sidebar-section ${show && "zl-sidebar-open"}`}>
      <div className="tc-sidebar-navbar">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => handleBackBtn(true)}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title text-center text-uppercase">
          {mdInfoMethod && mdInfoMethod?.label}
        </p>
        <div className="tc-sidebar-right-btn"></div>
      </div>
      <div className="tc-sidebar-body-wrapper ">
        <div className="tc-sidebar-body">
          <div className="tc-add-md-page tc-receive-pro-matr-body-section">
            {mdInfoMethod?.value !== "SUMMARY" && (
              <div className="add-scroll">
                <div className="tc-content-wrapper h-100">
                  <div className="tc-calender-input-content d-flex mb-4">
                    <span className="text-select-option">For:</span>
                    <div className="month-static-disabled">
                      {monthInfo && monthInfo?.nextMName}
                    </div>
                  </div>
                  <div className="tc-card-itinerary-content">
                    <div className="tc-card-itinerary-container">
                      {mdInfoMethod?.value === "INSERT" && (
                        <>
                          <div className="tc-card-input-container">
                            <div className="tc-card-input-label">
                              PRC #<span>:</span>
                            </div>
                            <input
                              type="text"
                              id="prc"
                              name="prc"
                              value={values.prc}
                              className={`tc-card-static-input ${
                                errors && errors.prc && "error-active"
                              }`}
                              placeholder="0000000"
                              onChange={(e) => {
                                handleInputChange(e);
                                setErrors({ ...errors, prc: "" });
                              }}
                            />
                          </div>
                          <div className="tc-card-input-container">
                            <div className="tc-card-input-label">
                              Last Name<span>:</span>
                            </div>
                            <input
                              type="text"
                              id="lastname"
                              name="lastname"
                              value={values.lastname}
                              className={`tc-card-static-input text-uppercase ${
                                errors && errors.lastname && "error-active"
                              }`}
                              placeholder="Enter Your Last Name..."
                              onChange={(e) => {
                                handleInputChange(e);
                                setErrors({ ...errors, lastname: "" });
                              }}
                            />
                          </div>
                          <div className="tc-card-input-container">
                            <div className="tc-card-input-label">
                              First Name<span>:</span>
                            </div>
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              value={values.firstname}
                              className={`tc-card-static-input text-uppercase ${
                                errors && errors.firstname && "error-active"
                              }`}
                              placeholder="Enter Your First Name..."
                              onChange={(e) => {
                                handleInputChange(e);
                                setErrors({ ...errors, firstname: "" });
                              }}
                            />
                          </div>
                        </>
                      )}
                      {mdInfoMethod?.value !== "INSERT" && (
                        <>
                          <div className="tc-card-input-container">
                            <div className="tc-card-input-label">
                              MD Code<span>:</span>
                            </div>
                            <div className="tc-card-static-input color-gray">
                              {values?.selectedMD && values?.selectedMD?.code
                                ? values?.selectedMD?.code
                                : "-"}
                            </div>
                          </div>
                          {mdInfoMethod?.value === "EDIT" ? (
                            values?.selectedMD && values?.selectedMD?.prc ? (
                              <div className="tc-card-input-container">
                                <div className="tc-card-input-label">
                                  PRC #<span>:</span>
                                </div>
                                <div className="tc-card-static-input color-gray">
                                  {values?.selectedMD?.prc}
                                </div>
                              </div>
                            ) : (
                              <div className="tc-card-input-container">
                                <div className="tc-card-input-label">
                                  PRC #<span>:</span>
                                </div>
                                <input
                                  type="text"
                                  id="prc"
                                  name="prc"
                                  value={values.prc}
                                  className={`tc-card-static-input ${
                                    errors && errors.prc && "error-active"
                                  }`}
                                  placeholder="0000000"
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    setErrors({ ...errors, prc: "" });
                                  }}
                                />
                              </div>
                            )
                          ) : (
                            <div className="tc-card-input-container">
                              <div className="tc-card-input-label">
                                PRC #<span>:</span>{" "}
                              </div>
                              <div className="tc-card-static-input color-gray">
                                {values?.selectedMD && values?.prc
                                  ? values?.prc
                                  : "-"}
                              </div>
                            </div>
                          )}
                          <div className="tc-card-input-container mb-3">
                            <div className="tc-card-input-label">
                              MD Name<span>:</span>
                            </div>
                            <Select
                              id="selectedMD"
                              name="selectedMD"
                              value={values.selectedMD}
                              onChange={(e) => {
                                handleInputChange(e, "selectedMD");
                                setErrors({ ...errors, selectedMD: "" });
                              }}
                              options={universeMDOptions}
                              className={`tc-custom-select-2 tc-custom-select-mx ${
                                errors && errors.selectedMD && "error-active"
                              }`}
                              classNamePrefix="select"
                              placeholder="Select MD..."
                            />
                          </div>
                        </>
                      )}
                      <div className="tc-card-input-container mb-3">
                        <div className="tc-card-input-label">
                          Specialty<span>:</span>
                        </div>
                        {mdInfoMethod?.value === "INSERT" ||
                        mdInfoMethod?.value === "EDIT" ? (
                          <Select
                            id="specialty_id"
                            name="specialty_id"
                            value={values.specialty_id}
                            onChange={(e) => {
                              handleInputChange(e, "specialty_id");
                              setErrors({ ...errors, specialty_id: "" });
                            }}
                            options={specialtyOptions}
                            className={`tc-custom-select-2 tc-custom-select-mx ${
                              errors && errors.specialty_id && "error-active"
                            }`}
                            classNamePrefix="select"
                            placeholder="Select Specialty..."
                          />
                        ) : (
                          <div className="tc-card-static-input color-gray">
                            {values?.selectedMD && values?.specialty_id?.label
                              ? values?.specialty_id?.label
                              : "-"}
                          </div>
                        )}
                      </div>
                      <div className="tc-card-input-container mb-3">
                        <div className="tc-card-input-label">
                          Institution Name<span>:</span>
                        </div>
                        {mdInfoMethod?.value === "INSERT" ||
                        mdInfoMethod?.value === "EDIT" ? (
                          <div className="w-100 position-relative">
                            <Select
                              id="clinic"
                              name="clinic"
                              options={clinicOptions}
                              value={values.clinic}
                              onChange={(e) => {
                                handleInputChange(e, "clinic");
                                setErrors({ ...errors, clinic: "" });
                              }}
                              className={`tc-custom-select-2 tc-custom-select-mx ${
                                errors && errors.clinic && "error-active"
                              }`}
                              classNamePrefix="select"
                              placeholder="Select Institution..."
                            />
                            <span
                              className="add-btn"
                              onClick={() => handleAddClinicModal()}
                            >
                              Add Institution
                            </span>
                          </div>
                        ) : (
                          <div className="tc-card-static-input color-gray">
                            {values?.selectedMD && values?.clinic?.label
                              ? values?.clinic?.label
                              : "-"}
                          </div>
                        )}
                      </div>
                      {/* <div className="tc-card-input-container">
                      <div className="tc-card-input-label">
                        Class/Frequency <span>:</span>
                      </div>
                      {mdInfoMethod?.value === "INSERT" ? (
                        <div className="w-100 mt-3">
                          <Select
                            id="frequency_id"
                            name="frequency_id"
                            value={values.frequency_id}
                            options={frequencyOptions}
                            onChange={(e) => {
                              handleInputChange(e, "frequency_id");
                              setErrors({ ...errors, frequency_id: "" });
                            }}
                            className={`tc-custom-select-2 tc-custom-select-sm ${
                              errors && errors.frequency_id && "error-active"
                            }`}
                            classNamePrefix="select"
                            placeholder="Select..."
                          />
                        </div>
                      ) : (
                        <div
                          className={`tc-card-static-input color-gray ${
                            mdInfoMethod?.value === "EDIT" && "mt-3"
                          }`}
                        >
                          {values?.selectedMD && values?.frequency_id?.label
                            ? values?.frequency_id?.label
                            : "-"}
                        </div>
                      )}
                    </div> */}
                    </div>
                    {(mdInfoMethod?.value === "EDIT" ||
                      mdInfoMethod?.value === "INSERT" ||
                      mdInfoMethod?.value === "DELETE") && (
                      <div className="tc-edit-itinerary-wrap">
                        <div className="tc-edit-itinerary-reason">
                          <span className="fw-semibold">Reason</span>
                          <textarea
                            name="reason"
                            className="form-control"
                            placeholder="Write reason..."
                            rows={5}
                            value={values.reason}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <div className="tc-edit-itinerary-attachment mt-3">
                          <span className="fw-semibold d-flex justify-content-between align-content-center">
                            Attachment
                            <span
                              role="button"
                              className="selfi-icon"
                              onClick={() => handleSystemWebcam()}
                            >
                              {cameraIcon2}
                            </span>
                          </span>
                          <div className="w-100 tc-file-upload-input">
                            <input
                              type="file"
                              name="attachment"
                              id="file"
                              accept="image/*,.xls,.xlsx"
                              onChange={(e) => handleInputChange(e)}
                            />
                            <label
                              htmlFor="file"
                              className="tc-file-upload-label"
                            >
                              {values.attachment ? (
                                <div
                                  className={`tc-selected-file tc-choose-content ${
                                    values.attachment?.type?.includes(
                                      "image"
                                    ) && "border-0"
                                  }`}
                                >
                                  {values.attachment?.type?.includes(
                                    "image"
                                  ) ? (
                                    <img
                                      src={URL.createObjectURL(
                                        values.attachment
                                      )}
                                      height={220}
                                      alt="attachment"
                                    />
                                  ) : (
                                    <span>{values.attachment?.name}</span>
                                  )}
                                </div>
                              ) : (
                                <div className="tc-choose-content">
                                  {fileUploadIcon}
                                  <span className="tc-choose-text">
                                    Choose File to upload
                                  </span>
                                </div>
                              )}
                            </label>
                          </div>
                        </div>
                        {mdInfoMethod?.value !== "VIEW" && (
                          <div className="text-center mt-4">
                            <Button
                              className=""
                              onClick={() => handleSavePrompt()}
                              disabled={isLoading}
                            >
                              {mdInfoMethod?.value === "DELETE"
                                ? "REMOVE"
                                : "SUBMIT"}
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {mdInfoMethod?.value === "SUMMARY" && (
              <div className="tc-itinerary-body-section tc-md-request-history">
                <div className="tc-report-header d-flex justify-content-between align-items-center custom-datepicker-box mb-3">
                  <div className="tc-report-header-inner-box ">
                    <p className="mb-0 fw-semibold tc-report-header-date">
                      {selectedDate
                        ? moment(selectedDate).format("MMMM yyy")
                        : moment().format("MMMM yyy")}
                      <label htmlFor="selectedDate">{OutOfFieldIcon}</label>
                    </p>
                    <ReactDatePicker
                      onChange={(e) => onDateChangehandler(e)}
                      id="selectedDate"
                      name="selectedDate"
                      value={selectedDate}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      minDate={moment().add(-1, "months").startOf("month")._d}
                      maxDate={moment()._d}
                      className="d-none"
                      selected={
                        selectedDate ? new Date(selectedDate) : new Date()
                      }
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                  <div className="tc-requests-content">
                    <h5 className="text-end my-2">{`Total Number of Request: ${getRequestListCount(
                      mdRequestList
                    )}`}</h5>
                  </div>
                </div>

                <div className="tc-table-wrapper tc-white-table-wrapper">
                  <div className="table-responsive">
                    <Table className="tc-table">
                      <thead>
                        <tr>
                          <th className="text-center">TYPE</th>
                          <th className="text-center">DATE</th>
                          <th className="text-center">MD CODE</th>
                          <th className="text-center">PRC</th>
                          <th className="text-center">LAST NAME</th>
                          <th className="text-center">FIRST NAME</th>
                          <th className="text-center">SPECIALTY</th>
                          <th className="text-center">REASON</th>
                          <th className="text-center">ATTACHMENT</th>
                          <th className="text-center">INSTITUTION CODE</th>
                          <th className="text-center">INSTITUTION</th>
                          <th className="text-center">FREQUENCY</th>
                          <th className="text-center">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mdRequestList?.length === 0 ? (
                          <tr>
                            <td colSpan={13} className="text-center">
                              <DataNotFound message={"No MD Info to display"} />
                            </td>
                          </tr>
                        ) : (
                          mdRequestList &&
                          mdRequestList?.length > 0 &&
                          mdRequestList.map((mdRequest, index) => {
                            return (
                              <tr
                                key={index}
                                className={` ${
                                  !checkRequestIsEnable(mdRequest) &&
                                  "bg-color-gray"
                                }`}
                              >
                                <td className="text-center min-width-200">
                                  {mdRequest.type_request
                                    ? mdRequest.type_request
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  {moment(mdRequest.created_at)
                                    .format("MM/DD/YY")
                                    .toUpperCase()}
                                </td>
                                <td className="text-center min-width-200">
                                  {mdRequest.md_code ? mdRequest.md_code : "-"}
                                </td>
                                <td className="text-center min-width-200">
                                  {mdRequest.prc ? mdRequest.prc : "-"}
                                </td>
                                <td className="text-center min-width-200">
                                  {mdRequest.firstname
                                    ? mdRequest.firstname
                                    : "-"}
                                </td>
                                <td className="text-center min-width-200">
                                  {mdRequest.lastname
                                    ? mdRequest.lastname
                                    : "-"}
                                </td>
                                <td className="text-center min-width-250">
                                  {mdRequest.specialty
                                    ? mdRequest.specialty
                                    : "-"}
                                </td>
                                <td className="text-center min-width-200">
                                  {mdRequest.reason ? mdRequest.reason : "-"}
                                </td>
                                <td className="text-center min-width-250">
                                  {renderImageList(mdRequest)}
                                </td>
                                <td className="text-center">
                                  {mdRequest?.clinic_code
                                    ? mdRequest?.clinic_code
                                    : "-"}
                                </td>
                                <td className="text-center min-width-300">
                                  {mdRequest.clinic ? mdRequest.clinic : "-"}
                                </td>

                                <td className="text-center min-width-250">-</td>
                                <td className="text-center min-width-250">
                                  {manageRequestStatus(mdRequest)}
                                  {!checkRequestIsEnable(mdRequest) && (
                                    <div>
                                      {mdRequest?.admin_approved_date
                                        ? moment(
                                            mdRequest?.admin_approved_date
                                          ).format("MM/DD/YY h:mm A")
                                        : mdRequest?.dm_approved_date
                                        ? moment(
                                            mdRequest?.dm_approved_date
                                          ).format("MM/DD/YY h:mm A")
                                        : mdRequest?.updated_at ||
                                          mdRequest?.created_at
                                        ? moment(
                                            mdRequest?.updated_at ||
                                              mdRequest?.created_at
                                          ).format("MM/DD/YY h:mm A")
                                        : ""}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}

            <AddNewMDModal
              show={showAddClinic}
              onHide={handleAddClinicModal}
              values={values}
              errors={errors}
              cancel="Cancel"
              save="Ok"
              onSubmit={handleAddNewClinic}
            />
            {isDesktop ? (
              <>
                {showWebcam && (
                  <ImageCaptureModal
                    show={showWebcam}
                    onHide={handleShowWebcam}
                    handleCaptureImage={handleCaptureImage}
                  />
                )}
              </>
            ) : (
              <CustomImageCapture
                inputRef={inputRef}
                handleFileChange={handleCaptureImage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateMDForm;
