import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { db } from "../../helpers/db";
import { useLiveQuery } from "dexie-react-hooks";
import {
  DateUtility,
  nameFormatting,
  dateFormatting,
  checkRequestIsEnable,
  manageRequestStatus,
} from "../../helpers/commonFunction";
import moment from "moment";
import { DataNotFound, ImageCard } from "../../components";
import { backBtnIcon, ExcelIcon, OutOfFieldIcon } from "../../icon/icon";
import { useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import ReactDatePicker from "react-datepicker";
import { getMediaFileFromDB } from "../../helpers/offlineAPIs";

const UpdateRequestHistory = () => {
  const navigate = useNavigate();
  const getItineraryHistoryFromDB = useLiveQuery(
    () => db.itineraryRequest.toArray(),
    []
  );
  const freqOption = [
    {
      id: 1,
      name: "4X",
      target: 4,
      freq_id: 1,
      value: 4,
      label: "A - 4X",
    },
    {
      id: 2,
      name: "3X",
      target: 3,
      freq_id: 2,
      value: 3,
      label: "B - 3X",
    },
    {
      id: 3,
      name: "2X",
      target: 2,
      freq_id: 3,
      value: 2,
      label: "C - 2X",
    },
    {
      id: 4,
      name: "1X",
      target: 1,
      freq_id: 4,
      value: 1,
      label: "D - 1X",
    },
    {
      id: 5,
      name: "0X",
      target: 0,
      freq_id: 5,
      value: 0,
      label: "OUTLET - 0X",
    },
  ];

  const [selectedDate, setSelectedDate] = useState("");
  const [itiHistoryRequest, setItiHistoryRequest] = useState([]);

  useEffect(() => {
    if (getItineraryHistoryFromDB && getItineraryHistoryFromDB?.length > 0) {
      onDateChangehandler("", getItineraryHistoryFromDB);
    }
  }, [getItineraryHistoryFromDB]);

  const onDateChangehandler = async (event, itinerary) => {
    const selectedD = dateFormatting(event);
    const updatedHisReqList = itinerary ? itinerary : getItineraryHistoryFromDB;
    const result = updatedHisReqList.filter(
      (item) =>
        DateUtility.month(item?.created_at) === DateUtility.month(selectedD)
    );
    const result2 = result.sort(
      (a, b) =>
        new Date(b?.created_at || b?.record_time) -
        new Date(a?.created_at || a?.record_time)
    );
    const newData = await Promise.all(
      result2.map(async (item) => {
        if (!item?.base64?.includes("base64")) {
          const base64 = await getMediaFileFromDB(item, "excel_file", "base64");
          return {
            ...item,
            base64: base64,
          };
        } else {
          return item;
        }
      })
    );
    setSelectedDate(selectedD);
    setItiHistoryRequest(newData);
  };

  const getNotificationCount = (list) => {
    const notiCount = list ? list.length.toString().padStart(2, "0") : 0;
    return notiCount;
  };

  const createMDsId = (numberId) => {
    let stringWithLeadingZeros = String(numberId).padStart(5, "0");
    return stringWithLeadingZeros;
  };
  const getWeekName = (preDatesArr, newDatesArr) => {
    if (newDatesArr.length === 0) {
      return moment(preDatesArr[0].date).format("ddd");
    } else {
      return moment(newDatesArr[0].date).format("ddd");
    }
  };
  const getUpdatedDate = (createdAt, updatedAt) => {
    if (updatedAt) {
      return (
        <span>
          {moment(updatedAt).format("MM/DD/YY")}
          <br />
          {moment(updatedAt).format("h:mm:ss A")}
        </span>
      );
    } else {
      return (
        <span>
          {moment(createdAt).format("MM/DD/YY")}
          <br />
          {moment(createdAt).format("h:mm:ss A")}
        </span>
      );
    }
  };
  const getModifiedDates = (preDatesArr, newDatesArr) => {
    let actualDates;
    if (newDatesArr.length === 0) {
      actualDates = preDatesArr;
    } else {
      actualDates = newDatesArr;
    }
    const modifiedDates = actualDates.map((dateObj) => ({
      date: moment(dateObj.date, "YYYY-MM-DD").format("MM/DD/YY"),
    }));
    return modifiedDates;
  };

  const getClassNameByFreq = (frequency) => {
    return frequency === 1
      ? "A"
      : frequency === 2
      ? "B"
      : frequency === 3
      ? "C"
      : frequency === 4
      ? "D"
      : "OUTLET";
  };

  const getFrequencyById = (frequency) => {
    const result = freqOption.filter((item) => item.id === frequency);
    return result ? result[0].name : "";
  };

  const renderImageList = (request) => {
    return (
      <>
        {request?.excel_file ? (
          (request?.excel_file?.size &&
            request?.excel_file?.type?.includes("xls")) ||
          (!request?.excel_file?.size &&
            request?.excel_file?.includes("xls")) ||
          request?.excel_file?.name?.includes("xls") ? (
            <span>{ExcelIcon}</span>
          ) : (
            <ImageCard
              src={
                request?.excel_file?.size
                  ? URL.createObjectURL(request?.excel_file)
                  : request?.base64?.includes("base64")
                  ? request?.base64
                  : process.env.REACT_APP_IMAGE_URL + request?.excel_file
              }
              alt="Attachment"
              imagePreview={true}
            />
          )
        ) : (
          "-"
        )}
      </>
    );
  };

  return (
    <div className="tc-body">
      <div className="tc-navbar-back-text">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => navigate(routes.itinerary)}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title">UPDATES REQUESTS HISTORY</p>
        <div className="tc-navbar-right-btn"></div>
      </div>

      <div className="tc-md-request-history">
        <div className="tc-report-header d-flex justify-content-between align-items-center custom-datepicker-box mb-3">
          <div className="tc-report-header-inner-box ">
            <p className="mb-0 fw-semibold tc-report-header-date">
              {selectedDate
                ? moment(selectedDate).format("MMMM yyy")
                : moment().format("MMMM yyy")}
              <label htmlFor="selectedDate">{OutOfFieldIcon}</label>
            </p>
            <ReactDatePicker
              id="selectedDate"
              name="selectedDate"
              value={selectedDate}
              onChange={(e) => onDateChangehandler(e)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              minDate={moment().add(-1, "months").startOf("month")._d}
              maxDate={moment()._d}
              className="d-none"
              selected={selectedDate ? new Date(selectedDate) : new Date()}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>
          <div className="tc-requests-content">
            <h5 className="text-end my-2">{`Total Number of Request: ${getNotificationCount(
              itiHistoryRequest
            )}`}</h5>
          </div>
        </div>
        <div className="tc-table-wrapper tc-white-table-wrapper">
          <div className="table-responsive">
            <Table className="tc-table">
              <thead>
                <tr>
                  <th className="text-center">TYPE</th>
                  <th className="text-center">MD CODE</th>
                  <th className="text-center">MD NAME</th>
                  <th className="text-center">MONTH</th>
                  <th className="text-center">DAY</th>
                  <th className="text-center">DATES</th>
                  <th className="text-center">CLASS</th>
                  <th className="text-center">FREQUENCY</th>
                  <th className="text-center">REASON</th>
                  <th className="text-center">ATTACHMENT</th>
                  <th className="text-center">STATUS</th>
                  <th className="text-center">DATE SUBMITTED</th>
                </tr>
              </thead>
              <tbody>
                {itiHistoryRequest?.length === 0 ? (
                  <tr>
                    <td colSpan={12} className="text-center">
                      <DataNotFound message={"No data found!"} />
                    </td>
                  </tr>
                ) : (
                  itiHistoryRequest &&
                  itiHistoryRequest?.length > 0 &&
                  itiHistoryRequest.map((itinerary, index) => (
                    <tr
                      key={index}
                      className={` ${
                        !checkRequestIsEnable(itinerary) && "bg-color-gray"
                      }`}
                    >
                      <td className="text-center">
                        {itinerary.type_request
                          ? itinerary.type_request === "INSERT"
                            ? "ADD"
                            : itinerary.type_request === "DELETE"
                            ? "REMOVE"
                            : itinerary.type_request
                          : "-"}
                      </td>
                      <td className="text-center min-width-200">
                        {itinerary.code
                          ? itinerary.code !== "0"
                            ? itinerary.code
                            : createMDsId(itinerary.itinerary_id)
                          : "-"}
                      </td>
                      <td className="text-center min-width-250">
                        {nameFormatting(itinerary)}
                      </td>
                      <td className="text-center">
                        {itinerary.month
                          ? itinerary.month.substr(0, 3).toUpperCase()
                          : "-"}
                      </td>
                      <td className="text-center">
                        {getWeekName(
                          itinerary.dates && typeof itinerary.dates === "string"
                            ? JSON.parse(itinerary.dates)
                            : itinerary.dates,
                          itinerary.update_dates
                            ? typeof itinerary.update_dates === "string"
                              ? JSON.parse(itinerary.update_dates)
                              : itinerary.update_dates
                            : []
                        ).toUpperCase()}
                      </td>
                      <td className="text-center">
                        {getModifiedDates(
                          itinerary.dates && typeof itinerary.dates === "string"
                            ? JSON.parse(itinerary.dates)
                            : itinerary.dates,
                          itinerary.update_dates
                            ? typeof itinerary.update_dates === "string"
                              ? JSON.parse(itinerary.update_dates)
                              : itinerary.update_dates
                            : []
                        )?.map((dateObj, i) => (
                          <div key={i}>{dateObj.date}</div>
                        ))}
                      </td>
                      <td className="text-center">
                        {itinerary.update_frequency_id
                          ? getClassNameByFreq(itinerary.update_frequency_id)
                          : itinerary.frequency_id
                          ? getClassNameByFreq(itinerary.frequency_id)
                          : "-"}
                      </td>
                      <td className="text-center">
                        {itinerary.update_frequency_id
                          ? getFrequencyById(itinerary.update_frequency_id)
                          : itinerary.frequency_id
                          ? getFrequencyById(itinerary.frequency_id)
                          : "-"}
                      </td>
                      <td className="text-center min-width-250">
                        {itinerary.reason ? itinerary?.reason : "-"}
                      </td>
                      <td className="text-center min-width-250">
                        {renderImageList(itinerary)}
                      </td>
                      <td className="text-center min-width-250">
                        {manageRequestStatus(itinerary)}
                        {!checkRequestIsEnable(itinerary) && (
                          <div>
                            {moment(itinerary.updated_at).format("MM/DD/YY")}
                          </div>
                        )}
                      </td>
                      <td className="text-center min-width-200">
                        {getUpdatedDate(
                          itinerary.created_at,
                          itinerary.updated_at
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateRequestHistory;
