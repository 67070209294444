import moment from "moment";
import { toast } from "react-toastify";
import { messages } from "./messages";
import { isDesktop } from "react-device-detect";
import { db, insertEntryInDB } from "./db";

const toastList = new Set();
const MAX_TOAST = 2;
export const API_TIME_OUT = 200;
export const notify = (test, message) => {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (message === "success") {
      id = toast.success(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else if (message === "warning") {
      id = toast.warning(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
};
export const notifyAPIMessages = (res) => {
  if (res.status === 200 || res.status === 201) {
    notify(res.data?.message, "success");
  } else {
    const errorMessage = res.response
      ? res.response.data?.message
      : res.message;
    notify(errorMessage, "error");
  }
};

var dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthNamesOptions = [
  {
    value: "1",
    label: "January",
  },
  {
    value: "2",
    label: "February",
  },
  {
    value: "3",
    label: "March",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "June",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "August",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];
export const weekNameOptions = [
  { value: "Mon", label: "Mon" },
  { value: "Tue", label: "Tue" },
  { value: "Wed", label: "Wed" },
  { value: "Thu", label: "Thu" },
  { value: "Fri", label: "Fri" },
];
export const itineraryMethods = [
  { id: 1, type: "ADD", value: "ADD", label: "Add Itinerary" },
  { id: 2, type: "EDIT", value: "EDIT", label: "Edit Itinerary" },
  { id: 3, type: "REMOVE", value: "REMOVE", label: "Remove Itinerary" },
  { id: 4, type: "VIEW", value: "VIEW", label: "View Itinerary" },
  { id: 5, type: "SUMMARY", value: "SUMMARY", label: "Summary" },
  {
    id: 6,
    type: "WSUMMARY",
    value: "SUMMARY",
    label: "Summary (Weekly Totals)",
  },
];
export const mdMethods = [
  { type: "ADD", value: "INSERT", label: "Add New MD" },
  { type: "REMOVE", value: "DELETE", label: "Delete MD" },
  { type: "EDIT", value: "EDIT", label: "Edit MD Info" },
  { type: "VIEW", value: "VIEW", label: "View MD Info" },
  { type: "SUMMARY", value: "SUMMARY", label: "MD REQUESTS HISTORY" },
];
export const summaryReportType = [
  {
    type: "",
    name: "Already Approved:",
    value: "Already Approved:",
    approve_request: "",
    type_request: "",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Add to Itinerary",
    value: "Added",
    approve_request: "APPROVED",
    type_request: "INSERT",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Remove from Itinerary",
    value: "Removed",
    approve_request: "APPROVED",
    type_request: "DELETE",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Edit Itinerary",
    value: "Edited",
    approve_request: "APPROVED",
    type_request: "EDIT",
    count: 0,
  },
  {
    type: "",
    name: "October 2024 Totals",
    value: "October 2024 Totals",
    approve_request: "",
    type_request: "",
    count: 0,
  },
  {
    type: "",
    name: "For Approval:",
    value: "For Approval:",
    approve_request: "",
    type_request: "",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Add to Itinerary",
    value: "approvalAdded",
    approve_request: "INSERT",
    type_request: "INSERT",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Remove from Itinerary",
    value: "approvalRemoved",
    approve_request: "DELETE",
    type_request: "DELETE",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Edit Itinerary",
    value: "approvalEdited",
    approve_request: "EDIT",
    type_request: "EDIT",
    count: 0,
  },
  {
    type: "",
    name: "Total if all are Approved",
    value: "Total if all are Approved",
    approve_request: "",
    type_request: "",
    count: 0,
  },
];
export const summaryWReportType = [
  {
    id: 1,
    name: "Week 1",
    value: "Week 1",
    type: "WSUMMARY",
    count: 0,
  },
  {
    id: 2,
    name: "Week 2",
    value: "Week 2",
    type: "WSUMMARY",
    count: 0,
  },
  {
    id: 3,
    name: "Week 3",
    value: "Week 3",
    type: "WSUMMARY",
    count: 0,
  },
  {
    id: 4,
    name: "Week 4",
    value: "Week 4",
    type: "WSUMMARY",
    count: 0,
  },
];

// GET START, END DATE AND MONTH WEEKS
const dateFormatConvertor = (date, month, year) => {
  let dd = date < 10 ? "0" + date : date;
  let mm = month < 10 ? "0" + month : month;
  let yyyy = year;
  return yyyy + "-" + mm + "-" + dd;
};
export const getStartDateAndEndDate = (type, week, month, defDate) => {
  const currDate = new Date();
  const currYear = currDate.getFullYear();
  const currMonth =
    typeof month !== "number" ? monthArray.indexOf(month) : month;
  const startDate = new Date(currYear, currMonth, 1);
  const endDate = new Date(currYear, currMonth + 1, 0);

  if (type === "day") {
    const weeks = [];
    const numDays = endDate.getDate();
    let dayOfWeekCounter = startDate.getDay() - 1;
    for (let i = 1; i <= numDays; i++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(i);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }
    const weekData = weeks
      .filter((w) => w.length !== 0)
      .map((w) => ({
        start: dateFormatConvertor(w[0], currMonth + 1, currYear),
        end: dateFormatConvertor(w[w.length - 1], currMonth + 1, currYear),
        dates: w.map((d, i) => {
          return {
            date: dateFormatConvertor(d, currMonth + 1, currYear),
            day: dayNames[i],
          };
        }),
      }));
    return {
      report: type,
      startDate: weekData[week - 1]?.start,
      endDate: weekData[week - 1]?.end,
      dates: weekData[week - 1].dates,
      week: week,
    };
  } else if (type === "week") {
    const weeks = [];
    const numDays = defDate ? defDate : endDate.getDate();
    let dayOfWeekCounter = startDate.getDay() - 1;

    for (let i = 1; i <= numDays; i++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(i);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    const weekData = weeks
      .filter((w) => w.length !== 0)
      .map((w, i) => ({
        dates: w,
        weekDetails: {
          id: i + 1,
          label: `Week ${i + 1}`,
          value: i + 1,
        },
      }));
    const weekDates = weeks
      .filter((w) => w.length !== 0 && w?.length === 7)
      .map((w) =>
        w.map((d) => {
          return dateFormatConvertor(d, currMonth + 1, currYear);
        })
      );
    return {
      report: type,
      weekData: weekData,
      weekDates: weekDates,
      month: monthArray[month],
      year: currYear,
    };
  } else if (type === "month") {
    const months = [];
    const numMonths = month;
    for (let i = 0; i <= numMonths; i++) {
      months.push(monthArray[i]);
    }
    const monthData = months
      .filter((m) => m.length !== 0)
      .map((m, i) => ({
        monthDetails: { id: i + 1, lable: m, value: m },
      }));
    return {
      report: type,
      month: monthData,
    };
  } else if (type === "monthDates") {
    return { startDate: startDate, endDate: endDate };
  }
};
export const getStartDateOfWeek = (date) => {
  const startOfWeek = date ? new Date(date) : new Date();
  const day = startOfWeek.getDay();
  const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1);
  startOfWeek.setDate(diff);
  return startOfWeek;
};

// HANDLE DATE FORMATTING
const moment2 = require("moment-timezone");
export const defaultTimeZone = moment2.tz.guess();
export const convertDefaultTimeZone = (date) => {
  return moment
    .utc(date, "YYYY-MM-DD")
    .clone()
    .tz(defaultTimeZone)
    .format("YYYY-MM-DD");
};
export const dateFormatting = (date) => {
  const newDate = date ? date : new Date();
  return moment(newDate).format("YYYY-MM-DD");
};
export const dateFormatting2 = (date) => {
  return moment(date).format("MM-DD-YYYY");
};
export const dateFormatWithTime = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};
export const defaultDateFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MM/DD/YY");
};
export const defaultTimeFormat = (date) => {
  return moment(date).format("hh:mm:ss A");
};
export const dashDateFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MM-DD-YY");
};
export const defaultDateDashFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("YYYY-MM-DD");
};
export const dateWithTimeFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MM/DD/YY hh:mm A");
};
export const dateWithTimeDashFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MM-DD-YY hh:mm A");
};
export const timeWithHoursFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("h:mm A");
};
export const fullTimeFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("hh:mm:ss A");
};
export const getMonthAndDate = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MMM DD");
};
export const getMonthStartEndDate = (month) => {
  const start = moment()
    .add(month ? month : 0, "months")
    .startOf("month")
    .format();
  const end = moment()
    .add(month ? month : 0, "months")
    .endOf("month")
    .format();
  const year = moment()
    .clone()
    .add(month ? month : 0, "month")
    .format("YYYY");
  const cMonth = moment()
    .clone()
    .add(month ? month : 0, "month")
    .format("MMMM");
  return {
    start: new Date(start),
    end: new Date(end),
    year: Number(year),
    month: cMonth,
  };
};
export const getTimeDifferenceInMin = (date1, date2) => {
  const ndate1 = new Date(date1);
  const ndate2 = new Date(date2);
  const milliseconds = Math.abs(ndate2 - ndate1);
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(milliseconds / 1000 / 60);
  return { seconds, minutes };
};
export const DateUtility = {
  dashDate: (date) => moment(date).format("MM-DD-YYYY"),
  dateNTime: (date) => moment(date).format("hh:mm A, DD MMM YYYY"),
  ancDuration: (date) => moment(date).format("DD MMM YY"),
  todayDay: (date) => moment(date).weekday(),
  todayDate: (date) => moment(date).format("DD"),
  month: (date) => moment(date || new Date()).format("MMM"),
  monthYear: (date) => moment(date).format("MMM YYYY"),
  date24time: (date) => moment(date).format("ddd, MMM D, YYYY. h:mm"),
  AmPmTime: (date) => moment(date, "h:mm:ss A").format("LT"),
  nameDayMonthYear: (date) => moment(date).format("DD MMM YYYY"),
  dayMonthYearTime: (date) => moment(date).format("DD MMMM YYYY, hh:mm A"),
  monthDateYearTime: (date) => moment(date).format("MMMM DD, YYYY, HH:mm"),
  dayMonthYearTimeWithDash: (date) =>
    date ? moment(date).format("DD-MM-YYYY, hh:mm A") : " - ",
  dayMonthYearTimeWithSlash: (date) =>
    date ? moment(date).format("DD/MM/YYYY, hh:mm A") : " - ",
  AmPmTimeWithoutSecond: (date) => moment(date).format("hh:mm A"),
  nameDayMonthYearTime: (date) =>
    moment(date).format("ddd, DD MMM YYYY, hh:mm A"),
  dayMonthYear: (date) => moment(date).format("DD MMM YYYY"),
  requestDateTime: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
  dayMonth: (date) => moment(date).format("DD MMM"),
  fullDateTime: (date) => moment(date).format("MMMM DD, YYYY, hh:mm A"),
  requestTime: (date) => moment(date).format("HH:mm:ss"),
  orderTime: (date) => moment(date).format("DD MMM, hh:mm A"),
  dayMonthYear2: (date) => moment(date).format("DD/MM/YYYY"),
};

// GET LOCATION COORDINATES AND CHECK CAMERA PERMISSION
export const getLocationCoordinates = async () => {
  if (!navigator.geolocation) {
    notify(messages?.errors?.location_not_support, "error");
    return null;
  }

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, (error) => {
        const errorMessages = {
          [error.PERMISSION_DENIED]: {
            title: "Permission Denied",
            message: messages.errors.location_permission_issue,
          },
          [error.POSITION_UNAVAILABLE]: {
            title: "Position Unavailable",
            message: messages.errors.location_position_issue,
          },
          [error.TIMEOUT]: {
            title: "Request Timeout",
            message: messages.errors.location_timeout_issue,
          },
        };

        const errorDetails = errorMessages[error.code] || {
          title: "Location Unknown Issue",
          message: messages.errors.location_unknown_issue,
        };

        const reqObj = {
          date: new Date(),
          code: error.code,
          error: error.message,
          ...errorDetails,
        };

        insertEntryInDB("errorLogs", reqObj);
        reject(reqObj);
      });
    });
    return position.coords;
  } catch (error) {
    return "";
  }
};
export const checkSystemWebcam = () => {
  return new Promise((resolve, reject) => {
    if (isDesktop) {
      navigator.mediaDevices
        .getUserMedia({ video: { width: 300 } })
        .then((stream) => {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
          resolve(stream);
        })
        .catch((error) => {
          if (error.name === "NotAllowedError") {
            notify(messages.errors.camera_permission, "error");
          } else {
            notify(messages.errors.camera_not_support, "error");
          }
          reject(error);
        });
    } else {
      resolve();
    }
  });
};
export const getCameraStream = async (webcamRef, onChange) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { width: 800, height: 800, facingMode: "user" },
    });
    webcamRef.current.srcObject = stream;
    onChange(stream);
  } catch (error) {
    notify("Unable to access the front camera. Switching to the back camera.");
    try {
      const fallbackStream = await navigator.mediaDevices.getUserMedia({
        video: { width: 800, height: 800, facingMode: "environment" },
      });
      webcamRef.current.srcObject = fallbackStream;
    } catch (error2) {
      notify("Unable to access any camera.");
    }
  }
};

// GET TIME DIFFERENCE
export const getTimeDifference = (start, end) => {
  let startTime = new Date(start).getTime();
  let endTime = new Date(end).getTime();
  let seconds = Math.round(endTime - startTime);
  return seconds;
};
export const getPrevious7dayDate = (selectedDate) => {
  return moment(selectedDate)
    .subtract(7, "days")
    .add(1, "days")
    .format("YYYY-MM-DD");
};
export const getPrevious7DaysArr = (selectedDate) => {
  let datesArray = [];
  let currentDate = moment(selectedDate);

  for (let i = 0; i < 7; i++) {
    datesArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate.subtract(1, "days");
  }

  return datesArray;
};

export const getUniqueMDData = (dataList) => {
  const uniqueData = {};
  dataList &&
    dataList?.length > 0 &&
    dataList?.forEach((entry) => {
      const md_id = entry.md_id;
      if (!uniqueData[md_id]) {
        uniqueData[md_id] = {
          ...entry,
          dates: [
            {
              date: entry.date,
              is_summary: entry.is_summary,
              is_visited: entry.is_visited,
            },
          ],
        };
      } else {
        uniqueData[md_id].dates.push({
          date: entry.date,
          is_summary: entry.is_summary,
          is_visited: entry.is_visited,
        });
      }
    });
  const newUniqueData = Object.values(uniqueData);
  return newUniqueData;
};
export const updateDashboardDataByMDList = (dataList, oofList, holiday) => {
  const dashMData = {
    A: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    B: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    C: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    D: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
  };
  const dashDData = {
    A: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    B: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    C: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    D: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
  };
  const dailyData = [];
  const monthlyData = [];
  const currentDate = defaultDateDashFormat(new Date());
  const newOOFList = oofList ? oofList : [];
  const newHoliday = holiday ? holiday : [];
  dataList &&
    dataList?.length > 0 &&
    dataList?.forEach((entry) => {
      const { name, is_summary, md_id, date, record_date } = entry;
      const entryDate = defaultDateDashFormat(date);
      const visitDate = record_date ? defaultDateDashFormat(record_date) : null;
      const oofMDList = getOOFMDListByDate(newOOFList, date);
      const holiday = getHolidayByDate(newHoliday, date);

      if (!dashMData[name]) {
        dashMData[name] = {
          targetCalls: [],
          actualCalls: [],
          targetCount: [],
          actualCount: [],
        };
        dashDData[name] = {
          targetCalls: [],
          actualCalls: [],
          targetCount: [],
          actualCount: [],
        };
      }

      // Get dashboard monthly data
      if (!oofMDList.includes(md_id) && holiday?.date !== date) {
        dashMData[name].targetCalls.push(entry);
      }
      if (is_summary === 1) {
        dashMData[name].actualCalls.push(entry);
      }
      if (!dashMData[name].targetCount[md_id]) {
        dashMData[name].targetCount[md_id] = { ...entry };
      }
      if (is_summary === 1 && !dashMData[name].actualCount[md_id]) {
        dashMData[name].actualCount[md_id] = { ...entry };
      }

      // Get dashboard perDay data
      if (entryDate <= currentDate) {
        if (!oofMDList.includes(md_id) && holiday?.date !== date) {
          dashDData[name].targetCalls.push(entry);
        }
        if (!dashDData[name].targetCount[md_id]) {
          dashDData[name].targetCount[md_id] = { ...entry };
        }
        if (is_summary === 1 && !dashDData[name].actualCount[md_id]) {
          dashDData[name].actualCount[md_id] = { ...entry };
        }
      }
      if (visitDate !== null && visitDate <= currentDate && is_summary === 1) {
        dashDData[name].actualCalls.push(entry);
      }
    });

  // Get dashboard monthly data
  Object.keys(dashMData).forEach((className, index) => {
    const { targetCalls, actualCalls, targetCount, actualCount } =
      dashMData[className];
    const newTargetCount = Object.values(targetCount);
    const newActualCount = Object.values(actualCount);

    monthlyData[index] = {
      id: index + 1,
      class: className,
      actual_call: actualCalls.length,
      actual_md: newActualCount.length,
      f2f_call: 0,
      perf_call: 0,
      perf_md: 0,
      virtual_call: 0,
      target_call: targetCalls.length,
      target_md: newTargetCount.length,
    };
  });

  // Get dashboard perDay data
  Object.keys(dashDData).forEach((className, index) => {
    const { targetCalls, actualCalls, targetCount, actualCount } =
      dashDData[className];
    const newTargetCount = Object.values(targetCount);
    const newActualCount = Object.values(actualCount);

    dailyData[index] = {
      id: index + 1,
      class: className,
      actual_call: actualCalls.length,
      actual_md: newActualCount.length,
      f2f_call: 0,
      perf_call: 0,
      perf_md: 0,
      virtual_call: 0,
      target_call: targetCalls.length,
      target_md: newTargetCount.length,
    };
  });

  if (monthlyData && monthlyData?.length > 0) {
    db.dashBoardMonthly.clear();
    monthlyData &&
      monthlyData?.map(async (item) => {
        await insertEntryInDB("dashBoardMonthly", item);
      });
  }
  if (dailyData && dailyData?.length > 0) {
    db.dashBoardPerDay.clear();
    dailyData &&
      dailyData?.map(async (item) => {
        await insertEntryInDB("dashBoardPerDay", item);
      });
  }
  return { dailyData, monthlyData };
};
export const getNewMDListByRecordDate = async (mdList) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const newMDList = await updateMDListByRecordDate(mdList);
  const updatedMDList = newMDList.map((item) => {
    if (
      item.today !== currentDate &&
      item.is_visited === 1 &&
      item.is_summary === 0
    ) {
      return {
        ...item,
        today: currentDate,
        is_visited: 0,
      };
    } else {
      return {
        ...item,
        today: currentDate,
      };
    }
  });
  return updatedMDList?.length > 0 ? updatedMDList : newMDList;
};
export const updateMDListByRecordDate = async (callList) => {
  if (!callList || callList?.length === 0) return [];
  let newCalls = [...callList];

  const groupedCallList = newCalls.reduce((acc, call) => {
    if (!acc[call.md_id]) {
      acc[call.md_id] = [];
    }
    acc[call.md_id].push(call);
    return acc;
  }, {});

  const dayDiffClass = ["A", "B"];
  const weekDiffClass = ["C", "D"];
  const currDate = new Date();

  const filteredCalls = Object.values(groupedCallList).flatMap((calls) => {
    const sortedCalls = calls.sort(
      (a, b) => new Date(b?.record_date) - new Date(a?.record_date)
    );
    const lastCall = sortedCalls.find(
      (call) => call?.record_date && call?.is_summary
    );
    const newCurrDate = currDate ? defaultDateDashFormat(currDate) : null;
    const lastCallDate = lastCall ? new Date(lastCall?.record_date) : null;
    const newLastCallDate = lastCallDate
      ? defaultDateDashFormat(lastCallDate)
      : null;

    const weekStartDate = getStartDateOfWeek(currDate);
    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekEndDate.getDate() + 6);

    const newWeekSDate = defaultDateDashFormat(weekStartDate);
    const newWeekEDate = defaultDateDashFormat(weekEndDate);

    const weekCalls = sortedCalls.filter((call) => {
      const callDate =
        call?.record_date && call?.is_summary
          ? defaultDateDashFormat(call?.record_date)
          : null;
      return (
        dayDiffClass.includes(call.name) &&
        callDate >= newWeekSDate &&
        callDate <= newWeekEDate
      );
    });
    const isWeekCall = weekCalls.length >= 2;
    return sortedCalls.map((call) => {
      const dayDifference = Math.abs(
        Math.floor(
          (new Date(newCurrDate) - new Date(newLastCallDate)) /
            (1000 * 60 * 60 * 24)
        )
      );
      if (dayDiffClass.includes(call.name)) {
        call.is_week_call = isWeekCall ? 1 : 0;
        call.is_consecutive = dayDifference >= 2 ? 0 : 1;
      } else if (weekDiffClass.includes(call.name)) {
        call.is_consecutive = dayDifference >= 2 ? 0 : 1;
        call.is_week_call =
          newLastCallDate >= newWeekSDate && newLastCallDate <= newWeekEDate
            ? 1
            : 0;
      }
      return call;
    });
  });
  return filteredCalls?.length > 0 ? filteredCalls : callList;
};
export const getOOFMDListByDate = (oofList, sDate) => {
  let mdIdsArray = [];
  const getDisabledMds =
    oofList &&
    oofList?.length > 0 &&
    oofList?.filter((item) => item.date === sDate);
  if (getDisabledMds && getDisabledMds?.length > 0) {
    mdIdsArray = getDisabledMds[0]?.md_ids;
  }
  return mdIdsArray;
};
export const getHolidayByDate = (holidayList, sDate) => {
  let getHoliday = "";
  if (holidayList && holidayList?.length > 0) {
    return (
      (getHoliday = holidayList?.find((item) => item.date === sDate)) || ""
    );
  } else {
    return getHoliday;
  }
};

// MANAGE MEDIA FILES
let summaryArr = [];
let activitiesArr = [];
export const extractUrls = (type, listArr) => {
  if (type === "activities" && activitiesArr?.length !== listArr?.length) {
    const productDetailings = listArr[0]?.product_detailing;
    let imageURLs = [];
    productDetailings.forEach((item) => {
      if (!item?.pdf_url.endsWith(".pdf")) {
        imageURLs.push(item.pdf_url);
      }
      if (item.subProduct && item.subProduct?.length > 0) {
        item.subProduct.forEach((subp) => {
          if (!subp?.pdf_url.endsWith(".pdf")) {
            imageURLs.push(subp.pdf_url);
          }
        });
      }
    });
    cacheImages(imageURLs);
    activitiesArr = listArr;
  }
  if (type === "summary" && summaryArr?.length !== listArr?.length) {
    const mdListArrWithData = listArr?.filter((item) => item.data);
    const imageURLs =
      mdListArrWithData?.map(
        (item) => process.env.REACT_APP_IMAGE_URL + item.data.signature_url
      ) || [];
    cacheImages(imageURLs);
    summaryArr = listArr;
  }
};
export const cacheImages = async (srcArray) => {
  const promises = await srcArray.map((src) => {
    return new Promise(async function (resolve, reject) {
      if (src.endsWith(".pdf")) {
        try {
          const response = await fetch(src);
          if (!response.ok) {
            reject(new Error("Network response was not OK"));
            return;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      }
    });
  });
  await Promise.all(promises)
    .then(() => {})
    .catch(() => {});
};
export const fetchMediaData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch file from ${url}: ${response.statusText}`);
  }
  return await response.blob();
};
export const mediaToBase64 = async (media) => {
  return new Promise((resolve, reject) => {
    if (!media || !media?.size) return media;
    const reader = new FileReader();
    reader.readAsDataURL(media);
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(media);
      }
    };
    reader.onerror = () => {
      reject(media);
    };
  });
};
export const compressMediaFiles = async (file) => {
  const canvas = document.createElement("canvas");
  const img = new Image();
  img.src = URL.createObjectURL(file);

  return new Promise((resolve) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);

      canvas.toBlob(
        async (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        0.3
      );
    };
  });
};

// OTHER FUNCTIONS
export const nameFormatting = (user, type) => {
  if (type === "FL") {
    return user && user?.firstname?.trim().concat(", ", user?.lastname?.trim());
  } else if (type === "FLC") {
    return user && user?.firstname?.trim().concat(" ", user?.lastname?.trim());
  } else if (type === "LF") {
    return user && user?.lastname?.trim().concat(" ", user?.firstname?.trim());
  } else {
    return user && user?.lastname?.trim().concat(", ", user?.firstname?.trim());
  }
};
export const getDifference = (num1, num2) => {
  let maxNum = Math.max(num1, num2);
  let minNum = Math.min(num1, num2);
  let difference = maxNum - minNum;
  return difference;
};
export const getAgeOfBirthday = (age) => {
  const ageStr = age.toString();
  const lastDigit = ageStr[ageStr.length - 1];
  const lastTwoDigits = age % 100;

  let suffix = "th";
  if (lastTwoDigits < 11 || lastTwoDigits > 13) {
    switch (lastDigit) {
      case "1":
        suffix = "st";
        break;
      case "2":
        suffix = "nd";
        break;
      case "3":
        suffix = "rd";
        break;
      default:
        suffix = "th";
        break;
    }
  }
  return age + suffix;
};
export const getClassByFreq = (freq) => {
  if (freq === "4X") {
    return "A";
  } else if (freq === "3X") {
    return "B";
  } else if (freq === "2X") {
    return "C";
  } else if (freq === "1X") {
    return "D";
  } else if (freq === "0X") {
    return "OUTLET";
  } else {
    return "";
  }
};
export const checkRequestIsEnable = (request) => {
  if (request?.month === moment().clone().add(0, "month").format("MMMM")) {
    return false;
  } else if (
    request?.status === "APPROVED" ||
    request?.status === "DISAPPROVED" ||
    request.approve_request === "APPROVED" ||
    request.approve_request === "DISAPPROVED"
  ) {
    return false;
  } else {
    return true;
  }
};

export const manageRequestStatus = (request, type, isSimple) => {
  if (type === "VMC" && request?.reason && request?.reason?.charAt(0) !== "*") {
    return "RECORDED";
  } else if (
    request.status === "APPROVED" ||
    request.status === "DISAPPROVED" ||
    request.approve_request === "APPROVED" ||
    request.approve_request === "DISAPPROVED"
  ) {
    return request.status || request.approve_request;
  } else if (
    request.status === "ADMIN_APPROVAL" ||
    request.approve_request === "ADMIN_APPROVAL"
  ) {
    if (request?.month === moment().clone().add(0, "month").format("MMMM")) {
      return "NOT APPROVED BY ADMIN";
    } else if (type === "VMC" && isSimple) {
      return "FOR APPROVAL";
    } else {
      return "FOR ADMIN APPROVAL";
    }
  } else {
    if (request?.month === moment().clone().add(0, "month").format("MMMM")) {
      return "NOT APPROVED BY DM";
    } else if (type === "VMC" && isSimple) {
      return "FOR APPROVAL";
    } else {
      return "FOR DM APPROVAL";
    }
  }
};

export const manageStatus = (type) => {
  if (type === "DISAPPROVED") {
    return "Disapproved";
  } else if (type === "APPROVED") {
    return "Approved";
  } else if (type === "ADMIN_APPROVAL") {
    return "Approved";
  } else {
    return type;
  }
};

export const numberFormatting = (number) => {
  const formattedNumber = new Intl.NumberFormat().format(number && number);
  return formattedNumber;
};
export const handleVisits = (actual, target) => {
  if (target > 0) {
    return actual > 0 ? actual + " of " + target : 0 + " of " + target;
  } else {
    return 0 + " of " + 0;
  }
};
export const copyToClipboard = (item, type) => {
  if (type === "error") {
    const newItem = {};
    if (item?.response) {
      newItem.message = item.message;
      newItem.data = item?.response?.data;
      newItem.status = item?.response?.status;
      newItem.statusText = item?.response?.statusText;
      newItem.responseURL = item?.response?.request?.responseURL;
      navigator.clipboard.writeText(JSON.stringify(newItem));
    } else {
      newItem.message = item.message;
      newItem.data = item?.request?.response;
      newItem.status = item?.request?.status;
      newItem.statusText = item?.request?.statusText;
      newItem.responseURL = item?.request?.responseURL;
      navigator.clipboard.writeText(newItem);
    }
  } else {
    navigator.clipboard.writeText(JSON.stringify(item));
  }
  notify("Copied Successfully", "success");
};
export const modifyText = (data) => {
  return data ? data?.trim().toLowerCase() : data;
};
export const getSortData = (data, key) => {
  if (data && data?.length === 0) return [];
  if (key) {
    const newData =
      data &&
      data?.length > 0 &&
      data.sort((a, b) => a[key].localeCompare(b[key]));
    return newData;
  } else {
    return data;
  }
};
export const getSeconds = (data) => {
  let mSeconds = data > 0 ? data : 0;
  let seconds = Math.round(mSeconds / 1000);
  return `${seconds} ${seconds > 1 ? "seconds" : "second"}`;
};
export const createMonthCalendar = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  const firstDay = new Date(year, month - 1, 1).getDay();
  const prevMonthDays = new Date(year, month - 1, 0).getDate();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const calendar = [];
  for (let i = firstDay; i > 0; i--) {
    calendar.push({
      date: prevMonthDays - i + 1,
      day: dayNames[firstDay - i],
      is_month: 0,
    });
  }

  for (let day = 1; day <= daysInMonth; day++) {
    const dayName = dayNames[(firstDay + day - 1) % 7];
    calendar.push({ date: day, day: dayName, is_month: 1 });
  }

  const totalDays = calendar.length;
  const leadingDaysCount = totalDays % 7 === 0 ? 0 : 7 - (totalDays % 7);
  for (let day = 1; day <= leadingDaysCount; day++) {
    const dayName = dayNames[(firstDay + daysInMonth + day) % 7];
    calendar.push({ date: day, day: dayName, is_month: 0 });
  }

  const weeks = [];
  for (let i = 0; i < calendar.length; i += 7) {
    weeks.push(calendar.slice(i, i + 7));
  }
  return weeks;
};
export const filterUniqueData = (data, type) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  return Array.from(
    new Map(
      data.map((item) => [
        `${item?.reason}|${JSON.stringify(item?.md_list)}|${item?.date}|${
          item?.user_id
        }`,
        item,
      ])
    ).values()
  );
};
