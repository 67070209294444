import React from "react";
import moment from "moment";
import { Navigate } from "react-router-dom";
import { routes } from "./helpers/constants";
import { AuthLayout, MainLayout, BrowserLayout } from "./layout";
import Page404 from "./pages/Page404/Page404";
import {
  authRoutes,
  mainRoutes,
  timeoutRoutes,
  invalidRoutes,
  limitedRoutes,
  compatibilityRoutes,
} from "./helpers/AllRoutes";
import { dateFormatting } from "./helpers/commonFunction";
import { browserName } from "react-device-detect";

const getRoutes = (user) => {
  const userTimeIn = () => {
    if (user && !user?.time_in) {
      return false;
    } else {
      return true;
    }
  };

  const userTimeOut = () => {
    let getOldTime = "";
    let getNewTime = "";
    let localDBDate = "";
    if (user && user?.timeout_date) {
      if (user?.timeout_date?.trim()?.includes(" ")) {
        localDBDate = user?.timeout_date?.trim()?.split(" ")[0];
      }
      if (localDBDate) {
        getOldTime = localDBDate;
        getNewTime = dateFormatting(new Date());
      } else {
        getOldTime = moment.utc(user?.timeout_date).format("YYYY-MM-DD");
        getNewTime = dateFormatting(new Date());
      }
    } else if (user && user?.timein_date) {
      if (user?.timein_date?.trim()?.includes(" ")) {
        localDBDate = user?.timein_date?.trim()?.split(" ")[0];
      }
      if (localDBDate) {
        getOldTime = localDBDate;
        getNewTime = dateFormatting(new Date());
      } else {
        getOldTime = moment.utc(user?.timein_date).format("YYYY-MM-DD");
        getNewTime = dateFormatting(new Date());
      }
    } else {
      return false;
    }
    if (getOldTime !== getNewTime) {
      return true;
    } else {
      return false;
    }
  };

  const isSafariBrowser = () => {
    if (browserName && browserName?.toLowerCase()?.includes("safari")) {
      return true;
    } else {
      return false;
    }
  };

  return [
    {
      path: routes.homepage,
      element: !isSafariBrowser() ? (
        <BrowserLayout />
      ) : !user ? (
        <Navigate to={routes.login} />
      ) : !userTimeIn() ? (
        <Navigate to={routes.timeInOut} />
      ) : userTimeOut() ? (
        <Navigate to={routes.sync} />
      ) : (
        <Navigate to={routes.dashboard} />
      ),
      children: compatibilityRoutes,
    },
    {
      path: routes.homepage,
      element: !isSafariBrowser() ? (
        <Navigate to={routes.browserCompatibility} />
      ) : !user ? (
        <AuthLayout />
      ) : !userTimeIn() ? (
        <Navigate to={routes.timeInOut} />
      ) : userTimeOut() ? (
        <Navigate to={routes.sync} />
      ) : (
        <Navigate to={routes.dashboard} />
      ),
      children: authRoutes,
    },
    {
      path: routes.homepage,
      element: !isSafariBrowser() ? (
        <Navigate to={routes.browserCompatibility} />
      ) : user ? (
        <MainLayout />
      ) : (
        <Navigate to={routes.login} />
      ),
      children: !userTimeIn()
        ? limitedRoutes
        : userTimeOut()
        ? timeoutRoutes
        : mainRoutes,
    },
    {
      path: "",
      element: !isSafariBrowser() ? (
        <Navigate to={routes.browserCompatibility} />
      ) : !user ? (
        <Navigate to={routes.login} />
      ) : !userTimeIn() ? (
        <Navigate to={routes.timeInOut} />
      ) : userTimeOut() ? (
        <Navigate to={routes.sync} />
      ) : user.user_role === "TM" ? (
        <Navigate to={routes.dashboard} />
      ) : (
        <Page404 />
      ),
      children: invalidRoutes,
    },
  ];
};

export default getRoutes;
