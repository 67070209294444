export const routes = {
  all: "*",
  homepage: "/",
  browserCompatibility: "/check-browser-compatibility",
  login: "/login",
  dashboard: "/dashboard",
  mdList: "/md/mdList",
  mdBirthdayList: "/md/md-birthday",
  aTozMDList: "/md/all-mdList",
  editCallMdList: "/md/edit-call-mdList",
  missedCallMdList: "/md/missed-call-mdList",
  mdInfo: "/md/md-info",
  itinerary: "/itinerary",
  updateItinerary: "/itinerary/update-itinerary",
  updateItineraryHistory: "/itinerary/update-itinerary-history",
  itineraryPerDay: "/itinerary/itinerary-per-day",
  monthlyItinerary: "/itinerary/monthly-itinerary",
  callActivities: "/callActivities",
  quickSign: "/quickSign",
  viewCallSummary: "/view-call-summary",
  quickList: "/quickList",
  promoMaterials: "/promo-meterials",
  recivePromoMats: "/promo-meterials/receive",
  outOfField: "/outOfField",
  timeInOut: "/timeInOut",
  tactical: "/tactical",
  reports: "/reports",
  notifications: "/notifications",
  requestHistory: "/request-history",
  sync: "/sync",
};

export const apiendpoints = {
  login: "/login",
  logout: "/user/logout",
  dashboard: "/dashboard",
  mdList: "/md/list",
  incidentalMdList: "/quick-sign/universe-md-list",
  searchMD: "/md/search",
  activeCycle: "md/active-cycle",

  // Call recording (call activities)
  callActivities: "/call-recording/call-activities",
  productDetailing: "/call-recording/product-detailing",
  marketResearch: "/call-recording/market-research",
  productSamples: "/call-recording/product-sampling",
  jointCall: "/call-recording/joint-call",
  callSummary: "/call-recording/call-summary",
  media: "/media",
  mdSignature: "/call-recording/add-md-signature",
  addProductDetailing: "/call-recording/add-product-detailing",
  addMarketResearch: "/call-recording/add-market-research",
  addProductSampling: "/call-recording/add-product-sampling",
  addJointCall: "/call-recording/add-joint-call",
  getMDExcuse: "/call-recording/get-md-excuse",
  getMDExcuseReason: "/call-recording/get-md-excuse-reason",
  insertMDExcuse: "/call-recording/insert-md-excuse",
  getCallObjOptions: "/call-recording/get-call-objective-option",

  // QuickSign
  quickSignCount: "/quick-sign/count-quick-sign",
  quickSignlist: "/quick-sign/list-quick-sign",
  quickSignmdList: "/quick-sign/md-list-quick-sign",
  addSignature: "/quick-sign/add-quick-sign",
  deleteSignature: "/quick-sign/delete-quick-sign",
  addMD: "/quick-sign/add-md",
  quickSignUpdate: "/quick-sign/quick-sign-update",
  quickSign: "/quickSign",
  incidentalOptions: "/quick-sign/drop-down-md-option",
  updateQuickSign: "/quick-sign/update-quick-sign",

  // Promo material
  promoMaterialList: "/promomat/material-balance-list",
  promoMatsReasons: "/promomat/reject-reasons",
  updateMaterial: "/promomat/return-material",
  reciveMatsList: "/promomat/material-issued-list",
  adjustReasons: "/promomat/adjust-reasons",
  submitReceiveMats: "/promomat/receive-materials",

  // Out of Field
  outOfField: "/out-of-field/list-oof-data",
  outOfFieldModalData: "/out-of-field/get-model-data",
  addOutOfField: "/out-of-field/add-request-oof",
  getMDListByOof: "/out-of-field/get-md-list-by-oof",

  // get Location
  getLocation: "/get-directions",

  // get frequency
  getFrequency: "/md/frequency",

  // TimeInOut
  getTimeInOut: "/time/get-time",
  addTimeInOut: "/time/add-time",
  deleteTimeInOut: "/time/delete-time",

  // Itinerary
  addItinerary: "/itinerary/change-request",
  getHolidays: "/itinerary/holidays",
  getItinerary: "/itinerary/get-change-request",
  editItinerary: "/itinerary/edit-change-request",

  removeItinerary: "/itinerary/delete-change-request",
  getMDListForItinerary: "/itinerary/md-list",
  getTmList: "/itinerary/tm-list",
  getItineraryList: "/itinerary/get-change-request-list",
  getDisapprovalReasons: "/itinerary/disapproval-reason",
  disapprovalItinerary: "/itinerary/disapproval-request",
  approvalItinerary: "/itinerary/approval-request",
  reqToGetPromoMatsTypeList: "/promomat/promomat-type",
  getItinerarySummaryData: "/itinerary/summary/data",
  getSummaryReportData: "/itinerary/summary/report-data",
  getItineraryCallPlan: "/itinerary/summary/call-plan",
  getItineraryWeeklyData: "itinerary/summary/weekly-data",
  getWeeklyReportData: "itinerary/summary/weekly-report-data",

  // reports API
  callSummaryData: "/dashboard/call-summary-md-class",
  mdCoverageToday: "/dashboard/md-coverage-today",
  samplesIssuance: "/dashboard/samples-issuance",
  promoMatsIssuance: "/dashboard/promo-mats-issuance",
  callConcentration: "/dashboard/call-concentration",
  daysField: "/dashboard/days-field",
  mdExcuse: "/dashboard/md-excuse",
  promoMatsInventory: "/dashboard/promo-mats-inventory",
  incidentalCalls: "/dashboard/incidental-calls",
  dailyCallRate: "/dashboard/daily-call-rate",
  dailyMdReach: "/dashboard/daily-md-rate",
  monthlyCallRate: "/dashboard/monthly-call-rate",
  monthlyMdReach: "/dashboard/monthly-md-rate",

  // offline
  getMdItineraryData: "/off-line/md-list",
  getCumulativeCalls: "/off-line/get_cumulative-call",
  submitCallRecording: "/off-line/all-call-off-line",
  submitCumulativeCall: "/off-line/cumulative-call",
  offlineReports: "/off-line/reports",
  offLineQuickSignData: "/off-line/quick-sign",
  changeMDBirthday: "/off-line/changes-birthday",
  getMDMasterList: "/off-line/md-master-list",
  submitEditCall: "/off-line/edit-call",
  addMDReliever: "/md/add-md-reliever",
  holidaysFrequency: "/itinerary/holidays-frequency",
  getMDInfoDetails: "/call-recording/get-md-insert-data",
  getMDRequest: "/md/get-md-request",
  addMDRequest: "/md/add-md-request",

  // notificaton
  getNotificationCount: "/notification/count",
  getNotificationType: "/notification/list",
  getNotifications: "/notification/list-data",
  getBirthDayData: "/notification/birth-day-data",
  readNotifications: "/notification/read-notification",
};
