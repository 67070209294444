import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import moment from "moment/moment";
import { useForm } from "../../hooks";
import {
  modifyText,
  fullTimeFormat,
  dateFormatting,
  defaultDateFormat,
  dateFormatWithTime,
  getLocationCoordinates,
  manageRequestStatus,
  checkRequestIsEnable,
} from "../../helpers/commonFunction";
import { RecordOutField } from "../../components/Modals";
import { outOfFieldValidations } from "../../helpers/formValidations";
import { addIcon } from "../../icon/icon";
import { db, getAllEntriesFromDB, insertEntryInDB } from "../../helpers/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { submitOFFMissedCallWhenOnline } from "../../helpers/offlineAPIs";
import { useDispatch } from "react-redux";

const OutOfField = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getOOFListFromDB = useLiveQuery(() => db.oofListData.toArray(), []);

  const currentDate = dateFormatting();
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [mdListData, setMDListData] = useState([]);
  const [oofListFromDB, setOOFListFromDB] = useState([]);
  const [oofNotesFromDB, setOOFNotesFromDB] = useState([]);
  const [oofTypesFromDB, setOOFTypesFromDB] = useState([]);
  const [updatedList, setUpdatedList] = useState({});
  const [dateCycle, setDateCycle] = useState({});
  const [typeList, setTypeList] = useState([]);
  const [reasonsList, setReasonsList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedMD, setSelectedMD] = useState([]);
  const [disableMds, setDisableMds] = useState([]);

  useEffect(() => {
    const handleGetInitialData = async () => {
      const [oofListData, oofCycle, oofNotes, oofTypes] = await Promise.all([
        getAllEntriesFromDB("oofListData"),
        getAllEntriesFromDB("oofCycle"),
        getAllEntriesFromDB("oofNotes"),
        getAllEntriesFromDB("oofTypes"),
      ]);
      setOOFListFromDB(oofListData);
      setOOFNotesFromDB(oofNotes);
      setOOFTypesFromDB(oofTypes);
      setUpdatedList(divideArr(oofListData));
      if (oofCycle && oofCycle?.length > 0) {
        setDateCycle(oofCycle[0]);
      }
      if (oofTypes && oofTypes?.length > 0) {
        setTypeList(oofTypes);
      }
      if (oofNotes && oofNotes?.length > 0) {
        let sortedNotes = [];
        sortedNotes = [...oofNotes].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setReasonsList(sortedNotes);
      }
    };
    handleGetInitialData();
  }, [getOOFListFromDB]);

  const divideArr = () => {
    let updatedOutOfFieldList = {
      allDayArr: [],
      morningArr: [],
      afternoonArr: [],
    };
    for (let i = 0; i < getOOFListFromDB?.length; i++) {
      if (getOOFListFromDB[i].type_id === 1) {
        updatedOutOfFieldList.allDayArr.push(getOOFListFromDB[i]);
      } else if (getOOFListFromDB[i].type_id === 2) {
        updatedOutOfFieldList.morningArr.push(getOOFListFromDB[i]);
      } else {
        updatedOutOfFieldList.afternoonArr.push(getOOFListFromDB[i]);
      }
    }
    const data1 = filterAndMergeByStatus(updatedOutOfFieldList.allDayArr);
    const data2 = filterAndMergeByStatus(updatedOutOfFieldList.morningArr);
    const data3 = filterAndMergeByStatus(updatedOutOfFieldList.afternoonArr);
    updatedOutOfFieldList.allDayArr = data1;
    updatedOutOfFieldList.morningArr = data2;
    updatedOutOfFieldList.afternoonArr = data3;
    return updatedOutOfFieldList;
  };

  const filterAndMergeByStatus = (data) => {
    const mergeEntriesByDateAndReason = (statusData) => {
      return statusData.reduce((acc, curr) => {
        const existingEntry = acc.find(
          (entry) =>
            entry.date === curr.date &&
            entry.reason === curr.reason &&
            (modifyText(entry.reason) === "deliver food"
              ? dateFormatWithTime(entry?.record_time) ===
                dateFormatWithTime(curr?.record_time)
              : true)
        );
        curr.md_list = curr.md_list || [];
        curr.md_name = curr.md_name || [];

        if (existingEntry) {
          existingEntry.md_list = [
            ...new Set([...existingEntry?.md_list, ...curr?.md_list]),
          ];
          existingEntry.md_name = [
            ...existingEntry.md_name,
            ...curr?.md_name.filter(
              (md) => !existingEntry?.md_name.some((e) => e.id === md.id)
            ),
          ];
        } else {
          acc.push({
            ...curr,
            md_list: [...curr?.md_list],
            md_name: [...curr?.md_name],
          });
        }
        return acc;
      }, []);
    };

    const newItems = data.filter((item) => item.status === "NEW");
    const approved = data.filter((item) => item.status === "APPROVED");
    const disapproved = data.filter((item) => item.status === "DISAPPROVED");
    const adminApproval = data.filter(
      (item) => item.status === "ADMIN_APPROVAL"
    );

    const mergedNew = mergeEntriesByDateAndReason(newItems);
    const mergedApproved = mergeEntriesByDateAndReason(approved);
    const mergedDisapproved = mergeEntriesByDateAndReason(disapproved);
    const mergedAdminApproval = mergeEntriesByDateAndReason(adminApproval);

    const newArray = [
      ...mergedNew,
      ...mergedApproved,
      ...mergedDisapproved,
      ...mergedAdminApproval,
    ];
    return newArray;
  };

  const initialFValues = {
    date: currentDate,
    oof_type_id: "",
    notes_id: "",
    remark: "",
    image: "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return outOfFieldValidations(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors
    );
  };
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (values?.image) {
      setErrors({
        ...errors,
        image: "",
      });
    }
  }, [values?.image]);

  useEffect(() => {
    const reason =
      oofNotesFromDB &&
      oofNotesFromDB?.find(
        (item) => item?.notes_id === Number(values?.notes_id)
      );
    setSelectedReason(reason);
    if (
      values?.date &&
      (reason?.name.charAt(0) === "*" ||
        modifyText(reason?.name) === "deliver food")
    ) {
      handleGetMDByReason(values?.date);
    } else if (
      reason?.name?.charAt(0) !== "*" ||
      modifyText(reason?.name) !== "deliver food"
    ) {
      setSelectedMD([]);
      setDisableMds([]);
    }
  }, [values.date, values.notes_id]);

  const handleGetMDByReason = async (sDate) => {
    const [mdList, oofListData] = await Promise.all([
      getAllEntriesFromDB("mdList"),
      getAllEntriesFromDB("oofListData"),
    ]);
    if (mdList && mdList?.length > 0) {
      const newMdList = mdList.filter((item) => item.date === sDate);
      setMDListData(newMdList);
      const filterMD =
        newMdList &&
        newMdList
          .filter((oldMD) => oldMD?.is_summary === 0)
          .map((newMD) => newMD?.md_id);
      const length = filterMD && filterMD?.length;
      if (selectedType?.name?.toLowerCase() !== "all day") {
        setCount(Math.floor(length / 2));
      } else {
        setSelectedMD(filterMD);
        setCount(Math.floor(length));
      }
      const isExist = oofListData.filter(
        (item) => item?.name === selectedType?.name && item.date === sDate
      );
      if (isExist && isExist?.length > 0) {
        let newMDs = [];
        isExist.forEach((item) => {
          newMDs.push(...item.md_list);
        });
        setSelectedMD(newMDs);
        setDisableMds(newMDs);
      }
    } else {
      setCount(0);
      setSelectedMD([]);
      setDisableMds([]);
      setMDListData([]);
    }
  };

  const handleSubmitMissedCallData = async () => {
    await getLocationCoordinates()
      .then((res) => {
        handleMissedCallOBJ(res);
      })
      .catch(() => {
        handleMissedCallOBJ("");
      });
  };

  const checkOtherValidate = () => {
    if (values && !values?.image) {
      setErrors({
        ...errors,
        image: "Please add photo!",
      });
      return false;
    } else {
      setErrors({
        ...errors,
        image: "",
      });
      return true;
    }
  };

  const handleMissedCallOBJ = async (location) => {
    if (validate() && checkOtherValidate()) {
      setIsLoading(true);
      const getType =
        oofTypesFromDB &&
        oofTypesFromDB?.length > 0 &&
        oofTypesFromDB.find((item) => item?.type_id === +values.oof_type_id);
      const getReason =
        oofNotesFromDB &&
        oofNotesFromDB?.length > 0 &&
        oofNotesFromDB.find((item) => item.notes_id === +values.notes_id);
      const newMDs =
        selectedMD && getType?.name?.toLowerCase() !== "all day"
          ? selectedMD.filter((item) => !disableMds.includes(item))
          : selectedMD;
      const reqObj = {
        address: null,
        latitude: location && location?.latitude ? location?.latitude : null,
        longitude: location && location?.longitude ? location?.longitude : null,
        date: values.date,
        oof_type_id: +values.oof_type_id,
        notes_id: +values.notes_id,
        md_list: newMDs,
        remark: values.remark,
        record_time: new Date(),
        image: values?.image,
        name: getType?.name,
        reason: getReason?.name,
        type_id: getType?.type_id,
        status: "NEW",
      };
      if (reqObj?.reason?.charAt(0) !== "*") {
        reqObj.status = "APPROVED";
      }
      const insertID = await insertEntryInDB("oofListData", reqObj);
      await insertEntryInDB("oofMissedCalls", {
        ...reqObj,
        id: insertID,
      });
      handleAutoRefresh();
      setModalShow(false);
      setValues({
        ...values,
        date: currentDate,
        oof_type_id: "",
        notes_id: "",
        remark: "",
        image: "",
      });
      setSelectedMD([]);
      setIsLoading(false);
    }
  };
  const handleAutoRefresh = async () => {
    if (window.navigator.onLine) {
      const addedOOF = await getAllEntriesFromDB("oofMissedCalls");
      submitOFFMissedCallWhenOnline(dispatch, addedOOF, "addMissCall").catch(
        () => {}
      );
    }
  };

  const onCancelRecordHandler = () => {
    setModalShow(false);
    setErrors("");
    setValues({
      ...values,
      date: currentDate,
      oof_type_id: "",
      notes_id: "",
      remark: "",
      image: "",
    });
  };

  const handleModalShow = (type) => {
    setModalShow(true);
    let currType =
      typeList && typeList?.find((item) => item?.name.toUpperCase() === type);
    setSelectedType(currType);
    setValues({
      ...values,
      oof_type_id: currType.id,
    });
  };

  const getUpdatedDate = (createdAt, updatedAt) => {
    if (updatedAt) {
      return (
        <span>
          {defaultDateFormat(updatedAt)} {fullTimeFormat(updatedAt)}
        </span>
      );
    } else {
      return (
        <span>
          {defaultDateFormat(createdAt)} {fullTimeFormat(createdAt)}
        </span>
      );
    }
  };

  return (
    <>
      <div className="tc-body">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="tc-profile-wrapper mb-0">
            <span className="tc-profile-name">VMC</span>
            <Button
              variant="primary"
              className="w-100 ms-3"
              role="button"
              onClick={() =>
                navigate(routes.requestHistory, {
                  state: {
                    type: "VMC",
                  },
                })
              }
            >
              VMC REQUESTS HISTORY
            </Button>
          </div>
          <div className="text-end fs-18">
            <p className="mb-0 fw-semibold">
              {moment().format("MMMM DD, YYYY")}
            </p>
          </div>
        </div>
        <div className="add-scroll out-of-field-page">
          <div className="tc-content-wrapper h-100">
            <div className="tc-field-content h-100">
              <Row className="h-100">
                <Col md={4} className="h-100">
                  <div className="tc-table-wrapper h-100">
                    <Table responsive className="tc-table">
                      <thead>
                        <tr>
                          <th colSpan={3}>
                            <h5>
                              ALL DAY{" "}
                              <span
                                className="tc-cursor-pointer"
                                onClick={() => handleModalShow("ALL DAY")}
                              >
                                {addIcon}
                              </span>
                            </h5>
                          </th>
                        </tr>
                        <tr>
                          <th>DATE</th>
                          <th>ACTIVITY</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      {updatedList?.allDayArr?.length === 0 ? (
                        <tbody>
                          <tr>
                            <td colSpan={3} className="text-center">
                              No data found
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {updatedList?.allDayArr?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div>{defaultDateFormat(item?.date)}</div>
                                </td>
                                <td>
                                  <div>{item.reason}</div>
                                </td>
                                <td>
                                  {manageRequestStatus(item, "VMC", true)}
                                  <br />
                                  {!checkRequestIsEnable(item) &&
                                    getUpdatedDate(
                                      item?.created_at,
                                      item?.record_time
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Col>
                <Col md={4} className="h-100">
                  <div className="tc-table-wrapper h-100">
                    <Table
                      responsive
                      locale={{ emptyText: "No data" }}
                      className="tc-table"
                    >
                      <thead>
                        <tr>
                          <th colSpan={3}>
                            <h5>
                              MORNING{" "}
                              <span
                                className="tc-cursor-pointer"
                                onClick={() => handleModalShow("MORNING")}
                              >
                                {addIcon}
                              </span>
                            </h5>
                          </th>
                        </tr>

                        <tr>
                          <th>DATE</th>
                          <th>ACTIVITY</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      {updatedList?.morningArr?.length === 0 ? (
                        <tbody>
                          <tr>
                            <td colSpan={3} className="text-center">
                              No data found
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {updatedList?.morningArr?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div>{defaultDateFormat(item?.date)}</div>
                                </td>
                                <td>
                                  <div>{item.reason}</div>
                                </td>
                                <td>
                                  {manageRequestStatus(item, "VMC", true)}
                                  <br />
                                  {!checkRequestIsEnable(item) &&
                                    getUpdatedDate(
                                      item?.created_at,
                                      item?.record_time
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Col>

                <Col md={4} className="h-100">
                  <div className="tc-table-wrapper h-100">
                    <Table responsive className="tc-table">
                      <thead>
                        <tr>
                          <th colSpan={3}>
                            <h5>
                              AFTERNOON{" "}
                              <span
                                className="tc-cursor-pointer"
                                onClick={() => handleModalShow("AFTERNOON")}
                              >
                                {addIcon}
                              </span>
                            </h5>
                          </th>
                        </tr>

                        <tr>
                          <th>DATE</th>
                          <th>ACTIVITY</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      {updatedList?.afternoonArr?.length === 0 ? (
                        <tbody>
                          <tr>
                            <td colSpan={3} className="text-center">
                              No data found
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {updatedList?.afternoonArr?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div>{defaultDateFormat(item?.date)}</div>
                                </td>
                                <td>
                                  <div>{item.reason}</div>
                                </td>
                                <td>
                                  {manageRequestStatus(item, "VMC", true)}
                                  <br />
                                  {!checkRequestIsEnable(item) &&
                                    getUpdatedDate(
                                      item?.created_at,
                                      item?.record_time
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Col>
              </Row>
              {modalShow && (
                <RecordOutField
                  show={modalShow}
                  onHide={onCancelRecordHandler}
                  count={count}
                  errors={errors}
                  setErrors={setErrors}
                  values={values}
                  setValues={setValues}
                  typeList={typeList}
                  isLoading={isLoading}
                  dateCycle={dateCycle}
                  selectedMD={selectedMD}
                  setSelectedMD={setSelectedMD}
                  disableMds={disableMds}
                  mdListData={mdListData}
                  reasonsList={reasonsList}
                  selectedType={selectedType}
                  selectedReason={selectedReason}
                  handleInputChange={handleInputChange}
                  handleSubmitMissedCallData={handleSubmitMissedCallData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OutOfField;
