import axios from "axios";

export const authHeaders = () => {
  const authToken =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));

  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
};

export const authHeaders2 = () => {
  const authToken =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));

  return {
    Authorization: `Bearer ${authToken}`,
  };
};

export const refreshToken = async () => {
  const refreshToken =
    localStorage.getItem("refresh_token") &&
    JSON.parse(localStorage.getItem("refresh_token"));
  const reqObj = {
    type: "TM",
    refresh_token: refreshToken,
  };
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/refresh-token`,
    reqObj,
    authHeaders()
  );
};

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export default Axios;
