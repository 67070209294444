import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, authHeaders2 } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout, handleTokenRefresh } from "./userSlice";

export const reqToGetQuickSignCount = createAsyncThunk(
  apiendpoints.quickSignCount,
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignCount,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

export const reqToGetQuickSignlist = createAsyncThunk(
  apiendpoints.quickSignlist,
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignlist,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

export const reqToGetQuickSignMDList = createAsyncThunk(
  apiendpoints.quickSignmdList,
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignmdList,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToAddSignature = createAsyncThunk(
  apiendpoints.addSignature,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.addSignature,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response.data);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response.data);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToDeleteSignature = createAsyncThunk(
  apiendpoints.deleteSignature,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.deleteSignature,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response.data);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response.data);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToAddMD = createAsyncThunk(
  apiendpoints.addMD,
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addMD,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToUpdateQuickSign = createAsyncThunk(
  apiendpoints.quickSignUpdate,
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.quickSignUpdate,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetIncidentalOptions = createAsyncThunk(
  apiendpoints.incidentalOptions,
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.incidentalOptions,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetAddressFromLatLong = createAsyncThunk(
  apiendpoints.getLocation,
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.getLocation,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        data.onSuccess(response);
      } else {
        notify("something went wrong!");
      }
      return response;
    } catch (error) {
      data.onFailure(error);
      return error;
    }
  }
);

export const reqToUpdateSignature = createAsyncThunk(
  apiendpoints.updateQuickSign,
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.updateQuickSign,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        data.onSuccess(response);
      } else {
        notify("something went wrong!");
      }
      return response;
    } catch (error) {
      data.onFailure(error);
      return error;
    }
  }
);

const initialState = {
  loader: false,
  error: "",
  quickSignCount: "",
  quickSignList: [],
  quickSignMDList: [],
  incidentalOptions: {},
};

// Create quickSign slice
const quickSignSlice = createSlice({
  name: "quickSignSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get quickSignCount data
    builder
      .addCase(reqToGetQuickSignCount.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToGetQuickSignCount.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
          state.quickSignCount = action?.payload?.data;
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          notify(action.payload.response.data, "error");
        } else {
          notify(action.payload.message, "error");
        }
      })
      .addCase(reqToGetQuickSignCount.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // Get quickSign list
    builder
      .addCase(reqToGetQuickSignlist.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToGetQuickSignlist.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
          let data = action?.payload?.data?.list || [];
          while (data?.length < 10) {
            data.push({});
          }
          state.quickSignList = {
            list: data,
            count: action?.payload?.data?.count,
          };
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          notify(action.payload.response.data, "error");
        } else {
          notify(action.payload.message, "error");
        }
      })
      .addCase(reqToGetQuickSignlist.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // Get quickSign MD list
    builder
      .addCase(reqToGetQuickSignMDList.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToGetQuickSignMDList.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
          state.quickSignMDList = action?.payload?.data;
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          notify(action.payload.response.data, "error");
        } else {
          notify(action.payload.message, "error");
        }
      })
      .addCase(reqToGetQuickSignMDList.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // Add Signature
    builder
      .addCase(reqToAddSignature.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToAddSignature.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          // notify(action.payload.response.data, "error");
        } else {
          // notify(action.payload.message, "error");
        }
      })
      .addCase(reqToAddSignature.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // Delete Signature
    builder
      .addCase(reqToDeleteSignature.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToDeleteSignature.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          // notify(action.payload.response.data, "error");
        } else {
          // notify(action.payload.message, "error");
        }
      })
      .addCase(reqToDeleteSignature.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // Add MD
    builder
      .addCase(reqToAddMD.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToAddMD.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          notify(action.payload.response.data, "error");
        } else {
          notify(action.payload.message, "error");
        }
      })
      .addCase(reqToAddMD.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // Get incidentalOptions data
    builder
      .addCase(reqToGetIncidentalOptions.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToGetIncidentalOptions.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
          state.incidentalOptions = action?.payload?.data;
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          notify(action.payload.response.data, "error");
        } else {
          notify(action.payload.message, "error");
        }
      })
      .addCase(reqToGetIncidentalOptions.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // get location
    builder
      .addCase(reqToGetAddressFromLatLong.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToGetAddressFromLatLong.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          // notify(action.payload.response.data, "error");
        } else {
          // notify(action.payload.message, "error");
        }
      })
      .addCase(reqToGetAddressFromLatLong.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // Update signature
    builder
      .addCase(reqToUpdateSignature.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToUpdateSignature.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          // notify(action.payload.response.data, "error");
        } else {
          // notify(action.payload.message, "error");
        }
      })
      .addCase(reqToUpdateSignature.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });
  },
});

export default quickSignSlice.reducer;
