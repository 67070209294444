import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DateUtility, nameFormatting } from "../../helpers/commonFunction";

const MonthlyCallDates = (props) => {
  const {
    selectedMD,
    disableMDs,
    holidays,
    isDisabled,
    selectedMDCallDates,
    handleCallActivitiesOfMD,
    ...rest
  } = props;

  const [newMDCallDates, setNewMDCallDates] = useState([]);
  useEffect(() => {
    if (selectedMDCallDates && selectedMDCallDates?.length > 0) {
      const sortByDate = selectedMDCallDates.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      setNewMDCallDates(sortByDate);
    }
  }, [selectedMDCallDates]);

  const checkMDIsDisabled = (item) => {
    if (disableMDs && disableMDs?.length > 0) {
      let isFound = false;
      disableMDs &&
        disableMDs.forEach((disItem) => {
          if (
            (disItem.date === item.date && disItem.disable_all === 1) ||
            (disItem.date === item.date &&
              disItem.md_ids.includes(selectedMD.md_id))
          ) {
            isFound = true;
          }
        });
      return isFound;
    } else {
      return false;
    }
  };
  const checkIsHoliday = (item) => {
    if (holidays && holidays.length > 0) {
      let isFound = false;
      holidays &&
        holidays.forEach((holi) => {
          if (holi.date === item.date && holi.holiday_all === 1) {
            isFound = true;
          }
        });
      return isFound;
    } else {
      return false;
    }
  };
  return (
    <Modal {...rest} centered className="tc-main-modal tc-quicksign-modal">
      <Modal.Header closeButton>
        <Modal.Title className="">
          {selectedMD && nameFormatting(selectedMD, "LF")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-0 mb-3 pt-0">
        <span className="d-flex justify-content-center fw-medium">
          CALL PLAN
        </span>
        <div className="tc-monthly-md-btn-wrapper pt-2">
          {newMDCallDates && newMDCallDates?.length > 0 ? (
            newMDCallDates.map((item, i) => {
              return (
                <div key={i} className="tc-monthly-md-btn-container">
                  {item?.is_summary === 1 ||
                  item?.is_visited === 1 ||
                  item?.is_save === 1 ||
                  item?.is_consecutive === 1 ||
                  item?.is_week_call === 1 ||
                  checkMDIsDisabled(item) ||
                  checkIsHoliday(item) ||
                  isDisabled ? (
                    <button disabled className="tc-monthly-md-btn">
                      {item.date ? DateUtility.dashDate(item.date) : ""}
                    </button>
                  ) : (
                    <button
                      className="tc-monthly-md-btn"
                      onClick={() => handleCallActivitiesOfMD(item)}
                    >
                      {item.date ? DateUtility.dashDate(item.date) : ""}
                    </button>
                  )}
                </div>
              );
            })
          ) : (
            <span className="fw-semibold">No dates to display</span>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MonthlyCallDates;
