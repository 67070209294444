import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import {
  modifyText,
  mediaToBase64,
  nameFormatting,
  dateFormatting,
  defaultDateFormat,
  checkSystemWebcam,
} from "../../helpers/commonFunction";
import { fileUploadIcon } from "../../icon/icon";
import ImageCaptureModal from "./ImageCaptureModal";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { isDesktop } from "react-device-detect";
import CustomImageCapture from "../CustomImageCapture";

const RecordOutField = (props) => {
  const {
    to,
    count,
    values,
    setValues,
    errors,
    setErrors,
    disableMds,
    selectedMD,
    setSelectedMD,
    dateCycle,
    typeList,
    isLoading,
    reasonsList,
    mdListData,
    selectedType,
    selectedReason,
    handleInputChange,
    handleSubmitMissedCallData,
    ...rest
  } = props;

  const inputRef = useRef();
  const [showWebcam, setShowWebcam] = useState(false);
  const handleShowWebcam = () => {
    setShowWebcam(!showWebcam);
  };

  const handleSystemWebcam = () => {
    checkSystemWebcam()
      .then(() => {
        if (isDesktop) {
          handleShowWebcam();
        } else {
          inputRef.current.click();
        }
      })
      .catch(() => {});
  };

  const handleCaptureImage = async (event) => {
    if (isDesktop) {
      const blobData = dataURLtoBlob(event);
      setValues({
        ...values,
        image: blobData,
      });
      handleShowWebcam();
    } else {
      const file =
        event?.target?.files &&
        event?.target?.files?.length > 0 &&
        event?.target?.files[0];
      if (file) {
        const base64 = await mediaToBase64(file);
        const blobData = dataURLtoBlob(base64);
        setValues({
          ...values,
          image: blobData,
        });
        setShowWebcam(false);
      }
    }
  };

  const handleSelectMD = (data) => {
    let mdList = [...selectedMD];
    let filterData = mdList && mdList.find((item) => item === data?.md_id);
    if (filterData) {
      const index = mdList && mdList.indexOf(data?.md_id);
      mdList.splice(index, 1);
      setSelectedMD(mdList);
    } else {
      mdList.push(data.md_id);
      setSelectedMD(mdList);
    }
  };

  const handleChangeValues = async (event, type, name) => {
    if (type === "file") {
      if (event?.target) {
        const file = event?.target?.files[0];
        if (file) {
          const base64 = await mediaToBase64(file);
          const blobData = dataURLtoBlob(base64);
          setValues({
            ...values,
            image: blobData,
          });
          setShowWebcam(false);
        }
      } else {
        setValues({
          ...values,
          image: event,
        });
      }
    }
  };

  return (
    <>
      <Modal
        className="tc-main-modal tc-modal-md"
        {...rest}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="pb-0">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-black"
          >
            {selectedType && selectedType?.name?.toUpperCase()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="popup-scroll">
          <div>
            <strong className="fw-semibold d-inline-block pe-2 mb-1 text-uppercase">
              Date
            </strong>
            <div>
              <ReactDatePicker
                onChange={(e) => {
                  setValues({ ...values, date: dateFormatting(e, "date") });
                }}
                id="date"
                name="date"
                dateFormat="yyyy-MM-dd"
                value={defaultDateFormat(values.date)}
                selected={new Date(values.date)}
                minDate={moment(dateCycle?.start)._d}
                maxDate={moment(dateCycle?.end)._d}
                onKeyDown={(e) => e.preventDefault()}
                className="form-control"
              />
            </div>
          </div>
          <div className="mt-3">
            <strong className="fw-semibold d-inline-block pe-2 mb-1">
              REASON
            </strong>
            <div>
              <Form.Select
                name="notes_id"
                id="notes_id"
                value={values.notes_id || "Select"}
                onChange={(e) => {
                  handleInputChange(e, "notes_id");
                  setErrors({ ...errors, notes_id: "" });
                }}
                className="form-select-input"
              >
                <option disabled>Select</option>
                {reasonsList?.map((item, i) => {
                  return (
                    <option key={i} value={item.notes_id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors && errors.notes_id && (
                <span className="tc-error">{errors?.notes_id}</span>
              )}
            </div>
          </div>
          <div className="tc-edit-itinerary-attachment mt-3">
            <span className="fw-semibold d-flex justify-content-between align-content-center">
              ATTACHMENT
            </span>
            <div className="w-100 tc-file-upload-input">
              <input
                type="file"
                name="image"
                id="file"
                accept="image/jpeg, image/png, image/webp, image/bmp, image/ico, image/gif"
                onChange={(e) => handleChangeValues(e, "file", "attachment")}
              />
              <label htmlFor="file" className="tc-file-upload-label">
                {values.image ? (
                  <div
                    className={`tc-selected-file tc-choose-content ${
                      values.image?.type?.includes("image") && "border-0"
                    }`}
                  >
                    {values.image?.type?.includes("image") ? (
                      <img
                        src={URL.createObjectURL(values.image)}
                        height={220}
                        alt="image"
                      />
                    ) : (
                      <span>{values.image}</span>
                    )}
                  </div>
                ) : (
                  <div className="tc-choose-content border-solid">
                    {fileUploadIcon}
                    <span className="tc-choose-text">
                      Choose File to upload
                    </span>
                  </div>
                )}
              </label>
            </div>
            {errors && errors?.image && (
              <span className="tc-error">{errors?.image}</span>
            )}
          </div>
          <div className="mt-3">
            <strong className="fw-semibold d-inline-block pe-2 mb-1">
              REMARKS
            </strong>
            <div>
              <Form.Control
                name="remark"
                id="remark"
                value={values.remark}
                placeholder="Enter remark"
                as="textarea"
                onChange={(e) => {
                  handleInputChange(e);
                  setErrors({ ...errors, remark: "" });
                }}
                className="form-select-input"
              />
              {errors && errors.remark && (
                <span className="tc-error">{errors?.remark}</span>
              )}
            </div>
          </div>
          {mdListData &&
            mdListData?.length > 0 &&
            (selectedReason?.name?.charAt(0) === "*" ||
              modifyText(selectedReason?.name) === "deliver food") && (
              <div className="tc-out-of-field-md-modal-checkbox-list-box mt-3">
                <div className="d-flex align-items-center mb-2">
                  <h6 className="mb-0">MDs </h6>
                  <p className="mb-0 ms-auto fw-normal">
                    {selectedMD && selectedMD?.length}/{count}
                  </p>
                </div>
                <div className="tc-out-of-field-md-modal-checkbox-list modal-checkbox-list">
                  {mdListData &&
                    mdListData?.map((item, index) => {
                      if (item?.is_summary === 0) {
                        return (
                          <Form.Check
                            key={index}
                            name="md_list"
                            type="checkbox"
                            className="py-2"
                            id={index + 1}
                            label={item && nameFormatting(item)}
                            onChange={(e) => handleSelectMD(item)}
                            checked={selectedMD.includes(item.md_id)}
                            disabled={
                              selectedType?.name === "All Day"
                                ? true
                                : selectedMD?.length === count &&
                                  !selectedMD.includes(item.md_id)
                                ? true
                                : disableMds.includes(item.md_id)
                                ? true
                                : false
                            }
                          />
                        );
                      }
                    })}
                </div>
              </div>
            )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="text-center">
            <div className="btn-row-container">
              <Button
                variant="danger"
                type="button"
                onClick={() => props.onHide()}
                disabled={isLoading}
              >
                CANCEL
              </Button>
              <Button
                variant="primary"
                type="button"
                onClick={() => handleSubmitMissedCallData()}
                disabled={isLoading}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {isDesktop ? (
        <>
          {showWebcam && (
            <ImageCaptureModal
              show={showWebcam}
              onHide={handleShowWebcam}
              handleCaptureImage={handleCaptureImage}
            />
          )}
        </>
      ) : (
        <CustomImageCapture
          inputRef={inputRef}
          handleFileChange={handleCaptureImage}
        />
      )}
    </>
  );
};

export default RecordOutField;
