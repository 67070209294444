import React from "react";
import { AnnouncementsIcon, cakeIcon2, userIcon } from "../icon/icon";
import {
  defaultDateFormat,
  getMonthAndDate,
  manageStatus,
  nameFormatting,
  timeWithHoursFormat,
} from "../helpers/commonFunction";
import { DataNotFound } from "../components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tab, Table, Tabs } from "react-bootstrap";

const NotificationDetails = (props) => {
  const { user, notifications, selectedNotifiType } = props;

  return (
    <div className="h-100">
      <div className="tc-content-wrapper pt-2 h-100">
        <div className="tc-view-notification-type">
          <span>
            {selectedNotifiType
              ? selectedNotifiType === "BIRTHDAY"
                ? cakeIcon2
                : selectedNotifiType === "ANNOUNCEMENTS"
                ? AnnouncementsIcon
                : userIcon
              : userIcon}
          </span>
          <h4 className="p-0 m-0">
            {selectedNotifiType
              ? selectedNotifiType === "BIRTHDAY"
                ? "MD Birthdays"
                : selectedNotifiType === "DM"
                ? "District Manager"
                : selectedNotifiType
              : ""}
          </h4>
        </div>
        {selectedNotifiType !== "BIRTHDAY" &&
          selectedNotifiType !== "ANNOUNCEMENTS" && (
            <>
              {notifications && notifications?.length === 0 ? (
                <span className="m-auto mt-5">
                  <DataNotFound name={"No Notification yet"} />
                </span>
              ) : (
                <InfiniteScroll
                  dataLength={notifications?.length}
                  hasMore={true}
                  scrollableTarget="scrollableDiv"
                  className="tc-notification-container"
                >
                  {notifications &&
                    notifications.map((item, i) => (
                      <div key={i} className="tc-notification-outer-content">
                        <div className="tc-notification-content">
                          <span className="tc-notification-date">
                            {defaultDateFormat(item.created_at) +
                              " - " +
                              timeWithHoursFormat(item.created_at)}
                          </span>
                          <div>
                            {item?.notification_type === "OOF" ? (
                              <>
                                {`Hello `}
                                <span className="tc_notification_desc">
                                  {nameFormatting(user, "FLC")},
                                </span>
                                {` Your Request for `}
                                <span className="tc_notification_desc">
                                  Valid Missed Calls
                                </span>
                                {` has been `}
                                <span className="tc_notification_desc">
                                  {manageStatus(item?.approve_request)}.
                                </span>
                                {selectedNotifiType === "DM" && (
                                  <>
                                    {` and forwarded to Admin for `}
                                    <span className="tc_notification_desc">
                                      Final approval.
                                    </span>
                                  </>
                                )}
                              </>
                            ) : item?.notification_type === "ITINERARY" ? (
                              <>
                                {`Hello `}
                                <span className="tc_notification_desc">
                                  {nameFormatting(user, "LF")},
                                </span>
                                {` Your request to `}
                                <span className="tc_notification_desc">
                                  {item?.type_request === "INSERT"
                                    ? "ADD "
                                    : item?.type_request === "DELETE"
                                    ? "REMOVE "
                                    : "EDIT "}
                                  Dr. {nameFormatting(item, "LF")}
                                </span>
                                {` to the `}
                                <span className="tc_notification_desc">
                                  NEXT MONTH ITINERARY
                                </span>
                                {` has been `}
                                <span className="tc_notification_desc">
                                  {manageStatus(item?.approve_request)}.
                                </span>
                                {` Kindly go to the `}
                                <span className="tc_notification_desc text-capitalize">
                                  Updates Channel
                                </span>
                                {` and tap the `}
                                <span className="tc_notification_desc text-capitalize">
                                  Itinerary Per Day button.
                                </span>
                                {` You can check it using the `}
                                <span className="tc_notification_desc text-capitalize">
                                  AZ icon
                                </span>
                                {` or the `}
                                <span className="tc_notification_desc text-capitalize">
                                  Itinerary Calendar.
                                </span>
                              </>
                            ) : (
                              <>
                                {`Hello `}
                                <span className="tc_notification_desc">
                                  {nameFormatting(user, "FLC")},
                                </span>
                                {` Your request to `}
                                <span className="tc_notification_desc">
                                  {`${
                                    item?.type_request === "INSERT"
                                      ? "ADD"
                                      : item?.type_request === "DELETE"
                                      ? "REMOVE"
                                      : "EDIT"
                                  } MD `}
                                </span>
                                {`has been `}
                                <span className="tc_notification_desc">
                                  {manageStatus(item?.approve_request)}
                                </span>
                                {selectedNotifiType === "DM" ? (
                                  <>
                                    {item?.type_request === "INSERT" ? (
                                      <>
                                        {`, and forwarded to Admin for `}
                                        <span className="tc_notification_desc">
                                          FINAL APPROVAL.
                                        </span>
                                      </>
                                    ) : (
                                      ". Please check."
                                    )}
                                  </>
                                ) : item?.type_request === "INSERT" &&
                                  item?.approve_request === "APPROVED" ? (
                                  <>
                                    {". Please check the "}
                                    <span className="tc_notification_desc text-capitalize">
                                      Updates Channel (Add to Itinerary)
                                    </span>
                                    {
                                      " and add this MD if you want it included in the next month’s itinerary."
                                    }
                                  </>
                                ) : (
                                  ". Please check."
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </InfiniteScroll>
              )}
            </>
          )}
        {selectedNotifiType === "BIRTHDAY" && (
          <Tabs
            defaultActiveKey="monthly"
            id="uncontrolled-tab-example"
            className="mt-3 mb-3 tabs_underline"
            fill
          >
            <Tab
              eventKey="monthly"
              title="Monthly Celebrants"
              className="dm-birthday-class"
            >
              <div className="tc-table-wrapper h-100 mb-0">
                <Table responsive className="tc-table">
                  <thead>
                    <tr>
                      <th>MD Name</th>
                      <th>Event</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  {notifications &&
                  notifications?.month_birthday?.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan={3} className="text-center">
                          No Birthday yet
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {notifications &&
                        notifications?.month_birthday?.length > 0 &&
                        notifications?.month_birthday.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="min-width-300 text-start">
                                {item ? nameFormatting(item, "LF") : "-"}
                              </td>
                              <td>
                                <div>Birthday</div>
                              </td>
                              <td>
                                {item.birthdate
                                  ? getMonthAndDate(item.birthdate)
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </div>
            </Tab>
            <Tab
              eventKey="weekly"
              title="Weekly Celebrants"
              className="dm-birthday-class"
            >
              <div className="tc-table-wrapper h-100 mb-0">
                <Table responsive className="tc-table">
                  <thead>
                    <tr>
                      <th>MD Name</th>
                      <th>Event</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  {notifications &&
                  notifications?.weekly_birthday?.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan={3} className="text-center">
                          No celebrants found
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {notifications &&
                        notifications?.weekly_birthday?.length > 0 &&
                        notifications?.weekly_birthday.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="min-width-300 text-start">
                                {item ? nameFormatting(item, "LF") : "-"}
                              </td>
                              <td>
                                <div>Birthday</div>
                              </td>
                              <td>
                                {item.birthdate
                                  ? getMonthAndDate(item.birthdate)
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </div>
            </Tab>
          </Tabs>
        )}
        {selectedNotifiType === "ANNOUNCEMENTS" && (
          <>
            {notifications && notifications?.length === 0 ? (
              <span className="m-auto mt-5">
                <DataNotFound name={"No Announcements yet"} />
              </span>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationDetails;
