import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import mdListSlice from "./slice/mdListSlice";
import dashboardSlice from "./slice/dashboardSlice";
import outOfFieldSlice from "./slice/outOfFieldSlice";
import promoMaterialSlice from "./slice/promoMaterialSlice";
import quickSignSlice from "./slice/quickSignSlice";
import callRecordingSlice from "./slice/callRecordingSlice";
import tacticalSlice from "./slice/tacticalSlice";
import timeInOutSlice from "./slice/timeInOutSlice";
import itinerarySlice from "./slice/itinerarySlice";
import offlineSlice from "./slice/offlineSlice";
import notificationSlice from "./slice/notificationSlice";

const store = configureStore({
  reducer: {
    userReducer: userSlice,
    mdListReducer: mdListSlice,
    dashboardReducer: dashboardSlice,
    callRecordingReducer: callRecordingSlice,
    outOfFieldReducer: outOfFieldSlice,
    promoMaterialReducer: promoMaterialSlice,
    quickSignReducer: quickSignSlice,
    tacticalReducer: tacticalSlice,
    timeInOutReducer: timeInOutSlice,
    itineraryReducer: itinerarySlice,
    offlineReducer: offlineSlice,
    notificationReducer: notificationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
