import React, { useEffect, useState } from "react";
import {
  callIcon,
  cakeIcon2,
  editIcon2,
  backBtnIcon,
  checkBorderIcon,
  aToZIcon,
} from "../../icon/icon";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { DataNotFound } from "../../components";
import { messages } from "../../helpers/messages";
import { routes } from "../../helpers/constants";
import moment from "moment";
import {
  handleVisits,
  nameFormatting,
  getMonthStartEndDate,
  getNewMDListByRecordDate,
} from "../../helpers/commonFunction";
import { useLiveQuery } from "dexie-react-hooks";
import { db, getAllEntriesFromDB } from "../../helpers/db";
import { UserDefaultImage } from "../../images";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { getMediaFileFromDB } from "../../helpers/offlineAPIs";

const EditCallMDList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userReducer = useSelector((state) => state.userReducer.user);

  const [selectedDate, setSelectedDate] = useState();
  const [dateOptions, setDateOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mdListData, setMdListData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [mdListFromDB, setMDListFromDB] = useState([]);
  const [disabledMDList, setDisabledMDList] = useState([]);
  const [holidayListFromDB, setHolidayListFromDB] = useState([]);
  const [disableMDs, setDisableMDs] = useState({
    md_ids: [],
    disable_all: 0,
  });
  const [holidays, setHolidays] = useState({
    date: "",
    holiday_all: 0,
  });

  useEffect(() => {
    if (mdListData && mdListData?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [mdListData]);

  useEffect(() => {
    const handleGetInitialData = async () => {
      const [mdList, holidays, disabled_mds] = await Promise.all([
        getAllEntriesFromDB("mdList"),
        getAllEntriesFromDB("holidays"),
        getAllEntriesFromDB("disabled_mds"),
      ]);
      setMDListFromDB(mdList);
      setHolidayListFromDB(holidays);
      setDisabledMDList(disabled_mds);
      const today = moment().format("YYYY-MM-DD");
      const month = getMonthStartEndDate();
      const monthSDate = moment(month.start).format("YYYY-MM-DD");
      const monthEDate = moment(month.end).format("YYYY-MM-DD");
      const label =
        moment(monthSDate).format("MMM DD") +
        " - " +
        moment(monthSDate).format("ddd");
      setSelectedDate({
        id: 1,
        date: monthSDate,
        label: `${label} (# MDs)`,
        value: monthSDate,
      });
      if (mdList && mdList?.length > 0) {
        const dateMDCount = {};
        const uniqueDates = new Set();
        mdList.filter((item) => {
          if (
            item.date >= monthSDate &&
            item.date <= monthEDate &&
            !item.is_summary &&
            item.is_save === 1
          ) {
            if (dateMDCount[item.date]) {
              dateMDCount[item.date]++;
            } else {
              dateMDCount[item.date] = 1;
              uniqueDates.add(item.date);
            }
          }
          return false;
        });
        const sortedDates = Array.from(uniqueDates).sort((a, b) => {
          return moment(a).isBefore(moment(b)) ? -1 : 1;
        });
        const dateOption =
          sortedDates && sortedDates?.length > 0
            ? sortedDates.map((item, i) => {
                const mdCount = dateMDCount[item];
                const label =
                  moment(item).format("MMM DD") +
                  " - " +
                  moment(item).format("ddd");
                return {
                  id: i + 1,
                  value: item,
                  label: `${label} (# MDs: ${mdCount})`,
                  date: item,
                };
              })
            : [];
        setDateOptions(dateOption);
        if (location.state?.selectedDate) {
          handleSearchMDList(
            "date",
            location.state.selectedDate,
            mdList,
            holidays,
            disabled_mds
          );
          setSelectedDate(location.state.selectedDate);
        } else {
          setSelectedDate(dateOption[0]);
          handleSearchMDList(
            "date",
            selectedDate ? selectedDate : dateOption[0],
            mdList,
            holidays,
            disabled_mds
          );
        }
      }
    };
    handleGetInitialData();
  }, []);

  const handleOnChange = (event, type) => {
    if (type === "option") {
      setSelectedDate(event);
      handleSearchMDList("date", event);
    } else if (event.target.value === "") {
      setSearchValue(event.target.value);
      handleSearchMDList("date", selectedDate);
    } else {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    }
  };

  const handleSearchMDList = async (
    searchBy,
    values,
    mdList,
    holidays,
    disabled_md
  ) => {
    try {
      const newMDList = mdList ? mdList : mdListFromDB;
      const newHolidayList = holidays ? holidays : holidayListFromDB;
      const newDisabledMDList = disabled_md ? disabled_md : disabledMDList;
      const updatedMDList = await getNewMDListByRecordDate(newMDList);
      if (searchBy === "date") {
        const newList = updatedMDList.filter(
          (item) =>
            item.date === values.date && !item.is_summary && item.is_save
        );
        setMdListData(newList);
        const getDisabledMds =
          newDisabledMDList &&
          newDisabledMDList?.length > 0 &&
          newDisabledMDList?.filter((item) => item.date === values.date);
        handleGetMDListByOof(getDisabledMds);
        const getHolidays =
          newHolidayList &&
          newHolidayList?.length > 0 &&
          newHolidayList?.filter((item) => item.date === values.date);
        setHolidays({
          ...getHolidays,
          date: getHolidays?.length > 0 ? getHolidays[0].date : "",
          holiday_all: getHolidays?.length > 0 ? getHolidays[0].holiday_all : 0,
        });
      } else {
        const previousNewList = updatedMDList.filter(
          (item) => item.date === selectedDate.value
        );
        const newList = previousNewList.filter(
          (item) =>
            `${item.lastname.trim()} ${item.firstname.trim()}`
              .toLowerCase()
              .includes(values.toLowerCase()) ||
            `${item.firstname.trim()} ${item.lastname.trim()}`
              .toLowerCase()
              .includes(values.toLowerCase())
        );
        const finalList =
          newList.length > 0 ? newList : !values ? previousNewList : [];
        setMdListData(finalList);
      }
    } catch (error) {
      console.error("Error searching MD list by date:", error);
    }
  };

  const handleGetMDListByOof = (data) => {
    if (data && data?.length > 0) {
      setDisableMDs({
        ...disableMDs,
        md_ids: data[0]?.md_ids,
        disable_all: data[0]?.disable_all,
      });
    } else {
      setDisableMDs({
        ...disableMDs,
        md_ids: [],
        disable_all: 0,
      });
    }
  };

  const handleCallActivitiesOfMD = async (item) => {
    if (item && item.md_id) {
      let saveData = "";
      let editableData = "";
      let quickSignObj = "";
      if (item?.is_save === 1) {
        saveData = item?.save_data ? JSON.parse(item?.save_data) : "";
        let quickSignSData = saveData ? saveData?.quickSignObj : "";
        if (quickSignSData?.signature_url) {
          delete quickSignSData.signPadData;
          const base64 = await getMediaFileFromDB(
            quickSignSData,
            "signature_url",
            "signPadData"
          );
          quickSignObj = { ...quickSignSData, signPadData: base64 };
        } else if (quickSignSData?.signPadData) {
          const blobData = dataURLtoBlob(quickSignSData?.signPadData);
          quickSignObj = { ...quickSignSData, signature_url: blobData };
        } else {
          quickSignObj = quickSignSData;
        }
        editableData = {
          data: saveData,
          save_id: item?.save_id,
          s_record_time: new Date(item?.save_record_date),
          s_target_date: item?.save_target_date,
          s_visited_date: item?.save_visited_date,
        };
      }
      navigate(routes.callActivities, {
        state: {
          selectedMD: item,
          reqObj: { md_id: item.md_id },
          prevPath: location.pathname,
          quickSignObj: quickSignObj,
          selectedDate: item.date,
          missedCallDate: selectedDate,
          start_time:
            saveData && saveData?.start_time
              ? new Date(saveData?.start_time)
              : new Date(),
          editableData: editableData ? editableData : "",
        },
      });
    }
  };

  const compareDates = (birthDate) => {
    const date1 = new Date(birthDate);
    const date2 = new Date(selectedDate?.value);

    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    const dayMonth1 = date1.getDate() + "-" + (date1.getMonth() + 1);
    const dayMonth2 = date2.getDate() + "-" + (date2.getMonth() + 1);

    const yearsDifference = Math.abs(year2 - year1);
    const sameDatMonth = dayMonth1 === dayMonth2;
    return {
      sameDatMonth,
      yearsDifference,
    };
  };

  const columns = [
    {
      name: "Class",
      sortable: true,
      selector: (row) => row?.name,
      cell: (row) => (
        <p
          className={`tc-data-table-class-text text-center w-100 ${
            row?.is_summary === 0 &&
            (disableMDs?.disable_all === 1 ||
              holidays?.holiday_all === 1 ||
              row?.is_consecutive === 1 ||
              disableMDs?.md_ids?.includes(row?.md_id) ||
              row?.is_week_call === 1)
              ? "tc-table-row-disabled"
              : ""
          }`}
        >
          {row?.name}
        </p>
      ),
    },
    {
      name: "Md Name",
      sortable: true,
      selector: (row) => row?.firstname.concat(" ", row?.lastname),
      cell: (row) => (
        <>
          {row?.is_summary === 0 ? (
            <p
              role="button"
              className={`tc-data-table-md-name-text text-start ${
                disableMDs?.disable_all === 1 ||
                holidays?.holiday_all === 1 ||
                row?.is_visited === 1 ||
                row?.is_consecutive === 1 ||
                disableMDs?.md_ids?.includes(row?.md_id) ||
                row?.is_week_call === 1
                  ? "tc-table-row-disabled"
                  : ""
              }`}
              onClick={() => handleCallActivitiesOfMD(row)}
            >
              {nameFormatting(row)}
            </p>
          ) : (
            <p
              role="button"
              className="tc-data-table-md-name-text text-start"
              onClick={() => {
                navigate(routes.viewCallSummary, {
                  state: { mdData: row, callDate: selectedDate?.value },
                });
              }}
            >
              {nameFormatting(row)}
            </p>
          )}
          {row?.birthdate && compareDates(row.birthdate).sameDatMonth ? (
            <span className="ms-2 tc-data-table-icon3" role="button">
              {cakeIcon2}
            </span>
          ) : (
            <span className="ms-2 tc-data-table-icon3-nodata"></span>
          )}
        </>
      ),
    },
    {
      name: "Visits",
      sortable: true,
      selector: (row) => handleVisits(row?.actual_visits, row?.target),
      cell: (row) => (
        <p
          className={`tc-data-table-visits-text tc-data-table-green-text ${
            row?.is_summary === 0 &&
            (disableMDs?.disable_all === 1 ||
              holidays?.holiday_all === 1 ||
              row?.is_consecutive === 1 ||
              disableMDs?.md_ids?.includes(row?.md_id) ||
              row?.is_week_call === 1)
              ? "tc-table-row-disabled"
              : ""
          }`}
        >
          {handleVisits(row?.actual_visits, row?.target)}
        </p>
      ),
    },
    {
      name: "Call Status",
      sortable: true,
      selector: (row) => row.call,
      cell: (row) =>
        row?.is_summary === 0 ? (
          <button
            className={`tc-data-table-button tc-data-table-icon ${
              row?.is_save === 1 && "tc-table-edit-icon"
            }`}
            onClick={() => handleCallActivitiesOfMD(row)}
            disabled={
              disableMDs?.disable_all === 1 ||
              holidays?.holiday_all === 1 ||
              row.is_visited === 1 ||
              row.is_consecutive === 1 ||
              disableMDs?.md_ids?.includes(row?.md_id) ||
              row?.is_week_call === 1
            }
          >
            {row?.is_save === 1 ? editIcon2 : callIcon}
          </button>
        ) : (
          <div
            className="tc-data-table-button-check-icon tc-data-table-icon"
            onClick={() => {
              navigate(routes.viewCallSummary, {
                state: { mdData: row, callDate: selectedDate?.value },
              });
            }}
          >
            {checkBorderIcon}
          </div>
        ),
    },
  ];

  return (
    <div className="tc-body">
      <div className="tc-navbar-back-text">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => navigate(routes.mdList)}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title text-uppercase">
          CALLS NOT YET SUBMITTED
        </p>
        <div className="tc-navbar-right-btn"></div>
      </div>
      <div className="tc-profile-wrapper">
        <img src={UserDefaultImage} alt="profile" />
        <span className="tc-profile-name">
          {userReducer && nameFormatting(userReducer, "LF")}
        </span>
      </div>
      <div className="add-scroll">
        <div className="tc-content-wrapper">
          <h2>LIST OF CALLS NOT YET SUBMITTED</h2>
          <div className="tc-calender-search-input-row justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Select
                id="clinic"
                name="clinic"
                options={dateOptions}
                value={selectedDate}
                onChange={(e) => handleOnChange(e, "option")}
                className="tc-custom-select-2 min-width-400"
                classNamePrefix="select"
                placeholder="Select date..."
              />
              {/* <label
                className="tc-atoz-icon"
                onClick={() =>
                  navigate(routes.aTozMDList, {
                    state: {
                      type: "EDITABLECALLS",
                      value: "Edit Calls",
                      lable: "Calls Not Yet Submitted",
                    },
                  })
                }
              >
                {aToZIcon}
              </label> */}
            </div>
            <div className="d-flex flex-column align-items-end">
              <h5 className="mt-3 mb-0">
                Total # of Calls Not Yet Submitted:{" "}
                {mdListData && mdListData?.length > 0 ? mdListData?.length : 0}
              </h5>
            </div>
          </div>
          {isLoading ? null : mdListData && mdListData?.length === 0 ? (
            <DataNotFound name={messages.toast.nodateData} />
          ) : (
            <DataTable
              columns={columns}
              data={mdListData && mdListData}
              className={`tc-data-table flex-grow-1 overflow-y-auto ${
                mdListData &&
                mdListData?.length === 0 &&
                "d-flex align-items-center"
              }`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCallMDList;
