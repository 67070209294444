import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, authHeaders2 } from "../../helpers/axios";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout, handleTokenRefresh } from "./userSlice";
import { messages } from "../../helpers/messages";

export const reqToGetActiveCycle = createAsyncThunk(
  apiendpoints.activeCycle,
  async ({ onSuccess, onFailure }) => {
    const request = {
      method: "GET",
      url: apiendpoints.activeCycle,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      console.log("error=====-----", error);
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          console.log("error=====", error);
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetMDList = createAsyncThunk(
  apiendpoints.mdList,
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.mdList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

export const reqToGetMDBySearch = createAsyncThunk(
  apiendpoints.searchMD,
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.searchMD,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetFrequencyList = createAsyncThunk(
  apiendpoints.getFrequency,
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.getFrequency,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetIncidentalMdList = createAsyncThunk(
  apiendpoints.incidentalMdList,
  async ({}) => {
    try {
      const response = await Axios.get(
        apiendpoints.incidentalMdList,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetMDListByMonth = createAsyncThunk(
  `${apiendpoints.mdList}/by/month`,
  async ({ data, onSuccess, onFailure, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.mdList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      onFailure(error);
      return error;
    }
  }
);

const initialState = {
  loader: false,
  error: "",
  apiMessage: "",
};

const mdListSlice = createSlice({
  name: "mdList",
  initialState,
  reducers: {
    reqToResetMDApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET CYCLE
    builder
      .addCase(reqToGetActiveCycle.pending, (state) => {
        state.apiMessage = messages.apiMessage.cycle;
      })
      .addCase(reqToGetActiveCycle.fulfilled, () => {})
      .addCase(reqToGetActiveCycle.rejected, () => {});

    // GET MDLIST
    builder
      .addCase(reqToGetMDList.pending, () => {})
      .addCase(reqToGetMDList.fulfilled, () => {})
      .addCase(reqToGetMDList.rejected, () => {});

    // GET MDLIST BY SEARCH
    builder
      .addCase(reqToGetMDBySearch.pending, () => {})
      .addCase(reqToGetMDBySearch.fulfilled, () => {})
      .addCase(reqToGetMDBySearch.rejected, () => {});

    // GET PATIENT DETAILS
    builder
      .addCase(reqToGetFrequencyList.pending, () => {})
      .addCase(reqToGetFrequencyList.fulfilled, () => {})
      .addCase(reqToGetFrequencyList.rejected, () => {});

    // GET INCIDENTAL MDLIST
    builder
      .addCase(reqToGetIncidentalMdList.pending, () => {})
      .addCase(reqToGetIncidentalMdList.fulfilled, () => {})
      .addCase(reqToGetIncidentalMdList.rejected, () => {});

    // GET MD LIST BY MONTH
    builder
      .addCase(reqToGetMDListByMonth.pending, () => {})
      .addCase(reqToGetMDListByMonth.fulfilled, () => {})
      .addCase(reqToGetMDListByMonth.rejected, () => {});
  },
});

export const { reqToResetMDApiMessage } = mdListSlice.actions;
export default mdListSlice.reducer;
