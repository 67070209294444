import { useState } from "react";
import { useDispatch } from "react-redux";
import { getTimeDifferenceInMin } from "../helpers/commonFunction";
import { handleOfflineDataSyncing } from "../helpers/offlineAPIs";
import {
  db,
  insertEntryInDB,
  getAllEntriesFromDB,
  reqToExportTableData,
  reqToImportTableData,
} from "../helpers/db";
import {
  reqToUserLogout,
  reqToUserLogoutInLocal,
} from "../store/slice/userSlice";

const useFetchAndSyncData = (sync = true) => {
  const dispatch = useDispatch();
  const [syncType, setSyncType] = useState("");
  const [syncMessage, setSyncMessage] = useState("");
  const [offlineModal, setOfflineModal] = useState(false);
  const handleOfflineModal = () => {
    setOfflineModal(!offlineModal);
  };
  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleConfirmModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const uploadAndFetchData = async (type, callBack) => {
    setSyncType(type);
    if (type === "export") {
      setSyncType("");
      const tableNames = [
        "addedCumulativeCalls",
        "callRecording",
        "addedQuickSign",
        "deletedQuickSign",
        "editableCalls",
        "oofMissedCalls",
        "addedTimeInOut",
        "addedMDBirthday",
        "addedItinerary",
        "addedMD",
        "userData",
      ];
      await reqToExportTableData(tableNames, dispatch);
    } else if (type === "import") {
      setSyncType("");
      await reqToImportTableData(callBack, dispatch);
    } else if (window.navigator.onLine) {
      setConfirmationModal(false);
      const [lastAPICallTime] = await Promise.all([
        getAllEntriesFromDB("lastAPICallTime"),
      ]);
      const syncAction = async (id, isNew = false) => {
        try {
          if (!isNew) {
            await db.lastAPICallTime.update(id, { call: 1, time: new Date() });
          }
          await handleOfflineDataSyncing(dispatch, sync, type ? type : "sync");
          await db.lastAPICallTime.update(id, { call: 0, time: new Date() });
          if (sync) {
            setSyncType("");
            setSyncMessage("");
            setConfirmationModal(false);
          }
        } catch (err) {
          await db.lastAPICallTime.update(id, { call: 0, time: new Date() });
          if (sync && err?.name !== "CanceledError") {
            setSyncMessage(err.message || "An error occurred during sync");
            setConfirmationModal(true);
          }
        }
      };

      if (
        type === "upload" ||
        type === "logout" ||
        type === "isNMonth" ||
        type === "finalUpload" ||
        type === "browserLogout"
      ) {
        try {
          await handleOfflineDataSyncing(dispatch, sync, type ? type : "sync");
          setSyncType("");
          setSyncMessage("");
          setConfirmationModal(false);
          if (type === "upload") {
            return Promise.resolve(true);
          } else if (type === "isNMonth" || type === "finalUpload") {
            callBack && (await callBack());
          } else if (type === "logout" || type === "browserLogout") {
            callBack && (await callBack());
            handleUserLogout();
          }
        } catch (err) {
          if (err?.name !== "CanceledError") {
            setSyncMessage(err.message || "An error occurred during sync");
            setConfirmationModal(true);
          }
        }
      } else if (lastAPICallTime && lastAPICallTime.length > 0) {
        const lastAPICall = lastAPICallTime[0];
        if (type === "sync") {
          syncAction(lastAPICall.id);
        } else if (lastAPICall && !lastAPICall.call) {
          syncAction(lastAPICall.id);
        } else {
          if (sync) return;
          const { minutes } = getTimeDifferenceInMin(
            new Date(),
            lastAPICall.time
          );
          if (minutes >= 2) {
            syncAction(lastAPICall.id);
          }
        }
      } else {
        const insertID = await insertEntryInDB("lastAPICallTime", {
          call: 1,
          time: new Date(),
        });
        syncAction(insertID, true);
      }
    } else if (type === "logout") {
      setSyncType("");
      dispatch(reqToUserLogoutInLocal());
    } else {
      setOfflineModal(true);
    }
  };

  const handleUserLogout = () => {
    dispatch(
      reqToUserLogout({
        data: "",
        onSuccess: () => {},
      })
    );
  };

  return {
    syncType,
    setSyncType,
    syncMessage,
    setSyncMessage,
    offlineModal,
    setOfflineModal,
    confirmationModal,
    setConfirmationModal,
    handleOfflineModal,
    handleConfirmModal,
    uploadAndFetchData,
  };
};

export default useFetchAndSyncData;
