import React from "react";
import { Outlet } from "react-router";
import { useMediaQuery } from "react-responsive";
import { Header, Footer } from "./";
import { RotatePhone } from "../components";

const MainLayout = () => {
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  return (
    <main className="tc-main-section">
      <RotatePhone show={!isLandscape} />
      <div className="tc-container">
        <Header />
        <div
          className={`tc-body-wrapper ${!window.navigator.onLine && "pt-0"}`}
        >
          <span
            className="d-flex justify-content-end fw-bold px-1"
            style={window.navigator.onLine ? { color: "" } : { color: "red" }}
          >
            {window.navigator.onLine ? "" : "You are offline!"}
          </span>
          <Outlet />
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default MainLayout;
