import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, authHeaders2 } from "../../helpers/axios";
import { apiendpoints } from "../../helpers/constants";
import { messages } from "../../helpers/messages";
import { handleTokenRefresh } from "./userSlice";

export const reqToGetMdItineraryData = createAsyncThunk(
  apiendpoints.getMdItineraryData,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.getMdItineraryData,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetCumulativeCalls = createAsyncThunk(
  apiendpoints.getCumulativeCalls,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.getCumulativeCalls,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToSubmitCallRecordingData = createAsyncThunk(
  apiendpoints.submitCallRecording,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.submitCallRecording,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response.data);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response.data);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToSubmitCumulativeCalls = createAsyncThunk(
  apiendpoints.submitCumulativeCall,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.submitCumulativeCall,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response.data);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response.data);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToSubmitEditableCalls = createAsyncThunk(
  apiendpoints.submitEditCall,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.submitEditCall,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response.data);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response.data);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetOffLineReportsData = createAsyncThunk(
  apiendpoints.offlineReports,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.offlineReports,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetOffLineQuickSignData = createAsyncThunk(
  apiendpoints.offLineQuickSignData,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.offLineQuickSignData,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToChangeMDBirthday = createAsyncThunk(
  apiendpoints.changeMDBirthday,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.changeMDBirthday,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetMDMasterList = createAsyncThunk(
  apiendpoints.getMDMasterList,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.getMDMasterList,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToAddMDReliever = createAsyncThunk(
  apiendpoints.addMDReliever,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.addMDReliever,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response.data);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response.data);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetHolidaysAndFreq = createAsyncThunk(
  apiendpoints.holidaysFrequency,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.holidaysFrequency,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetNextMonthItinerary = createAsyncThunk(
  apiendpoints.getItinerary,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.getItinerary,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToUpdateItinerary = createAsyncThunk(
  apiendpoints.addItinerary,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.addItinerary,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetMDInfoDetails = createAsyncThunk(
  apiendpoints.getMDInfoDetails,
  async ({ onSuccess, onFailure }) => {
    const request = {
      method: "GET",
      url: apiendpoints.getMDInfoDetails,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetMDRequest = createAsyncThunk(
  apiendpoints.getMDRequest,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.getMDRequest,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToAddMDRequest = createAsyncThunk(
  apiendpoints.addMDRequest,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.addMDRequest,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  apiMessage: "",
  commonMessage: "",
  syncLoader: false,
  fetchLoader: false,
  logoutLoader: false,
  activities: [],
  incidentalCalls: [],
  incidentalCallsChart: [],
};

// Create offline slice
const offlineSlice = createSlice({
  name: "offline",
  initialState,
  reducers: {
    reqToResetApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
    reqToUpdateApiMessage: (state, action) => {
      state.commonMessage = action.payload;
    },
    reqToFetchDataProcess: (state, action) => {
      state.fetchLoader = action.payload;
    },
    reqToStartSyncProcess: (state, action) => {
      state.syncLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET MDLIST
    builder
      .addCase(reqToGetMdItineraryData.pending, (state) => {
        state.apiMessage = messages.apiMessage.itinerary;
      })
      .addCase(reqToGetMdItineraryData.fulfilled, () => {})
      .addCase(reqToGetMdItineraryData.rejected, () => {});

    // GET CUMULATIVE CALLS
    builder
      .addCase(reqToGetCumulativeCalls.pending, (state) => {
        state.apiMessage = messages.apiMessage.cumulativeReport;
      })
      .addCase(reqToGetCumulativeCalls.fulfilled, () => {})
      .addCase(reqToGetCumulativeCalls.rejected, () => {});

    // GET REPORTS
    builder
      .addCase(reqToGetOffLineReportsData.pending, (state) => {
        state.apiMessage = messages.apiMessage.reports;
      })
      .addCase(reqToGetOffLineReportsData.fulfilled, () => {})
      .addCase(reqToGetOffLineReportsData.rejected, () => {});

    // ADD CALLS
    builder
      .addCase(reqToSubmitCallRecordingData.pending, () => {})
      .addCase(reqToSubmitCallRecordingData.fulfilled, () => {})
      .addCase(reqToSubmitCallRecordingData.rejected, () => {});

    // ADD CUMULATIVE CALLS
    builder
      .addCase(reqToSubmitCumulativeCalls.pending, () => {})
      .addCase(reqToSubmitCumulativeCalls.fulfilled, () => {})
      .addCase(reqToSubmitCumulativeCalls.rejected, () => {});

    // ADD EDIT CALLS
    builder
      .addCase(reqToSubmitEditableCalls.pending, () => {})
      .addCase(reqToSubmitEditableCalls.fulfilled, () => {})
      .addCase(reqToSubmitEditableCalls.rejected, () => {});

    // GET QUICK SIGN
    builder
      .addCase(reqToGetOffLineQuickSignData.pending, (state) => {
        state.apiMessage = messages.apiMessage.quickSign;
      })
      .addCase(reqToGetOffLineQuickSignData.fulfilled, () => {})
      .addCase(reqToGetOffLineQuickSignData.rejected, () => {});

    // UPDATE MD BIRTHDAY
    builder
      .addCase(reqToChangeMDBirthday.pending, () => {})
      .addCase(reqToChangeMDBirthday.fulfilled, () => {})
      .addCase(reqToChangeMDBirthday.rejected, () => {});

    // GET MASTER MDLIST
    builder
      .addCase(reqToGetMDMasterList.pending, (state) => {
        state.apiMessage = messages.apiMessage.masterMDList;
      })
      .addCase(reqToGetMDMasterList.fulfilled, () => {})
      .addCase(reqToGetMDMasterList.rejected, () => {});

    // ADD RELIEVER MD
    builder
      .addCase(reqToAddMDReliever.pending, () => {})
      .addCase(reqToAddMDReliever.fulfilled, () => {})
      .addCase(reqToAddMDReliever.rejected, () => {});

    // GET HOLIDAYS AND FREQUENCY
    builder
      .addCase(reqToGetHolidaysAndFreq.pending, () => {})
      .addCase(reqToGetHolidaysAndFreq.fulfilled, () => {})
      .addCase(reqToGetHolidaysAndFreq.rejected, () => {});

    // GET NEXT MONTH ITINERARY
    builder
      .addCase(reqToGetNextMonthItinerary.pending, (state) => {
        state.apiMessage = messages.apiMessage.nextMItinerary;
      })
      .addCase(reqToGetNextMonthItinerary.fulfilled, () => {})
      .addCase(reqToGetNextMonthItinerary.rejected, () => {});

    // UPDATE ITINERARY
    builder
      .addCase(reqToUpdateItinerary.pending, () => {})
      .addCase(reqToUpdateItinerary.fulfilled, () => {})
      .addCase(reqToUpdateItinerary.rejected, () => {});

    // GET MDINFO
    builder
      .addCase(reqToGetMDInfoDetails.pending, (state) => {
        state.apiMessage = messages.apiMessage.mdInfoDetails;
      })
      .addCase(reqToGetMDInfoDetails.fulfilled, () => {})
      .addCase(reqToGetMDInfoDetails.rejected, () => {});

    // GET MD REQUEST
    builder
      .addCase(reqToGetMDRequest.pending, (state) => {
        state.apiMessage = messages.apiMessage.mdInfoRequest;
      })
      .addCase(reqToGetMDRequest.fulfilled, () => {})
      .addCase(reqToGetMDRequest.rejected, () => {});

    // ADD MD REQUEST
    builder
      .addCase(reqToAddMDRequest.pending, () => {})
      .addCase(reqToAddMDRequest.fulfilled, () => {})
      .addCase(reqToAddMDRequest.rejected, () => {});
  },
});

export const {
  reqToResetApiMessage,
  reqToUpdateApiMessage,
  reqToFetchDataProcess,
  reqToStartSyncProcess,
} = offlineSlice.actions;
export default offlineSlice.reducer;
