import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { getAllEntriesFromDB } from "../../helpers/db";
import { useLocation, useNavigate } from "react-router-dom";
import { DataNotFound, ImageCard } from "../../components";
import { routes } from "../../helpers/constants";
import {
  nameFormatting,
  dateFormatting,
  manageRequestStatus,
  checkRequestIsEnable,
} from "../../helpers/commonFunction";
import { backBtnIcon, ExcelIcon } from "../../icon/icon";
import { getMediaFileFromDB } from "../../helpers/offlineAPIs";

const RequestHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedDate, setSelectedDate] = useState("");
  const [requestHistoryList, setRequestHistoryList] = useState([]);
  const [requestHFilterList, setRequestHFilterList] = useState([]);

  useEffect(() => {
    const handleGetInitialData = async () => {
      const [mdList, oofListData] = await Promise.all([
        getAllEntriesFromDB("mdList"),
        getAllEntriesFromDB("oofListData"),
      ]);
      let newRequestList = [];
      for (let i = 0; i < oofListData.length; i++) {
        const request = oofListData[i];
        if (request?.md_list?.length > 0) {
          const data = divideArr(request, mdList);
          newRequestList.push(...data);
        } else {
          newRequestList.push(request);
        }
      }
      setRequestHistoryList(newRequestList);
      onDateChangehandler("", newRequestList);
    };
    if (!location?.state?.type) {
      navigate(routes.dashboard);
    } else {
      handleGetInitialData();
    }
  }, []);

  const divideArr = (dataList, mdList) => {
    let newDataList = [];
    for (let i = 0; i < dataList?.md_list?.length; i++) {
      const mds = dataList?.md_list[i];
      const findMD = mdList.find((item) => item?.md_id === mds);
      if (findMD) {
        newDataList.push({
          ...dataList,
          md_list: [mds],
          md_name: [findMD],
        });
      }
    }
    return newDataList;
  };

  const onDateChangehandler = async (date, requestList) => {
    const selectedD = dateFormatting(date ? date : new Date());
    const newRequest = requestList ? requestList : requestHistoryList;
    const result = newRequest.sort(
      (a, b) =>
        new Date(b?.created_at || b?.record_time) -
        new Date(a?.created_at || a?.record_time)
    );
    const newData = await Promise.all(
      result.map(async (item) => {
        if (!item?.base64?.includes("base64")) {
          const base64 = await getMediaFileFromDB(item, "image", "base64");
          return {
            ...item,
            base64: base64,
          };
        } else {
          return item;
        }
      })
    );
    setSelectedDate(selectedD);
    setRequestHFilterList(newData);
  };

  const getRequestListCount = (list) => {
    const notiCount =
      list && list?.length > 0 ? list.length?.toString().padStart(2, "0") : 0;
    return notiCount;
  };

  const getUpdatedDate = (createdAt, updatedAt) => {
    if (updatedAt) {
      return (
        <span>
          {moment(updatedAt).format("MM/DD/YY")}
          <br />
          {moment(updatedAt).format("h:mm:ss A")}
        </span>
      );
    } else {
      return (
        <span>
          {moment(createdAt).format("MM/DD/YY")}
          <br />
          {moment(createdAt).format("h:mm:ss A")}
        </span>
      );
    }
  };

  const renderImageList = (request) => {
    return (
      <>
        {request?.image ? (
          (request?.image?.size && request?.image?.type?.includes("xls")) ||
          (!request?.image?.size && request?.image?.includes("xls")) ||
          request?.image?.name?.includes("xls") ? (
            <span>{ExcelIcon}</span>
          ) : (
            <ImageCard
              src={
                request?.image?.size
                  ? URL.createObjectURL(request?.image)
                  : request?.base64?.includes("base64")
                  ? request?.base64
                  : process.env.REACT_APP_IMAGE_URL + request?.image
              }
              alt="Attachment"
              imagePreview={true}
            />
          )
        ) : (
          "-"
        )}
      </>
    );
  };

  return (
    <div className="tc-body">
      <div className="tc-navbar-back-text">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => navigate(-1)}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title">
          {location?.state?.type} REQUESTS HISTORY
        </p>
        <div className="tc-navbar-right-btn"></div>
      </div>

      <div className="tc-md-request-history">
        <div className="tc-report-header d-flex justify-content-between align-items-center custom-datepicker-box mb-3">
          <div className="tc-report-header-inner-box "></div>
          <div className="tc-requests-content">
            <h5 className="text-end my-2">{`Total Number of Request: ${getRequestListCount(
              requestHFilterList
            )}`}</h5>
          </div>
        </div>
        <div className="tc-table-wrapper tc-white-table-wrapper">
          <div className="table-responsive">
            <Table className="tc-table">
              <thead>
                <tr>
                  <th className="text-center">MD CODE</th>
                  <th className="text-center">MD NAME</th>
                  <th className="text-center">MONTH</th>
                  <th className="text-center">DAY</th>
                  <th className="text-center">DATES</th>
                  <th className="text-center">REASON</th>
                  <th className="text-center">REMARK</th>
                  <th className="text-center">ATTACHMENT</th>
                  <th className="text-center">STATUS</th>
                  <th className="text-center">DATE SUBMITTED</th>
                </tr>
              </thead>
              <tbody>
                {requestHFilterList && requestHFilterList?.length > 0 ? (
                  requestHFilterList.map((request, index) => (
                    <tr
                      key={index}
                      className={` ${
                        !checkRequestIsEnable(request) && "bg-color-gray"
                      }`}
                    >
                      <td className="text-center min-width-200">
                        {Array.isArray(request?.md_list) &&
                        request?.md_list?.length > 0 ? (
                          <>
                            {request.md_list.map((md, i) => {
                              return (
                                <div key={i}>
                                  {md}
                                  {request.md_list.length - 1 === i ? "" : ","}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div>-</div>
                        )}
                      </td>
                      <td className="min-width-300">
                        {Array.isArray(request?.md_name) &&
                        request?.md_name?.length > 0 ? (
                          <>
                            {request?.md_name.map((md, i) => {
                              return (
                                <div key={i}>
                                  {nameFormatting(md)}
                                  {request.md_name.length - 1 === i ? "" : ","}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div>-</div>
                        )}
                      </td>
                      <td className="text-center">
                        {moment(request.date).format("MMM").toUpperCase()}
                      </td>
                      <td className="text-center">
                        {moment(request.date).format("ddd").toUpperCase()}
                      </td>
                      <td className="text-center">
                        {moment(request.date).format("MM/DD/YY")}
                      </td>
                      <td className="text-center min-width-250">
                        {request.reason ? request.reason : "-"}
                      </td>
                      <td className="text-center min-width-250">
                        {request.remark ? request.remark : "-"}
                      </td>
                      <td className="text-center min-width-250">
                        {renderImageList(request)}
                      </td>
                      <td className="text-center min-width-250">
                        {manageRequestStatus(request, "VMC")}
                        {!checkRequestIsEnable(request) && (
                          <div>
                            {moment(request.updated_at).format("MM/DD/YY")}
                          </div>
                        )}
                      </td>
                      <td className="text-center min-width-200">
                        {getUpdatedDate(request.created_at, request.updated_at)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      <DataNotFound message={"No data found!"} />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestHistory;
