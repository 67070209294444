import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CopyTextIcon } from "../../icon/icon";
import { useLiveQuery } from "dexie-react-hooks";
import { db, getAllEntriesFromDB } from "../../helpers/db";
import { dashDateFormat, notify } from "../../helpers/commonFunction";

const Confirmation = (props) => {
  const {
    size,
    title,
    type,
    subTitle,
    description,
    save,
    cancel,
    radius,
    onSubmit,
    errorMessage,
    ...rest
  } = props;

  const getErrorLogsFromDB = useLiveQuery(() => db.errorLogs.toArray(), []);

  const downloadLogFile = async () => {
    try {
      const [userData] = await Promise.all([getAllEntriesFromDB("userData")]);
      const newUser = userData && userData?.length > 0 && userData[0];
      const logContent = getErrorLogsFromDB
        .map((error) => {
          let logEntry = "";
          Object.keys(error).forEach((key) => {
            logEntry += `${key}: ${
              typeof error[key] === "object"
                ? JSON.stringify(error[key], null, 2)
                : error[key]
            }\n`;
          });
          return logEntry + "\n";
        })
        .join("\n");
      const blob = new Blob([logContent], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${newUser?.lastname}_${
        newUser?.firstname
      }_${dashDateFormat(new Date())}_errors.log`;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      notify("Please try again!");
    }
  };

  return (
    <Modal
      className={`tc-main-modal tc-modal-white ${radius && "tc-modal-radius"}`}
      {...rest}
      size={size ? size : "lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type === "location" ? "Location Permission" : title}
          {getErrorLogsFromDB &&
            getErrorLogsFromDB?.length > 0 &&
            (type === "location" || title?.toLowerCase()?.includes("sync")) && (
              <span
                className="ms-1"
                role="button"
                onClick={() => downloadLogFile()}
              >
                {CopyTextIcon}
              </span>
            )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-detail-content text-center">
          {subTitle && <div className="modal-detail-main-text">{subTitle}</div>}
          {!errorMessage?.message && description && (
            <div className="modal-detail-sub-text">{description}</div>
          )}
          {errorMessage && (
            <div className="modal-detail-sub-text fw-bold text-danger">
              {errorMessage?.message ? errorMessage?.message : errorMessage}
            </div>
          )}
        </div>
        <div className="modal-btn-row">
          {cancel && (
            <Button
              variant="danger"
              type="button"
              className="min-width-150"
              onClick={() => props.onHide()}
            >
              {cancel}
            </Button>
          )}
          {save && (
            <Button
              variant="primary"
              type="button"
              className="ms-2 min-width-150"
              onClick={() => onSubmit()}
            >
              {save}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Confirmation;
