import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { routes } from "../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CDCIHeaderLogo,
  CYDCHeaderLogo,
  CYDCSHeaderLogo,
  UserDefaultImage,
} from "../images";
import { nameFormatting } from "../helpers/commonFunction";
import {
  getLatestNotiTypeFromDB,
  getLatestNotiCountFromDB,
} from "../helpers/offlineAPIs";
import { Loader } from "../components";
import { getAllEntriesFromDB } from "../helpers/db";
import {
  CakeIcon,
  NotificationIcon,
  refreshIcon4,
  ExportIcon,
  LogoutIcon,
  TimeInOutIcon,
} from "../icon/icon";
import { useOnlineStatus, useFetchAndSyncData } from "../hooks";
import {
  OfflineModal,
  Confirmation,
  ImportDataModal,
} from "../components/Modals";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOnline } = useOnlineStatus();
  const { loader, user } = useSelector((state) => state.userReducer);
  const { fetchLoader, syncLoader } = useSelector(
    (state) => state.offlineReducer
  );
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const handleShowImportModal = () => {
    setIsOpen(false);
    setShowImportModal(!showImportModal);
  };

  const {
    syncType,
    syncMessage,
    offlineModal,
    confirmationModal,
    handleOfflineModal,
    handleConfirmModal,
    uploadAndFetchData,
  } = useFetchAndSyncData();

  useEffect(() => {
    const handleGetInitialData = async () => {
      const [notificationCount] = await Promise.all([
        getAllEntriesFromDB("notificationCount"),
      ]);
      if (notificationCount && notificationCount?.length > 0) {
        const count = notificationCount[0].ADMIN + notificationCount[0].DM;
        setCount(count);
      } else {
        setCount(0);
      }
    };
    handleGetInitialData();
  }, [location.pathname]);

  useEffect(() => {
    if (
      isOnline &&
      user &&
      (location.pathname === routes.mdList ||
        location.pathname === routes.outOfField ||
        location.pathname === routes.quickSign ||
        location.pathname === routes.itinerary ||
        location.pathname === routes.mdInfo)
    ) {
      getLatestNotiCountFromDB(dispatch, false).catch(() => {});
    }
  }, [location.pathname]);

  const handleNavigateOtherPage = (route) => {
    setIsOpen(false);
    if (route === routes.notifications) {
      getLatestNotiTypeFromDB(dispatch, false).catch(() => {});
    }
    navigate(route, { state: { prev: location?.pathname } });
  };

  const handleSyncData = (type) => {
    setIsOpen(false);
    uploadAndFetchData(type);
  };

  const handleImportDBData = async (type, file) => {
    setIsOpen(false);
    await uploadAndFetchData(type, file);
    handleShowImportModal();
  };

  return (
    <>
      {(fetchLoader || syncLoader || loader) && <Loader />}
      {location.pathname !== routes.callActivities &&
        location.pathname !== routes.timeInOut &&
        location.pathname !== routes.notifications &&
        location.pathname !== routes.mdBirthdayList &&
        location.pathname !== routes.sync && (
          <div className="tc-navbar">
            <div className="tc-navbar-content">
              <span className="tc-navbar-user-name">
                <img
                  src={
                    process.env.REACT_APP_TYPE === "CYDC"
                      ? CYDCHeaderLogo
                      : process.env.REACT_APP_TYPE === "CDCI"
                      ? CDCIHeaderLogo
                      : CYDCSHeaderLogo
                  }
                  alt="logo"
                />
              </span>
              <Dropdown
                show={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
                className="tc-navbar-profile-dropdown ms-auto"
              >
                <Dropdown.Toggle
                  className={`${count > 0 && "tc-navbar-user-profile"}`}
                >
                  {user && nameFormatting(user, "LF")}
                  <img src={UserDefaultImage} alt="userPhoto" />
                  <span
                    className={`${
                      count > 0 && "tc-profile-notification-count"
                    }`}
                  >
                    {count ? count : ""}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item"
                    onClick={() => handleNavigateOtherPage(routes.timeInOut)}
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-2">{TimeInOutIcon}</span>
                      Time In/Out
                    </span>
                  </button>
                  <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item logout-btn d-flex justify-content-between"
                    onClick={() =>
                      handleNavigateOtherPage(routes.notifications)
                    }
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-2">{NotificationIcon}</span>
                      Notifications
                      <span
                        className={`${count > 0 && "tc-notification-count"}`}
                      >
                        {count ? count : ""}
                      </span>
                    </span>
                  </button>
                  <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item logout-btn d-flex justify-content-between"
                    onClick={() =>
                      handleNavigateOtherPage(routes.mdBirthdayList)
                    }
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-2">{CakeIcon}</span>
                      Birthday
                    </span>
                  </button>
                  <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item logout-btn d-flex justify-content-between"
                    onClick={() => handleSyncData("sync")}
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-2">{refreshIcon4}</span>
                      Refresh
                    </span>
                  </button>
                  <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item logout-btn d-flex justify-content-between"
                    onClick={() => handleSyncData("export")}
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-2">{ExportIcon}</span>
                      Export DB
                    </span>
                  </button>
                  {/* <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item logout-btn d-flex justify-content-between"
                    onClick={() => handleShowImportModal()}
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-2">{ExportIcon}</span>
                      Import DB
                    </span>
                  </button> */}
                  <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item logout-btn"
                    onClick={() => handleSyncData("logout")}
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-2">{LogoutIcon}</span>
                      Logout
                    </span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
      {offlineModal && (
        <OfflineModal show={offlineModal} onHide={handleOfflineModal} />
      )}
      {confirmationModal && (
        <Confirmation
          show={confirmationModal}
          onHide={handleConfirmModal}
          title="Syncing Data"
          subTitle="Failed to sync data!"
          description="Please resync the data!"
          cancel="Cancel"
          save="Syncing"
          errorMessage={syncMessage}
          onSubmit={() => handleSyncData(syncType)}
        />
      )}
      {showImportModal && (
        <ImportDataModal
          show={showImportModal}
          onHide={handleShowImportModal}
          onSubmit={handleImportDBData}
        />
      )}
    </>
  );
};
export default Header;
