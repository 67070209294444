import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, authHeaders2 } from "../../helpers/axios";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout, handleTokenRefresh } from "./userSlice";
import { messages } from "../../helpers/messages";

export const reqToGetOutOfFieldData = createAsyncThunk(
  apiendpoints.outOfField,
  async ({ onSuccess, onFailure }) => {
    const request = {
      method: "GET",
      url: apiendpoints.outOfField,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetVMCModalData = createAsyncThunk(
  apiendpoints.outOfFieldModalData,
  async ({ onSuccess, onFailure }) => {
    const request = {
      method: "GET",
      url: apiendpoints.outOfFieldModalData,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToAddOutOfFieldData = createAsyncThunk(
  apiendpoints.addOutOfField,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.addOutOfField,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetMDListByOof = createAsyncThunk(
  apiendpoints.getMDListByOof,
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getMDListByOof,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

const initialState = {
  loader: false,
  error: "",
  apiMessage: "",
  modalData: {},
};

const outOfFieldSlice = createSlice({
  name: "out-of-field",
  initialState,
  reducers: {
    reqToResetVMCApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET VMC LIST
    builder
      .addCase(reqToGetOutOfFieldData.pending, (state) => {
        state.apiMessage = messages.apiMessage.vmcList;
      })
      .addCase(reqToGetOutOfFieldData.fulfilled, () => {})
      .addCase(reqToGetOutOfFieldData.rejected, () => {});

    // GET VMC MODAL DATA
    builder
      .addCase(reqToGetVMCModalData.pending, (state) => {
        state.apiMessage = messages.apiMessage.vmcModalData;
      })
      .addCase(reqToGetVMCModalData.fulfilled, () => {})
      .addCase(reqToGetVMCModalData.rejected, () => {});

    // ADD VMC REQUEST
    builder
      .addCase(reqToAddOutOfFieldData.pending, () => {})
      .addCase(reqToAddOutOfFieldData.fulfilled, () => {})
      .addCase(reqToAddOutOfFieldData.rejected, () => {});

    // GET MDLIST BY VMC
    builder
      .addCase(reqToGetMDListByOof.pending, () => {})
      .addCase(reqToGetMDListByOof.fulfilled, () => {})
      .addCase(reqToGetMDListByOof.rejected, () => {});
  },
});

export const { reqToResetVMCApiMessage } = outOfFieldSlice.actions;
export default outOfFieldSlice.reducer;
