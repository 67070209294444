import React from "react";

const ViewImageModal = (props) => {
  const { imgSrc, show, onHide } = props;
  return (
    <>
      <div
        onClick={onHide}
        className={`tc-img-preview-modal-bg ${
          show && "tc-img-preview-modal-open"
        }`}
      ></div>
      <div
        className={`tc-img-preview-modal ${
          show && "tc-img-preview-modal-open"
        }`}
      >
        <img src={imgSrc} alt="table img" />
      </div>
    </>
  );
};

export default ViewImageModal;
