import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, authHeaders2 } from "../../helpers/axios";
import { apiendpoints } from "../../helpers/constants";
import { messages } from "../../helpers/messages";
import { handleTokenRefresh } from "./userSlice";

export const reqToGetNotificationCount = createAsyncThunk(
  apiendpoints.getNotificationCount,
  async ({ onSuccess, onFailure }) => {
    const request = {
      method: "GET",
      url: apiendpoints.getNotificationCount,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetNotificationType = createAsyncThunk(
  apiendpoints.getNotificationType,
  async ({ onSuccess, onFailure }) => {
    const request = {
      method: "GET",
      url: apiendpoints.getNotificationType,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToGetNotification = createAsyncThunk(
  apiendpoints.getNotifications,
  async ({ data, onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.get(
        `${apiendpoints.getNotifications}?start=${data.start}&limit=${data.limit}&type=${data.type}`,
        header || authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToGetBirthDayData = createAsyncThunk(
  apiendpoints.getBirthDayData,
  async ({ data, onSuccess, onFailure, header }) => {
    const request = {
      method: "POST",
      url: apiendpoints.getBirthDayData,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToReadNotification = createAsyncThunk(
  apiendpoints.readNotifications,
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.readNotifications,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  loader: false,
  error: "",
  apiMessage: "",
  notificationCount: 0,
  notificationType: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reqToResetNotiApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET NOTIFICATION COUNT
    builder
      .addCase(reqToGetNotificationCount.pending, (state) => {
        state.apiMessage = messages.apiMessage.notificationCount;
      })
      .addCase(reqToGetNotificationCount.fulfilled, () => {})
      .addCase(reqToGetNotificationCount.rejected, () => {});

    // GET NOTIFICATION TYPE
    builder
      .addCase(reqToGetNotificationType.pending, (state) => {
        state.apiMessage = messages.apiMessage.notification;
      })
      .addCase(reqToGetNotificationType.fulfilled, () => {})
      .addCase(reqToGetNotificationType.rejected, () => {});

    // GET BIRTHDAY LIST
    builder
      .addCase(reqToGetBirthDayData.pending, (state) => {
        state.apiMessage = messages.apiMessage.mdBirthday;
      })
      .addCase(reqToGetBirthDayData.fulfilled, () => {})
      .addCase(reqToGetBirthDayData.rejected, () => {});

    // GET NOTIFICATION BY TYPE
    builder
      .addCase(reqToGetNotification.pending, () => {})
      .addCase(reqToGetNotification.fulfilled, () => {})
      .addCase(reqToGetNotification.rejected, () => {});

    // READ NOTIFICATION
    builder
      .addCase(reqToReadNotification.pending, () => {})
      .addCase(reqToReadNotification.fulfilled, () => {})
      .addCase(reqToReadNotification.rejected, () => {});
  },
});

export const { reqToResetNotiApiMessage } = notificationSlice.actions;
export default notificationSlice.reducer;
