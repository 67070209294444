import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import { routes } from "../../helpers/constants";
import { updateDashboardDataByMDList } from "../../helpers/commonFunction";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../helpers/db";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Loader } from "../../components";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const navigate = useNavigate();
  const currentDate = moment().format("dddd, MMMM DD");
  const getDashboardMDataFromDB = useLiveQuery(
    () => db.dashBoardMonthly.toArray(),
    []
  );
  const getDashboardDDataFromDB = useLiveQuery(
    () => db.dashBoardPerDay.toArray(),
    []
  );
  const getMDListFromDB = useLiveQuery(() => db.mdList.toArray(), []);
  const getOOFMDList = useLiveQuery(() => db.disabled_mds.toArray(), []);
  const getHolidays = useLiveQuery(() => db.holidays.toArray(), []);
  const { fetchLoader } = useSelector((state) => state.offlineReducer);

  const [isToggle, setIsToggle] = useState(true);
  const [summaryTable, setSummaryTable] = useState([]);

  useEffect(() => {
    if (getMDListFromDB && getMDListFromDB?.length > 0) {
      updateDashboardDataByMDList(getMDListFromDB, getOOFMDList, getHolidays);
    }
  }, [getMDListFromDB, getOOFMDList, getHolidays]);

  useEffect(() => {
    if (isToggle === true && getDashboardDDataFromDB?.length > 0) {
      setSummaryTable(getDashboardDDataFromDB);
    }
  }, [getDashboardDDataFromDB]);

  const calculateTotal = (propertyName) => {
    let total = 0;
    for (const data of summaryTable) {
      const propertyValue = data[propertyName];
      total +=
        typeof propertyValue === "number"
          ? propertyValue
          : parseInt(propertyValue, 10) || 0;
    }
    return total;
  };

  const roundToNearestTen = (number) => {
    return Math.ceil(number / 4) * 4;
  };

  const getGraphData = (callGraphObj) => {
    if (callGraphObj) {
      let target_point =
        roundToNearestTen(calculateTotal("target_call")) > 0
          ? roundToNearestTen(calculateTotal("target_call"))
          : 4;
      return {
        target_col: calculateTotal("target_call"),
        acutal_col: calculateTotal("actual_call"),
        target_point: target_point,
      };
    } else {
      let target_point =
        roundToNearestTen(calculateTotal("target_md")) > 0
          ? roundToNearestTen(calculateTotal("target_md"))
          : 4;
      return {
        target_col: calculateTotal("target_md"),
        acutal_col: calculateTotal("actual_md"),
        target_point: target_point,
      };
    }
  };

  const chartOptions = (chartType) => {
    return {
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: true,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          style: {
            color: "black",
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
            barHeight: "100%",
            borderRadius: 5,
          },
        },
        title: {
          text: chartType === "call_Rate" ? "CALL RATE" : "MD REACH",
          align: chartType === "call_Rate" ? "left" : "right",
          margin: 10,
          offsetX: chartType === "call_Rate" ? -2 : 2,
          offsetY: -0,
          floating: false,
          style: {
            fontSize: "20px",
            fontWeight: "600",
            fontFamily: undefined,
            color: "#F3F3F3",
            textTransform: "uppercase",
            borderBottom: "1px solid black",
          },
        },
        subtitle: {
          text: getSubTitles(chartType),
          align: chartType === "call_Rate" ? "left" : "right",
          offsetX: chartType === "call_Rate" ? -2 : 2,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "400",
            color: "#FFFFFF",
            textTransform: "uppercase",
          },
        },
        colors:
          chartType === "call_Rate"
            ? ["#ffa19c", "#caf2c2"]
            : ["#ffa19c", "#caf2c2"],
        xaxis: {
          categories: [""],
          crosshairs: {
            show: true,
          },
          axisBorder: {
            show: false,
            color: "#ffffff",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          logBase: 5,
          tickAmount: 4,
          min: 0,
          max:
            chartType === "call_Rate"
              ? getGraphData(true).target_point
              : getGraphData().target_point,
          labels: {
            formatter: function (val) {
              return val !== 0 ? val : 0;
            },
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "white",
              fontSize: "12px",
              fontWeight: 600,
            },
          },
          axisBorder: {
            show: false,
            color: "#ffffff",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#ffffff",
            width: 14,
            offsetX: 7,
            offsetY: 0,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "10px",
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          offsetX: 10,
          offsetY: 0,
          fontWeight: 400,
          labels: {
            colors: "white",
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
          },
        },
      },
      series: [
        {
          name: "Actual",
          data:
            chartType === "call_Rate"
              ? [getGraphData(true).acutal_col]
              : [getGraphData().acutal_col],
        },
        {
          name: "Target",
          data:
            chartType === "call_Rate"
              ? [getGraphData(true).target_col]
              : [getGraphData().target_col],
        },
      ],
    };
  };

  const getSubTitles = (chartType) => {
    if (chartType === "call_Rate") {
      return (
        `Actual: ${calculateTotal("actual_call")}` +
        " " +
        `Target: ${calculateTotal("target_call")}`
      );
    } else {
      return (
        `Actual: ${calculateTotal("actual_md")}` +
        " " +
        `Target: ${calculateTotal("target_md")}`
      );
    }
  };

  const getPercentage = (type) => {
    let totalTargetCalls;
    let totalAcutalCalls;
    let finalPrctge = 0;
    if (type === "call") {
      totalTargetCalls = calculateTotal("target_call");
      totalAcutalCalls = calculateTotal("actual_call");
    } else {
      totalTargetCalls = calculateTotal("target_md");
      totalAcutalCalls = calculateTotal("actual_md");
    }
    if (totalTargetCalls !== 0) {
      finalPrctge = (100 * totalAcutalCalls) / totalTargetCalls;
    }
    return finalPrctge.toFixed(2);
  };

  const getPercentage22 = (target, acutal) => {
    let finalPrctge = 0;
    if (target && acutal && target !== 0) {
      finalPrctge = (100 * acutal) / target;
    } else {
      return 0;
    }
    return finalPrctge.toFixed(2);
  };

  const toggleChangeHandler = () => {
    setIsToggle(!isToggle);
    isToggle
      ? setSummaryTable(getDashboardMDataFromDB)
      : setSummaryTable(getDashboardDDataFromDB);
  };

  return (
    <>
      {fetchLoader && <Loader />}
      <div className="tc-body">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="tc-profile-wrapper mb-0">
            <span className="tc-profile-name">DASHBOARD</span>
          </div>
          <div className="text-end fs-18">
            <p className="mb-0 fw-semibold">{moment().format("MMMM YYYY")}</p>
            <p className="mb-0">As of {currentDate}</p>
          </div>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            <div className="tc-chart-content">
              <Row>
                <Col md={3} id="Box-1">
                  <div className="tc-chart-container">
                    <Chart
                      options={chartOptions("call_Rate").options}
                      series={chartOptions("call_Rate").series}
                      type="bar"
                      className="tc-chart tc-dashboard-chart"
                      height={350}
                      width={"100%"}
                    />
                  </div>
                </Col>
                <Col md={6} id="Box-2">
                  <Row>
                    <Col md={12}>
                      <div className="tc-dashboard-header mb-2">
                        <h6 className="mb-0 pe-2">
                          {!isToggle
                            ? "Full Month Target"
                            : "Month-to-Date Target"}
                        </h6>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="toggle-btn"
                            value={isToggle}
                            checked={isToggle ? true : false}
                            onChange={() => toggleChangeHandler()}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} md={5} className="p-0 tc-tab-view-table">
                      <div className="tc-dashboard-table tc-table-wrapper">
                        <Table responsive className="tc-table">
                          <thead>
                            <tr>
                              <th>ACTUAL</th>
                              <th>TARGET</th>
                              <th>%</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryTable.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.actual_call}</td>
                                  <td>{item.target_call}</td>
                                  <td>
                                    {getPercentage22(
                                      item.target_call,
                                      item.actual_call
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>{calculateTotal("actual_call")}</td>
                              <td>{calculateTotal("target_call")}</td>
                              <td>{getPercentage("call") + "%"}</td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Col>
                    <Col xl={4} md={2} className="tc-p-0 tc-tab-view-table">
                      <div className="tc-class-table tc-table-wrapper middle-table">
                        <Table responsive className="tc-table">
                          <thead>
                            <tr>
                              <th>CLASS </th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryTable.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="tc-class-border">
                                      {item.class}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <div className="tc-class-border">TOTAL</div>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Col>
                    <Col xl={4} md={5} className="p-0 tc-tab-view-table">
                      <div className="tc-dashboard-table tc-table-wrapper">
                        <Table responsive className="tc-table">
                          <thead>
                            <tr>
                              <th>ACTUAL</th>
                              <th>TARGET</th>
                              <th>%</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryTable.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.actual_md}</td>
                                  <td>{item.target_md}</td>
                                  <td>
                                    {getPercentage22(
                                      item.target_md,
                                      item.actual_md
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>{calculateTotal("actual_md")}</td>
                              <td>{calculateTotal("target_md")}</td>
                              <td>{getPercentage("") + "%"}</td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} id="Box-3">
                  <div className="tc-chart-container">
                    <Chart
                      className="tc-chart tc-dashboard-chart"
                      options={chartOptions().options}
                      series={chartOptions().series}
                      type="bar"
                      height={350}
                      width={"100%"}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mt-4 text-center">
            <Button
              variant="primary"
              type="button"
              className="btn-min"
              onClick={() => navigate(routes.reports)}
            >
              Reports
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
