import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { useDispatch } from "react-redux";
import moment from "moment";
import { db } from "../../helpers/db";
import { backBtnIcon } from "../../icon/icon";
import ReportsDetail from "./ReportsDetail";
import {
  dateFormatting,
  getHolidayByDate,
  getOOFMDListByDate,
  getPrevious7DaysArr,
  defaultDateDashFormat,
  convertDefaultTimeZone,
  updateDashboardDataByMDList,
} from "../../helpers/commonFunction";
import {
  mdExcuse,
  reqToGetCallSummaryData,
  reqToGetCallConcentrationData,
  reqToGetPromoMatsInventoryData,
  reqToGetMDCoverageData,
  reqToGetSamplesIssuanceData,
  reqToGetPromoMatsIssuanceData,
  reqToGetIncidentalCalls,
  reqToGetDailyCallRateData,
  reqToGetDailyMdReachData,
  reqToGetMonthlyCallRateData,
  reqToGetMonthlyMdReachData,
  reqToGetMonthlyTotalMDs,
  reqToUpdateDaysFieldReport,
  reqToGetDaysFieldReport,
  reqToGetNotSubmittedSavedCalls,
  reqToGetNotSignedJointCalls,
  reqToGetVMCRequestsData,
  reqToGetNotYetCalledMissedCalls,
} from "../../store/slice/dashboardSlice";

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDate = moment().format("YYYY-MM-DD");
  const getMDListFromDB = useLiveQuery(() => db.mdList.toArray(), []);
  const getCumulativeCallsFromDB = useLiveQuery(
    () => db.cumulativeCalls.toArray(),
    []
  );
  const getOOFMDList = useLiveQuery(() => db.disabled_mds.toArray(), []);
  const getHolidays = useLiveQuery(() => db.holidays.toArray(), []);
  const getDaysFieldFromDB = useLiveQuery(() => db.daysField.toArray(), []);
  const getIncidentalDataFromDB = useLiveQuery(
    () => db.incidentalCalls.toArray(),
    []
  );
  const monthlyCallRateData = useLiveQuery(
    () => db.monthlyCallRate.toArray(),
    []
  );
  const monthlyMdReachData = useLiveQuery(
    () => db.monthlyMDReach.toArray(),
    []
  );
  const promoMatsInventoryData = useLiveQuery(
    () => db.promoMatsInventory.toArray(),
    []
  );
  const getOOFListFromDB = useLiveQuery(() => db.oofListData.toArray(), []);

  const reportTypes = [
    {
      title: "Today’s Reports",
      type: "today",
      name: "Today's",
      list: [
        {
          value: "md_coverage",
          label: "Itinerary Calls",
          active: true,
        },
        {
          value: "samples_issuance",
          label: "Samples Issuance",
          active: false,
        },
        {
          value: "promo_mats_issuance",
          label: "Promo Mats Issuance",
          active: false,
        },
        {
          value: "incidental_calls",
          label: "Incidental Calls",
          active: true,
        },
        {
          value: "today_not_submitted_saved_calls",
          label: "Calls Saved, Not Submitted",
          active: true,
        },
        {
          value: "today_not_signed_joint_calls",
          label: "Joint Calls",
          active: true,
        },
        {
          value: "today_vmc_request",
          label: "VMC Requests",
          active: true,
        },
      ],
    },
    {
      title: "Cumulative Reports",
      type: "cumulative",
      name: "Cumulative",
      list: [
        {
          value: "total_calls",
          label: "Itinerary Calls for the Month",
          active: true,
        },
        {
          value: "total_mds",
          label: "Total MDs",
          active: false,
        },
        {
          value: "call_summary",
          label: "Call Summary per MD Class",
          active: false,
        },
        {
          value: "call_concentration",
          label: "Call Concentration",
          active: false,
        },
        {
          value: "days_in_field",
          label: "Days in Field",
          active: false,
        },
        {
          value: "promo_mats_inventory",
          label: "Promo Mats Inventory",
          active: false,
        },
        {
          value: "monthly_incidental_calls",
          label: "Incidental Calls for the Month",
          active: true,
        },
        {
          value: "monthly_not_submitted_saved_calls",
          label: "Calls Saved, Not Submitted for the Month",
          active: true,
        },
        {
          value: "monthly_not_signed_joint_calls",
          label: "Joint Calls for the Month",
          active: true,
        },
        {
          value: "monthly_vmc_request",
          label: "VMC Requests for the Month",
          active: true,
        },
        {
          value: "monthly_missed_calls",
          label: "Missed Calls for the Month",
          active: false,
        },
      ],
    },
    {
      title: "Periodic Reports",
      type: "periodic",
      name: "Periodic",
      list: [
        {
          value: "daily_call_rate",
          label: "Daily Totals – Itinerary Calls",
          active: true,
        },
        {
          value: "daily_md_reach",
          label: "Daily MD Totals",
          active: false,
        },
        {
          value: "daily_incidental_calls",
          label: "Daily Totals - Incidental Calls",
          active: true,
        },
        {
          value: "monthly_call_rate",
          label: "Monthly Call Totals",
          active: true,
        },
        {
          value: "monthly_md_reach",
          label: "Monthly MD Reach",
          active: true,
        },
      ],
    },
  ];

  const [isAciveReport, setIsAciveReport] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [isRefreshed, setIsRefreshed] = useState(false);

  const onSelectReportHandler = (report) => {
    if (report.value === "call_summary") {
      const newData = getCallSummaryPerMDClassData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate
      );
      dispatch(reqToGetCallSummaryData(newData));
    } else if (report.value === "md_coverage") {
      const newData = getMDCoverageData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate,
        "today",
        "T"
      );
      dispatch(reqToGetMDCoverageData(newData));
    } else if (report.value === "samples_issuance") {
      const { samplesData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        currentDate,
        "samples_issuance"
      );
      dispatch(reqToGetSamplesIssuanceData(samplesData));
    } else if (report.value === "promo_mats_issuance") {
      const { promoMatsData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        currentDate,
        "promo_mats_issuance"
      );
      dispatch(reqToGetPromoMatsIssuanceData(promoMatsData));
    } else if (report.value === "call_concentration") {
      const newData = getCallConcentrationData(getMDListFromDB, currentDate);
      dispatch(reqToGetCallConcentrationData(newData));
    } else if (report.value === "days_in_field") {
      dispatch(reqToUpdateDaysFieldReport(getDaysFieldFromDB));
    } else if (report.value === "md_excuse") {
      dispatch(mdExcuse());
    } else if (report.value === "promo_mats_inventory") {
      dispatch(reqToGetPromoMatsInventoryData(promoMatsInventoryData));
    } else if (report.value === "daily_call_rate") {
      const { dailyCallRate } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate,
        "daily_call_rate"
      );
      dispatch(reqToGetDailyCallRateData(dailyCallRate));
    } else if (report.value === "daily_md_reach") {
      const { dailyMdReach } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate,
        "daily_md_reach"
      );
      dispatch(reqToGetDailyMdReachData(dailyMdReach));
    } else if (report.value === "monthly_call_rate") {
      const newData = getMonthlyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        monthlyCallRateData,
        "monthly_call_rate"
      );
      dispatch(reqToGetMonthlyCallRateData(newData));
    } else if (report.value === "monthly_md_reach") {
      const newData = getMonthlyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        monthlyMdReachData,
        "monthly_md_reach"
      );
      dispatch(reqToGetMonthlyMdReachData(newData));
    } else if (report.value === "total_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === currentDate &&
            !item?.is_save &&
            !item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getMDCoverageData(
          getCumulativeCallsFromDB,
          [],
          [],
          currentDate,
          "month",
          "C"
        );
        dispatch(reqToGetMDCoverageData({ list: newData, chart: [] }));
      } else {
        const newData = getMDCoverageData(
          getMDListFromDB,
          getOOFMDList,
          getHolidays,
          currentDate,
          "today",
          "C"
        );
        dispatch(reqToGetMDCoverageData(newData));
      }
    } else if (report.value === "total_mds") {
      const newData = getMonthlyTotalCallsMDsData(getMDListFromDB, "", "mds");
      dispatch(reqToGetMonthlyTotalMDs(newData));
    } else if (report.value === "incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        currentDate,
        "today"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (report.value === "monthly_incidental_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === currentDate &&
            item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getIncidentalCalls(
          getCumulativeCallsFromDB,
          currentDate,
          "month",
          "cumulative"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      } else {
        const newData = getIncidentalCalls(
          getIncidentalDataFromDB,
          currentDate,
          "month"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      }
    } else if (report.value === "daily_incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        currentDate,
        "daily"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (report.value === "today_not_submitted_saved_calls") {
      const newData = getNotSubmittedSavedCalls(
        getMDListFromDB,
        currentDate,
        "today",
        "T"
      );
      dispatch(reqToGetNotSubmittedSavedCalls(newData));
    } else if (report.value === "monthly_not_submitted_saved_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === currentDate &&
            item?.is_save
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSubmittedSavedCalls(
          getCumulativeCallsFromDB,
          currentDate,
          "month",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      } else {
        const newData = getNotSubmittedSavedCalls(
          getMDListFromDB,
          currentDate,
          "today",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      }
    } else if (report.value === "today_not_signed_joint_calls") {
      const newData = getNotSignedJointCalls(
        getMDListFromDB,
        getIncidentalDataFromDB,
        currentDate,
        "today",
        "T"
      );
      dispatch(reqToGetNotSignedJointCalls(newData));
    } else if (report.value === "monthly_not_signed_joint_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) => convertDefaultTimeZone(item?.record_time) === currentDate
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSignedJointCalls(
          getCumulativeCallsFromDB,
          getIncidentalDataFromDB,
          currentDate,
          "month",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      } else {
        const newData = getNotSignedJointCalls(
          getMDListFromDB,
          getIncidentalDataFromDB,
          currentDate,
          "today",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      }
    } else if (report.value === "today_vmc_request") {
      const newData = getVMCRequestsData(
        getOOFListFromDB,
        currentDate,
        "today"
      );
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (report.value === "monthly_vmc_request") {
      const newData = getVMCRequestsData(
        getOOFListFromDB,
        currentDate,
        "month"
      );
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (report.value === "monthly_missed_calls") {
      const newData = getNotYetCalledMissedCalls(
        getMDListFromDB,
        getOOFMDList,
        currentDate
      );
      dispatch(reqToGetNotYetCalledMissedCalls(newData));
    }
    setSelectedReportType(report);
    setIsAciveReport(true);
  };

  const refreshToGetCurrentDateData = () => {
    if (selectedReportType.value === "call_summary") {
      const newData = getCallSummaryPerMDClassData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays
      );
      dispatch(reqToGetCallSummaryData(newData));
    } else if (selectedReportType.value === "call_concentration") {
      const newData = getCallConcentrationData(getMDListFromDB);
      dispatch(reqToGetCallConcentrationData(newData));
    } else if (selectedReportType.value === "md_excuse") {
      dispatch(mdExcuse({ refresh: true }));
    } else if (selectedReportType.value === "days_in_field") {
      dispatch(reqToGetDaysFieldReport({ data: { refresh: true } }));
    }
    setIsRefreshed(true);
  };

  const onDateChangehandler = (event) => {
    const selectedD = moment(event).format("YYYY-MM-DD");
    if (selectedReportType.value === "md_coverage") {
      const newData = getMDCoverageData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        selectedD,
        "today",
        "T"
      );
      dispatch(reqToGetMDCoverageData(newData));
    } else if (selectedReportType.value === "samples_issuance") {
      const { samplesData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        selectedD,
        "samples_issuance"
      );
      dispatch(reqToGetSamplesIssuanceData(samplesData));
    } else if (selectedReportType.value === "promo_mats_issuance") {
      const { promoMatsData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        selectedD,
        "promo_mats_issuance"
      );
      dispatch(reqToGetPromoMatsIssuanceData(promoMatsData));
    } else if (selectedReportType.value === "daily_call_rate") {
      const { dailyCallRate } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        selectedD,
        "daily_call_rate"
      );
      dispatch(reqToGetDailyCallRateData(dailyCallRate));
    } else if (selectedReportType.value === "daily_md_reach") {
      const { dailyMdReach } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        selectedD,
        "daily_md_reach"
      );
      dispatch(reqToGetDailyMdReachData(dailyMdReach));
    } else if (selectedReportType.value === "total_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === selectedD &&
            !item?.is_save &&
            !item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getMDCoverageData(
          getCumulativeCallsFromDB,
          [],
          [],
          selectedD,
          "month",
          "C"
        );
        dispatch(reqToGetMDCoverageData({ list: newData, chart: [] }));
      } else {
        const newData = getMDCoverageData(
          getMDListFromDB,
          getOOFMDList,
          getHolidays,
          selectedD,
          "today",
          "C"
        );
        dispatch(reqToGetMDCoverageData(newData));
      }
    } else if (selectedReportType.value === "incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        selectedD,
        "today"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (selectedReportType.value === "monthly_incidental_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === selectedD &&
            item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getIncidentalCalls(
          getCumulativeCallsFromDB,
          selectedD,
          "month",
          "cumulative"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      } else {
        const newData = getIncidentalCalls(
          getIncidentalDataFromDB,
          selectedD,
          "month"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      }
    } else if (selectedReportType.value === "daily_incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        selectedD,
        "daily"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (selectedReportType.value === "today_not_submitted_saved_calls") {
      const newData = getNotSubmittedSavedCalls(
        getMDListFromDB,
        selectedD,
        "today",
        "T"
      );
      dispatch(reqToGetNotSubmittedSavedCalls(newData));
    } else if (
      selectedReportType.value === "monthly_not_submitted_saved_calls"
    ) {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === selectedD &&
            item?.is_save
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSubmittedSavedCalls(
          getCumulativeCallsFromDB,
          selectedD,
          "month",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      } else {
        const newData = getNotSubmittedSavedCalls(
          getMDListFromDB,
          selectedD,
          "today",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      }
    } else if (selectedReportType.value === "today_not_signed_joint_calls") {
      const newData = getNotSignedJointCalls(
        getMDListFromDB,
        getIncidentalDataFromDB,
        selectedD,
        "today",
        "T"
      );
      dispatch(reqToGetNotSignedJointCalls(newData));
    } else if (selectedReportType.value === "monthly_not_signed_joint_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) => convertDefaultTimeZone(item?.record_time) === selectedD
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSignedJointCalls(
          getCumulativeCallsFromDB,
          getIncidentalDataFromDB,
          selectedD,
          "month",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      } else {
        const newData = getNotSignedJointCalls(
          getMDListFromDB,
          getIncidentalDataFromDB,
          selectedD,
          "today",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      }
    } else if (selectedReportType.value === "today_vmc_request") {
      const newData = getVMCRequestsData(getOOFListFromDB, selectedD, "today");
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (selectedReportType.value === "monthly_vmc_request") {
      const newData = getVMCRequestsData(getOOFListFromDB, selectedD, "month");
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (selectedReportType.value === "monthly_missed_calls") {
      const newData = getNotYetCalledMissedCalls(
        getMDListFromDB,
        getOOFMDList,
        selectedD
      );
      dispatch(reqToGetNotYetCalledMissedCalls(newData));
    }
    setSelectedDate(selectedD);
  };

  // call_summary
  const getCallSummaryPerMDClassData = (
    dataList,
    oofList,
    holidays,
    currDate
  ) => {
    const dashDData = {
      A: {
        targetCalls: [],
        actualCalls: [],
        targetCount: [],
        actualCount: [],
        F2FCalls: [],
        virtualCall: [],
      },
      B: {
        targetCalls: [],
        actualCalls: [],
        targetCount: [],
        actualCount: [],
        F2FCalls: [],
        virtualCall: [],
      },
      C: {
        targetCalls: [],
        actualCalls: [],
        targetCount: [],
        actualCount: [],
        F2FCalls: [],
        virtualCall: [],
      },
      D: {
        targetCalls: [],
        actualCalls: [],
        targetCount: [],
        actualCount: [],
        F2FCalls: [],
        virtualCall: [],
      },
    };
    const dailyData = [];
    const date = moment(currDate).subtract(1, "days").format("YYYY-MM-DD");
    const currentDate = currDate
      ? defaultDateDashFormat(date)
      : defaultDateDashFormat(new Date());
    dataList &&
      dataList?.length > 0 &&
      dataList?.forEach((entry) => {
        const { name, is_summary, md_id, date, isVirtual, record_date } = entry;
        const entryDate = defaultDateDashFormat(date);
        const visitDate = record_date
          ? defaultDateDashFormat(record_date)
          : null;
        const oofMDList = getOOFMDListByDate(oofList, date);
        const holiday = getHolidayByDate(holidays, date);

        if (!dashDData[name]) {
          dashDData[name] = {
            targetCalls: [],
            actualCalls: [],
            targetCount: [],
            actualCount: [],
            F2FCalls: [],
            virtualCall: [],
          };
        }

        // Get dashboard perDay data
        if (entryDate <= currentDate) {
          if (!oofMDList.includes(md_id) && holiday?.date !== date) {
            dashDData[name].targetCalls.push(entry);
          }
          if (!dashDData[name].targetCount[md_id]) {
            dashDData[name].targetCount[md_id] = { ...entry };
          }
          if (is_summary === 1 && !dashDData[name].actualCount[md_id]) {
            dashDData[name].actualCount[md_id] = { ...entry };
          }
        }
        if (
          visitDate !== null &&
          visitDate <= currentDate &&
          is_summary === 1
        ) {
          dashDData[name].actualCalls.push(entry);
          if (isVirtual === 1) {
            dashDData[name].virtualCall.push(entry);
          } else {
            dashDData[name].F2FCalls.push(entry);
          }
        }
      });

    Object.keys(dashDData).forEach((className, index) => {
      const {
        targetCalls,
        actualCalls,
        targetCount,
        actualCount,
        F2FCalls,
        virtualCall,
      } = dashDData[className];
      const newTargetCount = Object.values(targetCount);
      const newActualCount = Object.values(actualCount);

      dailyData[index] = {
        id: index + 1,
        class: className,
        actual_call: actualCalls.length,
        actual_md: newActualCount.length,
        f2f_call: F2FCalls.length,
        perf_call: 0,
        perf_md: 0,
        virtual_call: virtualCall.length,
        target_call: targetCalls.length,
        target_md: newTargetCount.length,
      };
    });
    return dailyData;
  };
  // call_concentration
  const getCallConcentrationData = (dataList) => {
    const uniqueData = new Map();
    const newDataList = [];
    dataList &&
      dataList?.length > 0 &&
      dataList?.forEach(({ md_id, name, is_summary }) => {
        const existingEntry = uniqueData.get(md_id);
        uniqueData.set(md_id, {
          class_name: name,
          total_calls:
            (existingEntry ? existingEntry.total_calls : 0) +
            (is_summary === 1 ? 1 : 0),
          total_mds: 0,
        });
      });
    const newUniqueData = Array.from(uniqueData.values());
    newUniqueData &&
      newUniqueData?.length > 0 &&
      newUniqueData?.forEach(({ class_name, total_calls, total_mds }) => {
        const index = newDataList.findIndex(
          (item) =>
            item.class_name === class_name && item.total_calls === total_calls
        );
        if (index !== -1) {
          newDataList[index].total_mds++;
        } else {
          newDataList.push({
            class_name,
            total_calls,
            total_mds: total_mds + 1,
          });
        }
      });
    return newDataList;
  };
  // md_coverage and total_calls
  const getMDCoverageData = (
    dataList,
    oofList,
    holidays,
    sDate,
    type,
    rType
  ) => {
    if (type === "today") {
      let currDateMDs = [];
      let currDateCalls = [];
      const isCurrDate = sDate === dateFormatting();
      const mdCoverageData = {
        chart: [
          { class: "A", actual_md: 0, target_md: 0, perf_md: "0.00" },
          { class: "B", actual_md: 0, target_md: 0, perf_md: "0.00" },
          { class: "C", actual_md: 0, target_md: 0, perf_md: "0.00" },
          { class: "D", actual_md: 0, target_md: 0, perf_md: "0.00" },
        ],
        list: [],
        date: sDate,
      };

      currDateMDs =
        (dataList &&
          dataList?.length > 0 &&
          dataList.filter((entry) => entry.date === sDate)) ||
        [];
      currDateCalls =
        (dataList &&
          dataList?.length > 0 &&
          dataList
            .filter((entry) =>
              rType === "T"
                ? defaultDateDashFormat(entry?.record_date) === sDate
                : isCurrDate
                ? defaultDateDashFormat(entry?.record_date) < sDate
                : defaultDateDashFormat(entry?.record_date) <= sDate
            )
            .sort(
              (a, b) => new Date(b?.record_date) - new Date(a?.record_date)
            )) ||
        [];
      const oofMDList = getOOFMDListByDate(oofList, sDate);
      const holiday = getHolidayByDate(holidays, sDate);
      currDateMDs?.forEach((entry) => {
        const { md_id, name, date } = entry;
        const classIndex = mdCoverageData.chart.findIndex(
          (c) => c.class === name
        );
        if (
          classIndex !== -1 &&
          !oofMDList.includes(md_id) &&
          holiday?.date !== date
        ) {
          mdCoverageData.chart[classIndex].target_md++;
        }
      });
      currDateCalls?.forEach((entry) => {
        const {
          name,
          date,
          data,
          address,
          isQuicksign,
          is_summary,
          record_date,
        } = entry;
        if (!is_summary) return;
        const classIndex = mdCoverageData.chart.findIndex(
          (c) => c.class === name
        );
        if (classIndex === -1) return;
        const call_date = defaultDateDashFormat(record_date);
        const callType = isQuicksign
          ? "Quick Call"
          : data?.quickSignObj &&
            Object.keys(data?.quickSignObj)?.length > 0 &&
            (data?.quickSignObj?.signature_url ||
              data?.quickSignObj?.signPadData)
          ? "Quick Call"
          : "Itinerary";
        const reliever_md =
          data && data?.reliever_md && Object.keys(data?.reliever_md).length > 0
            ? data?.reliever_md
            : "";
        mdCoverageData.chart[classIndex].actual_md++;
        mdCoverageData.list.push({
          ...entry,
          address: address ? address : "Not yet available",
          reliever_md: reliever_md,
          call_type: callType,
          signature_md_time: data?.signature_time,
          remark:
            call_date < date
              ? "Advanced"
              : call_date > date
              ? "Make-Up"
              : "On-Time",
        });
      });
      return mdCoverageData;
    } else {
      const list = [];
      const isCurrDate = sDate === dateFormatting();
      dataList &&
        dataList?.length > 0 &&
        dataList?.forEach((entry) => {
          const {
            data,
            is_save,
            target_date,
            isQuicksign,
            is_incidental,
            record_time,
          } = entry;
          if (is_save || is_incidental) return;
          const checkCallDate = isCurrDate
            ? convertDefaultTimeZone(record_time) < sDate
            : convertDefaultTimeZone(record_time) <= sDate;
          if (!checkCallDate) return;
          const sMD = data && data?.selectedMD;
          const newData = data && data?.summary_data;
          const call_date = defaultDateDashFormat(record_time);
          const callType = isQuicksign
            ? "Quick Call"
            : newData?.quickSignObj &&
              Object.keys(newData?.quickSignObj)?.length > 0 &&
              (newData?.quickSignObj?.signature_url ||
                newData?.quickSignObj?.signPadData)
            ? "Quick Call"
            : "Itinerary";
          const reliever_md =
            newData &&
            newData?.reliever_md &&
            Object.keys(newData?.reliever_md).length > 0
              ? newData?.reliever_md
              : "";
          list.push({
            ...sMD,
            address: sMD?.address ? sMD?.address : "No Location Found",
            record_date: record_time,
            date: target_date,
            reliever_md: reliever_md,
            call_type: callType,
            signature_md_time: newData?.signature_time,
            remark:
              call_date < target_date
                ? "Advanced"
                : call_date > target_date
                ? "Make-Up"
                : "On-Time",
          });
        });
      const newList =
        (list?.length > 0 &&
          list.sort(
            (a, b) => new Date(b?.record_date) - new Date(a?.record_date)
          )) ||
        list;
      return newList;
    }
  };
  // promo_mats_issuance
  const getSamplesPromoIssuanceData = (dataList, sDate, type) => {
    let currDateArray = [];
    const samplesData = [];
    const promoMatsData = [];

    currDateArray =
      (dataList &&
        dataList?.length > 0 &&
        dataList?.filter(
          (entry) => defaultDateDashFormat(entry?.record_date) === sDate
        )) ||
      [];
    currDateArray.forEach((entry) => {
      const { is_summary, data } = entry;
      if (is_summary === 1 && data) {
        data &&
          data?.ProductSampling?.length > 0 &&
          data?.ProductSampling?.map((item) => {
            if (
              type === "samples_issuance" &&
              (item.promomat_type === "P" || item.promomat_type === "C")
            ) {
              samplesData.push({
                ...item,
                class_id: entry?.class_id,
                firstname: entry?.firstname,
                id: entry?.call_id,
                lastname: entry?.lastname,
                lot_number: item?.lot_number,
                md_id: entry?.md_id,
                record_time: entry?.record_date,
                sku: item?.name,
                quantity: item?.quantity,
                type: item?.promomat_type,
              });
            } else if (
              type === "promo_mats_issuance" &&
              item.promomat_type !== "P" &&
              item.promomat_type !== "C"
            ) {
              promoMatsData.push({
                ...item,
                class_id: entry?.class_id,
                firstname: entry?.firstname,
                id: entry?.call_id,
                lastname: entry?.lastname,
                lot_number: item?.lot_number,
                md_id: entry?.md_id,
                record_time: entry?.record_date,
                sku: item?.name,
                quantity: item?.quantity,
                type: item?.promomat_type,
              });
            }
            return item;
          });
      }
    });
    return { samplesData, promoMatsData };
  };
  // daily_call_rate and daily_md_reach
  const getDailyCallRateMdReachData = (
    dataList,
    oofList,
    holidays,
    sDate,
    type
  ) => {
    let dailyCallRate = [];
    let dailyMdReach = [];
    if (type === "daily_call_rate") {
      dailyCallRate = getPrevious7DaysArr(sDate).map((item) => {
        return {
          date: item,
          target_calls: 0,
          actual_call: 0,
        };
      });

      dataList &&
        dataList?.length > 0 &&
        dataList?.forEach((entry) => {
          const { md_id, date, is_summary, record_date } = entry;
          const oofMDList = getOOFMDListByDate(oofList, date);
          const holiday = getHolidayByDate(holidays, date);
          const classIndex1 = dailyCallRate.findIndex(
            (item) => item.date === date
          );
          const classIndex2 = dailyCallRate.findIndex(
            (item) => item.date === defaultDateDashFormat(record_date)
          );
          if (
            classIndex1 !== -1 &&
            !oofMDList.includes(md_id) &&
            holiday?.date !== date
          ) {
            dailyCallRate[classIndex1].target_calls++;
          }
          if (classIndex2 !== -1 && is_summary === 1) {
            dailyCallRate[classIndex2].actual_call++;
          }
        });
    } else {
      dailyMdReach = getPrevious7DaysArr(sDate).map((item) => {
        return {
          date: item,
          target_mds: 0,
          actual_mds: 0,
        };
      });

      dataList &&
        dataList?.length > 0 &&
        dataList?.forEach((entry) => {
          const { md_id, date, is_summary, record_date } = entry;
          const oofMDList = getOOFMDListByDate(oofList, date);
          const holiday = getHolidayByDate(holidays, date);
          const classIndex1 = dailyMdReach.findIndex(
            (item) => item.date === date
          );
          const classIndex2 = dailyMdReach.findIndex(
            (item) => item.date === defaultDateDashFormat(record_date)
          );
          if (
            classIndex1 !== -1 &&
            !oofMDList.includes(md_id) &&
            holiday?.date !== date
          ) {
            dailyMdReach[classIndex1].target_mds++;
          }
          if (classIndex2 !== -1 && is_summary === 1) {
            dailyMdReach[classIndex2].actual_mds++;
          }
        });
    }
    return { dailyCallRate, dailyMdReach };
  };
  // monthly_call_rate and monthly_md_reach
  const getMonthlyCallRateMdReachData = (
    mdList,
    oofList,
    holidays,
    dataList,
    type
  ) => {
    let calCallRate = 0;
    let newDataList = [];
    let currentMonth = new Date().toISOString().slice(0, 7);
    const { monthlyData } = updateDashboardDataByMDList(mdList);
    const oofMDList = getAllOOFMDList(oofList);
    const isHoliday = (date) => {
      if (holidays && holidays?.length > 0) {
        return holidays.some((h) => h.date === date);
      } else {
        return false;
      }
    };
    let getMDWithHoliday =
      (mdList &&
        mdList?.length > 0 &&
        mdList.filter((md) => isHoliday(md.date))) ||
      [];
    if (type === "monthly_call_rate") {
      monthlyData &&
        monthlyData?.length > 0 &&
        monthlyData?.forEach((item) => {
          return (calCallRate += item.actual_call);
        });
      newDataList =
        dataList &&
        dataList?.length > 0 &&
        dataList?.map((item) => {
          if (item.month === currentMonth) {
            return {
              ...item,
              actual_call: calCallRate,
              target_calls:
                item.target_calls -
                oofMDList?.length -
                getMDWithHoliday?.length,
            };
          } else {
            return {
              ...item,
            };
          }
        });
      return newDataList;
    } else {
      monthlyData &&
        monthlyData?.length > 0 &&
        monthlyData?.forEach((item) => {
          return (calCallRate += item.actual_md);
        });
      newDataList =
        dataList &&
        dataList?.length > 0 &&
        dataList?.map((item) => {
          if (item.month === currentMonth) {
            return {
              ...item,
              actual_mds: calCallRate,
            };
          } else {
            return {
              ...item,
            };
          }
        });
      return newDataList;
    }
  };
  // total_mds
  const getMonthlyTotalCallsMDsData = (mdList, sDate, type) => {
    if (type === "calls" && mdList?.length > 0) {
      let newCalls = mdList
        .filter(
          (call) =>
            call.is_summary === 1 &&
            defaultDateDashFormat(call?.record_date) === sDate
        )
        .sort((a, b) => new Date(b?.record_date) - new Date(a?.record_date));
      let uniqueCallsMap = new Map();
      newCalls &&
        newCalls?.length > 0 &&
        newCalls.forEach((call) => {
          const existingCall = uniqueCallsMap.get(call.md_id);
          if (
            !existingCall ||
            call.actual_visits > existingCall.actual_visits
          ) {
            uniqueCallsMap.set(call.md_id, { ...call, status: "new" });
          }
        });

      const uniqueCallsArray = Array.from(uniqueCallsMap.values());
      newCalls &&
        newCalls?.length > 0 &&
        newCalls.forEach((call) => {
          const existingCall = uniqueCallsArray.find(
            (uniqueCall) => uniqueCall.md_id === call.md_id
          );
          if (existingCall && existingCall.id !== call.id) {
            uniqueCallsArray.push({ ...call, status: "old" });
          }
        });

      uniqueCallsArray &&
        uniqueCallsArray?.length > 0 &&
        uniqueCallsArray.sort((a, b) => {
          if (a.md_id === b.md_id) {
            return new Date(a.date) - new Date(b.date);
          }
          return typeof a.md_id === "string" && typeof b.md_id === "string"
            ? a.md_id.localeCompare(b.md_id)
            : a.md_id - b.md_id;
        });
      return uniqueCallsArray?.length > 0 ? uniqueCallsArray : [];
    } else if (type === "mds" && mdList?.length > 0) {
      const uniqueMds = Object.values(
        mdList
          .filter((call) => call.is_summary === 1)
          .sort((a, b) => new Date(b?.record_date) - new Date(a?.record_date))
          .reduce((acc, curr) => {
            if (
              !acc[curr.md_id] ||
              curr?.actual_visits > acc[curr?.md_id]?.actual_visits
            ) {
              acc[curr?.md_id] = curr;
            }
            return acc;
          }, {})
      );
      return uniqueMds?.length > 0 ? uniqueMds : [];
    } else {
      return [];
    }
  };
  // incidental_calls, daily_incidental_calls, and monthly_incidental_calls
  const getIncidentalCalls = (dataList, sDate, type, type2) => {
    const chart = [
      { class: "A", actual_calls: 0 },
      { class: "B", actual_calls: 0 },
      { class: "C", actual_calls: 0 },
      { class: "D", actual_calls: 0 },
    ];
    let list = [];
    const isCurrDate = sDate === dateFormatting();
    if (type === "daily") {
      list = getPrevious7DaysArr(sDate).map((item) => {
        return {
          date: item,
          actual_call: 0,
        };
      });
    }
    dataList &&
      dataList?.length > 0 &&
      dataList?.forEach((entry) => {
        const { record_time, is_incidental, class_name, address, data } = entry;
        if (type === "today") {
          if (convertDefaultTimeZone(record_time) === sDate) {
            const index = chart.findIndex((item) => item.class === class_name);
            let jointCall =
              data &&
              data?.jointCall &&
              Object.keys(data?.jointCall)?.length > 0
                ? data?.jointCall?.notes
                : "";
            let postCall =
              data && data?.postCall && Object.keys(data?.postCall).length > 0
                ? data?.postCall?.post_call
                : "";
            let product =
              data?.productDetailing?.length > 0 ? data?.productDetailing : [];
            if (index !== -1) {
              chart[index].actual_calls++;
              list.push({
                ...entry,
                address: address ? address : "Not yet available",
                jointCall: jointCall,
                postCall: postCall,
                product: product,
              });
            }
          }
        } else if (type === "month") {
          const is_incidental1 = type2 === "cumulative" ? is_incidental : 1;
          const checkCallDate = isCurrDate
            ? convertDefaultTimeZone(record_time) < sDate
            : convertDefaultTimeZone(record_time) <= sDate;
          if (is_incidental1 && checkCallDate) {
            const newData = type2 === "cumulative" ? data?.summary_data : data;
            let jointCall =
              newData &&
              newData?.jointCall &&
              Object.keys(newData?.jointCall)?.length > 0
                ? newData?.jointCall?.notes
                : "";
            let postCall =
              newData &&
              newData?.postCall &&
              Object.keys(newData?.postCall).length > 0
                ? newData?.postCall?.post_call
                : "";
            let product =
              newData?.productDetailing?.length > 0
                ? newData?.productDetailing
                : [];
            list.push({
              ...entry,
              address: address ? address : "Not yet available",
              jointCall: jointCall,
              postCall: postCall,
              product: product,
            });
          }
        } else if (type === "daily") {
          const index = list.findIndex(
            (item) => item.date === defaultDateDashFormat(record_time)
          );
          if (index !== -1) {
            list[index].actual_call++;
          }
        }
      });
    const newList =
      (list?.length > 0 &&
        list.sort(
          (a, b) => new Date(b?.record_time) - new Date(a?.record_time)
        )) ||
      list;
    return { chart, list: newList };
  };
  // today_not_submitted_saved_calls and monthly_not_submitted_saved_calls
  const getNotSubmittedSavedCalls = (mdList, sDate, type, rType) => {
    const list = [];
    const isCurrDate = sDate === dateFormatting();
    if (type === "today") {
      mdList &&
        mdList?.length > 0 &&
        mdList.forEach((entry) => {
          const {
            date,
            data,
            address,
            is_save,
            is_summary,
            isQuicksign,
            save_record_date,
            save_data,
          } = entry;
          if (is_summary !== 1 && is_save === 1) {
            const checkCallDate =
              rType === "T"
                ? convertDefaultTimeZone(save_record_date) === sDate
                : isCurrDate
                ? convertDefaultTimeZone(save_record_date) < sDate
                : convertDefaultTimeZone(save_record_date) <= sDate;
            if (!checkCallDate) return;
            const newData = JSON.parse(save_data);
            const call_date = defaultDateDashFormat(save_record_date);
            const callType = isQuicksign
              ? "Quick Call"
              : newData?.quickSignObj &&
                Object.keys(newData?.quickSignObj)?.length > 0 &&
                (newData?.quickSignObj?.signature_url ||
                  newData?.quickSignObj?.signPadData)
              ? "Quick Call"
              : "Itinerary";
            const reliever_md =
              data &&
              data?.reliever_md &&
              Object.keys(data?.reliever_md).length > 0
                ? data?.reliever_md
                : "";
            const jointCall =
              newData &&
              newData?.jointCall &&
              Object.keys(newData?.jointCall).length > 0
                ? newData?.jointCall?.notes
                : "";
            const postCall =
              newData &&
              newData?.postCall &&
              Object.keys(newData?.postCall).length > 0
                ? newData?.postCall?.post_call
                : "";
            const callData = {
              ...entry,
              product: newData?.products?.length > 0 ? newData?.products : [],
              jointCall: jointCall,
              postCall: postCall,
              actual_call_date: save_record_date,
              signature_md_time: data?.signature_time,
              address: address ? address : "Not yet available",
              call_type: callType,
              reliever_md: reliever_md,
              remark:
                call_date < date
                  ? "Advanced"
                  : call_date > date
                  ? "Make-Up"
                  : "On-Time",
            };
            list.push(callData);
          }
        });
      const newList =
        (list?.length > 0 &&
          list.sort(
            (a, b) =>
              new Date(b?.actual_call_date) - new Date(a?.actual_call_date)
          )) ||
        list;
      return newList;
    } else {
      mdList &&
        mdList?.length > 0 &&
        mdList.forEach((entry) => {
          const { data, is_save, target_date, isQuicksign, record_time } =
            entry;
          if (is_save === 1) {
            const checkCallDate = isCurrDate
              ? convertDefaultTimeZone(record_time) < sDate
              : convertDefaultTimeZone(record_time) <= sDate;
            if (!checkCallDate) return;
            const sMD = data && data?.selectedMD;
            const newData = data && data?.summary_data;
            const call_date = defaultDateDashFormat(record_time);
            const callType = isQuicksign
              ? "Quick Call"
              : newData?.quickSignObj &&
                Object.keys(newData?.quickSignObj)?.length > 0 &&
                (newData?.quickSignObj?.signature_url ||
                  newData?.quickSignObj?.signPadData)
              ? "Quick Call"
              : "Itinerary";
            const reliever_md =
              newData &&
              newData?.reliever_md &&
              Object.keys(newData?.reliever_md).length > 0
                ? newData?.reliever_md
                : "";
            const jointCall =
              newData &&
              newData?.jointCall &&
              Object.keys(newData?.jointCall).length > 0
                ? newData?.jointCall?.notes
                : "";
            const postCall =
              newData &&
              newData?.postCall &&
              Object.keys(newData?.postCall).length > 0
                ? newData?.postCall?.post_call
                : "";
            const callData = {
              ...sMD,
              product:
                newData?.productDetailing?.length > 0
                  ? data?.productDetailing
                  : [],
              jointCall: jointCall,
              postCall: postCall,
              actual_call_date: record_time,
              signature_md_time: newData?.signature_time
                ? newData?.signature_time
                : "",
              date: target_date,
              address: sMD?.address ? sMD?.address : "No Location Found",
              call_type: callType,
              reliever_md: reliever_md,
              remark:
                call_date < target_date
                  ? "Advanced"
                  : call_date > target_date
                  ? "Make-Up"
                  : "On-Time",
            };
            list.push(callData);
          }
        });
      const newList =
        (list?.length > 0 &&
          list.sort(
            (a, b) =>
              new Date(b?.actual_call_date) - new Date(a?.actual_call_date)
          )) ||
        list;
      return newList;
    }
  };
  // today_not_signed_joint_calls and monthly_not_signed_joint_calls
  const getNotSignedJointCalls = (
    mdList,
    incidentalList,
    sDate,
    type,
    rType
  ) => {
    const list = [];
    const isCurrDate = sDate === dateFormatting();
    if (type === "today") {
      mdList &&
        mdList?.length > 0 &&
        mdList.forEach((entry) => {
          const {
            date,
            data,
            address,
            is_save,
            is_summary,
            isQuicksign,
            save_data,
            record_date,
            save_record_date,
          } = entry;
          if (is_summary === 1 || is_save === 1) {
            const checkCallDate =
              rType === "T"
                ? (is_summary === 1 &&
                    convertDefaultTimeZone(record_date) === sDate) ||
                  (is_save === 1 &&
                    convertDefaultTimeZone(save_record_date) === sDate)
                : isCurrDate
                ? (is_summary === 1 &&
                    convertDefaultTimeZone(record_date) < sDate) ||
                  (is_save === 1 &&
                    convertDefaultTimeZone(save_record_date) < sDate)
                : (is_summary === 1 &&
                    convertDefaultTimeZone(record_date) <= sDate) ||
                  (is_save === 1 &&
                    convertDefaultTimeZone(save_record_date) <= sDate);
            if (!checkCallDate) return;
            let newData = is_summary ? data : JSON.parse(save_data);
            const call_date = defaultDateDashFormat(
              is_summary ? record_date : save_record_date
            );
            const callType = isQuicksign
              ? "Quick Call"
              : newData?.quickSignObj &&
                Object.keys(newData?.quickSignObj)?.length > 0 &&
                (newData?.quickSignObj?.signature_url ||
                  newData?.quickSignObj?.signPadData)
              ? "Quick Call"
              : "Itinerary";
            const jointCall =
              newData &&
              newData?.jointCall &&
              Object.keys(newData?.jointCall)?.length > 0
                ? newData?.jointCall
                : "";
            const postCall =
              newData &&
              newData?.postCall &&
              Object.keys(newData?.postCall).length > 0
                ? newData?.postCall?.post_call
                : "";
            let product = "";
            if (is_summary) {
              product =
                newData?.productDetailing?.length > 0
                  ? newData?.productDetailing
                  : [];
            } else {
              product = newData?.products?.length > 0 ? newData?.products : [];
            }
            const callData = {
              ...entry,
              product: product,
              jointCall: jointCall?.notes,
              postCall: postCall,
              signature_md_time: data?.signature_time,
              address: address ? address : "Not yet available",
              actual_call_date:
                is_summary === 1 ? record_date : save_record_date,
              call_type: callType,
              call_status: is_summary ? "Submitted" : "Saved",
              dm_sign:
                jointCall &&
                (jointCall?.signature_url || jointCall?.signPadData)
                  ? "Signed"
                  : "Not Signed",
              remark:
                call_date < date
                  ? "Advanced"
                  : call_date > date
                  ? "Make-Up"
                  : "On-Time",
            };
            list.push(callData);
          }
        });
      incidentalList &&
        incidentalList?.length > 0 &&
        incidentalList.forEach((entry) => {
          const { data, address, class_name, record_time } = entry;
          const checkCallDate =
            rType === "T"
              ? convertDefaultTimeZone(record_time) === sDate
              : isCurrDate
              ? convertDefaultTimeZone(record_time) < sDate
              : convertDefaultTimeZone(record_time) <= sDate;
          if (checkCallDate) {
            let jointCall =
              data &&
              data?.jointCall &&
              Object.keys(data?.jointCall)?.length > 0
                ? data?.jointCall
                : "";
            let postCall =
              data && data?.postCall && Object.keys(data?.postCall).length > 0
                ? data?.postCall?.post_call
                : "";
            let product =
              data?.productDetailing?.length > 0 ? data?.productDetailing : [];
            const callData = {
              ...entry,
              product: product,
              jointCall: jointCall?.notes,
              postCall: postCall,
              name: class_name ? class_name : "-",
              signature_md_time: data?.signature_time,
              address: address ? address : "Not yet available",
              actual_call_date: record_time,
              call_type: "-",
              call_status: "Submitted",
              dm_sign:
                jointCall &&
                (jointCall?.signature_url || jointCall?.signPadData)
                  ? "Signed"
                  : "Not Signed",
              remark: "-",
            };
            list.push(callData);
          }
        });
      list.sort((a, b) => new Date(b?.record_date) - new Date(a?.record_date));
      list.sort((a, b) => {
        if (a?.dm_sign === b?.dm_sign) return 0;
        return a?.dm_sign === "Not Signed" ? -1 : 1;
      });
      return list;
    } else {
      mdList &&
        mdList?.length > 0 &&
        mdList.forEach((entry) => {
          const {
            data,
            is_save,
            target_date,
            isQuicksign,
            is_incidental,
            record_time,
          } = entry;
          const checkCallDate = isCurrDate
            ? convertDefaultTimeZone(record_time) < sDate
            : convertDefaultTimeZone(record_time) <= sDate;
          if (checkCallDate) {
            const sMD = data && data?.selectedMD;
            const newData = data && data?.summary_data;
            const call_date = defaultDateDashFormat(record_time);
            const callType = is_incidental
              ? "-"
              : isQuicksign
              ? "Quick Call"
              : newData?.quickSignObj &&
                Object.keys(newData?.quickSignObj)?.length > 0 &&
                (newData?.quickSignObj?.signature_url ||
                  newData?.quickSignObj?.signPadData)
              ? "Quick Call"
              : "Itinerary";
            const reliever_md =
              newData &&
              newData?.reliever_md &&
              Object.keys(newData?.reliever_md).length > 0
                ? newData?.reliever_md
                : "";
            const jointCall =
              newData &&
              newData?.jointCall &&
              Object.keys(newData?.jointCall).length > 0
                ? newData?.jointCall
                : "";
            const callData = {
              ...sMD,
              product:
                newData?.productDetailing?.length > 0
                  ? data?.productDetailing
                  : [],
              actual_call_date: record_time,
              signature_md_time: newData?.signature_time
                ? newData?.signature_time
                : "",
              date: target_date,
              name: sMD?.name ? sMD?.name : sMD?.class_name,
              reliever_md: reliever_md,
              address: sMD?.address ? sMD?.address : "No Location Found",
              call_type: callType,
              call_status: is_save ? "Saved" : "Submitted",
              dm_sign:
                jointCall &&
                (jointCall?.signature_url || jointCall?.signPadData)
                  ? "Signed"
                  : "Not Signed",
              remark:
                call_date < target_date
                  ? "Advanced"
                  : call_date > target_date
                  ? "Make-Up"
                  : "On-Time",
            };
            list.push(callData);
          }
        });
      return list;
    }
  };
  // today_vmc_request and monthly_vmc_request
  const getVMCRequestsData = (oofList, sDate, type) => {
    const list = [];
    oofList &&
      oofList?.length > 0 &&
      oofList.forEach((entry) => {
        const { created_at } = entry;
        if (convertDefaultTimeZone(created_at) === sDate) {
          list.push(entry);
        }
      });
    return list;
  };
  // monthly_missed_calls
  const getNotYetCalledMissedCalls = (mdList, oofList, sDate) => {
    const list = [];
    mdList &&
      mdList?.length > 0 &&
      mdList.forEach((entry) => {
        const { date, md_id } = entry;
        const oofMDList = getOOFMDListByDate(oofList, date);
        if (
          date === sDate &&
          oofMDList &&
          oofMDList?.length > 0 &&
          oofMDList?.includes(md_id)
        ) {
          list.push(entry);
        }
      });
    return list;
  };

  const getAllOOFMDList = (data) => {
    let mdIdsArray = [];
    data.forEach((item) => {
      if (item && item?.md_ids?.length > 0) {
        mdIdsArray = mdIdsArray.concat(item?.md_ids.map((id) => id));
      }
    });

    return mdIdsArray;
  };
  const backToReportsHandler = () => {
    setIsAciveReport(false);
    setSelectedReportType("");
    setSelectedDate();
    setIsRefreshed(false);
  };

  return (
    <>
      <div className="tc-body tc-body-without-ft tc-report-page">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(-1)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title text-uppercase">Reports</p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <div className="tc-content-wrapper">
          <div className="add-scroll">
            <div className="tc-report-contents">
              {reportTypes.map((type, i) => (
                <div className="tc-report-container mb-4" key={i}>
                  <h2 className="tc-report-label">{type.title}</h2>
                  <div className="tc-report-list">
                    {type.list.map((report, j) => {
                      if (report.active) {
                        return (
                          <Button
                            key={j}
                            role="button"
                            variant="primary"
                            className="w-100"
                            onClick={() =>
                              onSelectReportHandler({
                                ...report,
                                type: type?.type,
                                name: type?.name,
                              })
                            }
                          >
                            {report.label}
                          </Button>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="tc-footer-blank"></div> */}
      </div>
      <ReportsDetail
        showSidebar={isAciveReport}
        backToReportsHandler={backToReportsHandler}
        selectedReportType={selectedReportType}
        selectedDate={selectedDate}
        onDateChangehandler={onDateChangehandler}
        refreshToGetCurrentDateData={refreshToGetCurrentDateData}
        isRefreshed={isRefreshed}
      />
    </>
  );
};

export default Reports;
