import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, authHeaders2 } from "../../helpers/axios";
import { toast } from "react-toastify";
import { apiendpoints } from "../../helpers/constants";
import { messages } from "../../helpers/messages";
import { handleTokenRefresh } from "./userSlice";

export const reqToGetTimeInOutData = createAsyncThunk(
  apiendpoints.getTimeInOut,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.getTimeInOut,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response.data;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToAddTimeInOutData = createAsyncThunk(
  apiendpoints.addTimeInOut,
  async ({ data, onSuccess, onFailure }) => {
    const request = {
      method: "POST",
      url: apiendpoints.addTimeInOut,
      data,
      headers: authHeaders2(),
    };
    try {
      const response = await Axios(request);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (error.response && error.response?.status === 403) {
        try {
          const response = await handleTokenRefresh(request);
          onSuccess && onSuccess(response);
          return response;
        } catch (error) {
          onFailure && onFailure(error);
          return error;
        }
      } else {
        onFailure && onFailure(error);
        return error;
      }
    }
  }
);

export const reqToDeleteTimeInOut = createAsyncThunk(
  apiendpoints.deleteTimeInOut,
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.deleteTimeInOut,
        data.data,
        authHeaders()
      );
      if (response.status === 201 || (response.status === 200 && !data.cb)) {
        toast.success(data.toast, { autoClose: 3000 });
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const timeInOutSlice = createSlice({
  name: "timeInOut",
  initialState: {
    loader: false,
    timeInOut: [],
    addTime: "",
    deleteTime: "",
    currAddress: "",
    apiMessage: "",
  },
  reducers: {
    reqToResetTimeApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET TIMEINOUT
    builder
      .addCase(reqToGetTimeInOutData.pending, (state) => {
        state.apiMessage = messages.apiMessage.timeInOut;
      })
      .addCase(reqToGetTimeInOutData.fulfilled, () => {})
      .addCase(reqToGetTimeInOutData.rejected, () => {});

    // ADD TIMEINOUT
    builder
      .addCase(reqToAddTimeInOutData.pending, () => {})
      .addCase(reqToAddTimeInOutData.fulfilled, () => {})
      .addCase(reqToAddTimeInOutData.rejected, () => {});

    // DELETE TIMEINOUT
    builder
      .addCase(reqToDeleteTimeInOut.pending, () => {})
      .addCase(reqToDeleteTimeInOut.fulfilled, () => {})
      .addCase(reqToDeleteTimeInOut.rejected, () => {});
  },
});

export const { reqToResetTimeApiMessage } = timeInOutSlice.actions;
export default timeInOutSlice.reducer;
