import React, { useEffect } from "react";
import moment from "moment";
import { browserName } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CDCILogo, CYDCLogo, CYDCSLogo } from "../../images";
import { db, getAllEntriesFromDB } from "../../helpers/db";
import { useFetchAndSyncData } from "../../hooks";
import { Loader } from "../../components";
import { OfflineModal, Confirmation } from "../../components/Modals";
import { dateFormatting } from "../../helpers/commonFunction";
import { updateUserState } from "../../store/slice/userSlice";

const BrowserCompatibility = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { syncLoader } = useSelector((state) => state.offlineReducer);

  useEffect(() => {
    if (browserName && browserName?.toLowerCase()?.includes("safari")) {
      navigate(routes.dashboard);
    }
  }, [browserName]);

  useEffect(() => {
    checkDataInLocalDB();
  }, []);

  const {
    syncMessage,
    offlineModal,
    confirmationModal,
    handleOfflineModal,
    handleConfirmModal,
    uploadAndFetchData,
  } = useFetchAndSyncData();

  const checkDataInLocalDB = async () => {
    const [
      addedCumulativeCalls,
      callRecordingData,
      addedQuickSign,
      deletedQuickSign,
      editableCalls,
      missedCalls,
      addedTimeInOut,
      addedMDBirthday,
      addedItinerary,
      addedMD,
    ] = await Promise.all([
      getAllEntriesFromDB("addedCumulativeCalls"),
      getAllEntriesFromDB("callRecording"),
      getAllEntriesFromDB("addedQuickSign"),
      getAllEntriesFromDB("deletedQuickSign"),
      getAllEntriesFromDB("editableCalls"),
      getAllEntriesFromDB("oofMissedCalls"),
      getAllEntriesFromDB("addedTimeInOut"),
      getAllEntriesFromDB("addedMDBirthday"),
      getAllEntriesFromDB("addedItinerary"),
      getAllEntriesFromDB("addedMD"),
    ]);
    if (
      addedCumulativeCalls?.length > 0 ||
      callRecordingData?.length > 0 ||
      addedQuickSign?.length > 0 ||
      deletedQuickSign?.length > 0 ||
      editableCalls?.length > 0 ||
      missedCalls?.length > 0 ||
      addedTimeInOut?.length > 0 ||
      addedMDBirthday?.length > 0 ||
      addedItinerary?.length > 0 ||
      addedMD?.length > 0
    ) {
    } else {
      checkTimeInOut();
    }
  };

  const checkTimeInOut = async () => {
    let getOldTime = "";
    let getNewTime = "";
    let localDBDate = "";
    if (user && user?.timeout_date) {
      if (user?.timeout_date?.trim()?.includes(" ")) {
        localDBDate = user?.timeout_date?.trim()?.split(" ")[0];
      }
      if (localDBDate) {
        getOldTime = localDBDate;
        getNewTime = dateFormatting(new Date());
      } else {
        getOldTime = moment.utc(user?.timeout_date).format("YYYY-MM-DD");
        getNewTime = dateFormatting(new Date());
      }
    } else if (user && user?.timein_date) {
      if (user?.timein_date?.trim()?.includes(" ")) {
        localDBDate = user?.timein_date?.trim()?.split(" ")[0];
      }
      if (localDBDate) {
        getOldTime = localDBDate;
        getNewTime = dateFormatting(new Date());
      } else {
        getOldTime = moment.utc(user?.timein_date).format("YYYY-MM-DD");
        getNewTime = dateFormatting(new Date());
      }
    }
    if (getOldTime !== getNewTime) {
      let newUser = {
        ...user,
        time_in: false,
        timein_date: "",
        timeout_date: "",
      };
      await db.timeInOut.clear();
      dispatch(updateUserState(newUser));
    }
  };

  const handleUserLogout = () => {
    uploadAndFetchData("browserLogout", checkTimeInOut);
  };

  return (
    <>
      {syncLoader && <Loader />}
      <div className="tc-browser-compatibility-wrapper">
        <Card className="tc-browser-compatibility-content">
          <Card.Body>
            <div
              className={`tc-browser-compatibility-logo mb-4 ${
                process.env.REACT_APP_TYPE !== "CYDC" &&
                "tc-browser-compatibility-logo2"
              }`}
            >
              <img
                src={
                  process.env.REACT_APP_TYPE === "CYDC"
                    ? CYDCLogo
                    : process.env.REACT_APP_TYPE === "CDCI"
                    ? CDCILogo
                    : CYDCSLogo
                }
                alt="ETMS LOGO"
              />
            </div>
            <Card.Title className="text-center mb-3">Access Denied</Card.Title>
            <Card.Text className="text-center">
              Please Open App in Safari browser for best compatibility!
            </Card.Text>
            <div className="mt-4 text-center">
              {user && (
                <Button
                  variant="primary"
                  type="button"
                  className="btn-min"
                  onClick={handleUserLogout}
                >
                  Logout
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      {offlineModal && (
        <OfflineModal show={offlineModal} onHide={handleOfflineModal} />
      )}
      {confirmationModal && (
        <Confirmation
          show={confirmationModal}
          onHide={handleConfirmModal}
          title="Syncing Data"
          subTitle="Failed to sync data!"
          description="Please resync the data!"
          cancel="Cancel"
          save="Syncing"
          errorMessage={syncMessage}
          onSubmit={handleUserLogout}
        />
      )}
    </>
  );
};

export default BrowserCompatibility;
