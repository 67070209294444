import React, { useState } from "react";
import { ViewImageModal } from "./Modals";

const ImageCard = (props) => {
  const { height, imagePreview, ...rest } = props;
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  return (
    <>
      {props?.src ? (
        <>
          <img
            {...rest}
            className={`custom_data_table_img`}
            height={height ? height : 100}
            loading="lazy"
            onClick={() => {
              if (imagePreview) {
                setImgZoom(true);
                setImgSrc(props.src);
              }
            }}
            draggable={false}
          />
          <ViewImageModal
            onHide={() => setImgZoom(!imgZoom)}
            show={imgZoom}
            imgSrc={imgSrc}
          />
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ImageCard;
